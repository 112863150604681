import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import ElementGrid, { PAGE_SIZE } from './ElementGrid'
import OriginalPaletteCard from './OriginalPaletteCard'
import {
  PAGINATED_LAYERS_BY_ROOM_AND_MASK,
  PAGINATED_COLORS_BY_MASK,
} from '../../../../graphql/layers.graphql'
import { useDebounce } from '../hooks'
import { useSnackbar } from '../../../../contexts'
import {
  elementsMatch,
  isLayer,
  ROOM_COLOR_TYPE,
} from '../../../../utils/roomLayer'
import '../../../../types'

const useStyles = makeStyles((theme) => ({
  modal: {
    background: 'white',
    border: '1px solid #d8dee2',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    '@media screen and (orientation: landscape)': {
      maxWidth: 'max(450px, 35vw)',
      margin: '5px',
    },
    '@media screen and (orientation: portrait)': {
      maxWidth: '100%',
      margin: '5px',
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  modalHeader: {
    display: 'flex',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  modalText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tab: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  },
  gridContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  closeButton: {
    marginLeft: 'auto',
    marginBottom: 12,
    padding: 0,
    minWidth: 0,
  },
  closeButtonIcon: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

/**
 * @typedef PagedLayers
 * @prop {boolean} more
 * @prop {number} totalCount
 * @prop {ColorLayer[]} items
 */

/**
 * TODO: Add swatch or layer name filter
 * @param {number} roomId
 * @param {number} maskId
 * @param {string=} filter
 * @param {number} page
 * @param {number=} size
 * @param {object} options
 * @returns {ApolloReturn<{ layers: PagedLayers }>}
 */
function useLayersByMaskId(
  roomId,
  maskId,
  filter,
  page = 0,
  size = PAGE_SIZE,
  options = {}
) {
  return useQuery(PAGINATED_LAYERS_BY_ROOM_AND_MASK, {
    ...options,
    variables: {
      roomId,
      maskId,
      name: filter,
      page,
      size,
    },
  })
}

/**
 * @typedef PagedColors
 * @prop {boolean} more
 * @prop {number} totalCount
 * @prop {Color[]} items
 */

/**
 * @typedef PagedColorLayers
 * @prop {boolean} more
 * @prop {number} totalCount
 * @prop {ColorLayer[]} items
 */

/**
 * Fetches and transforms colors into the ColorLayer format
 * @param {number} maskId
 * @param {string=} filter
 * @param {number=} page
 * @param {number=} size
 * @param {object} options
 * @returns {ApolloReturn<{ colors: PagedColorLayers }>}
 */
function useColorsByMaskId(
  maskId,
  filter,
  page = 0,
  size = PAGE_SIZE,
  options = {}
) {
  /** @type {ApolloReturn<{ colors: PagedColors, maskById: Mask }>} */
  const result = useQuery(PAGINATED_COLORS_BY_MASK, {
    ...options,
    variables: {
      maskId,
      name: filter,
      page,
      size,
    },
  })

  return useMemo(() => {
    if (result.loading || result.error) return result
    return {
      ...result,
      data: {
        colors: {
          ...result.data.colors,
          items: result.data.colors.items.map((item) => ({
            mask: result.data.maskById,
            color: item,
            __typename: ROOM_COLOR_TYPE,
          })),
        },
      },
    }
  }, [result.loading, result.error, result.data])
}

const LAYER_ERROR_MESSAGE =
  'An error occured while fetching Floor Coverings, please try again later!'
const LAYER_EMPTY_MESSAGE = 'No Floor Covering matched your filters!'
const COLOR_ERROR_MESSAGE =
  'An error occured while fetching colors, please try again later!'
const COLOR_EMPTY_MESSAGE = 'No color matched your filters!'
const ERROR_REMINDER =
  'You can save your progress by clicking the "Share" button!'

function BasePalettePicker({
  open,
  viewName,
  filterTitle,
  mask,
  originalElement,
  actualElement,
  loading,
  error,
  elements,
  totalElements,
  errorText,
  emptyText,
  fetchMore,
  onFilterChange,
  onSelect,
  onReset,
  onClose,
}) {
  const classes = useStyles()

  return (
    <Modal open={open} hideBackdrop className={classes.modal} onClose={onClose}>
      <div className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.modalText} variant="h6">
            Choose a {viewName}
          </Typography>
          <Button className={classes.closeButton} onClick={onClose}>
            <CloseRoundedIcon className={classes.closeButtonIcon} />
          </Button>
        </div>
        <OriginalPaletteCard
          mask={mask}
          element={originalElement}
          onClick={onReset}
        />
        <div className={classes.tab}>
          <TextField
            label={filterTitle}
            variant="outlined"
            size="small"
            style={{ marginTop: 8, marginBottom: 8, width: '100%' }}
            onInput={onFilterChange}
          />
          <div className={classes.gridContainer}>
            <ElementGrid
              loading={loading}
              error={error}
              elements={elements}
              totalElements={totalElements}
              errorText={errorText}
              emptyText={emptyText}
              fetchMore={fetchMore}
              isElementSelected={(checkedElement) =>
                elementsMatch(actualElement, checkedElement)
              }
              onSelect={onSelect}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

BasePalettePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  viewName: PropTypes.string,
  filterTitle: PropTypes.string,
  mask: PropTypes.object,
  originalElement: PropTypes.object,
  actualElement: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  elements: PropTypes.arrayOf(PropTypes.object),
  totalElements: PropTypes.number,
  errorText: PropTypes.string,
  emptyText: PropTypes.string,
  fetchMore: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
}

BasePalettePicker.defaultProps = {
  viewName: null,
  filterTitle: null,
  mask: null,
  originalElement: null,
  actualElement: null,
  loading: null,
  error: null,
  elements: null,
  totalElements: null,
  errorText: null,
  emptyText: null,
  fetchMore: null,
  onFilterChange: null,
  onSelect: null,
  onReset: null,
  onClose: null,
}

function PalettePickerColors({
  open,
  mask,
  originalElement,
  actualElement,
  filter,
  onFilterChange,
  onSelect,
  onReset,
  onClose,
}) {
  const { addMessage } = useSnackbar()

  const { data, loading, error, fetchMore } = useColorsByMaskId(mask.id, filter)
  const colorCount = data?.colors?.totalCount

  const colors = useMemo(() => {
    if (loading) return []
    if (error) return null
    return data.colors.items
  }, [loading, data, error])

  useEffect(() => {
    if (error) {
      addMessage({
        title: COLOR_ERROR_MESSAGE,
        message: ERROR_REMINDER,
        type: 'error',
      })
    }
  }, [error])

  const handleFetchMore = () => {
    fetchMore({
      variables: { page: Math.floor(colors.length / PAGE_SIZE) },
    })
  }

  return (
    <BasePalettePicker
      viewName="Color"
      filterTitle="Search by Color Name or Number"
      open={open}
      mask={mask}
      originalElement={originalElement}
      actualElement={actualElement}
      loading={loading}
      error={error}
      elements={colors}
      totalElements={colorCount}
      errorText={COLOR_ERROR_MESSAGE}
      emptyText={COLOR_EMPTY_MESSAGE}
      fetchMore={handleFetchMore}
      onFilterChange={onFilterChange}
      onSelect={onSelect}
      onReset={onReset}
      onClose={onClose}
    />
  )
}

PalettePickerColors.propTypes = {
  open: PropTypes.bool.isRequired,
  mask: PropTypes.object,
  originalElement: PropTypes.object,
  actualElement: PropTypes.object,
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
}

PalettePickerColors.defaultProps = {
  mask: null,
  originalElement: null,
  actualElement: null,
  onSelect: null,
  onReset: null,
  onClose: null,
}

function PalettePickerLayers({
  open,
  mask,
  room,
  originalElement,
  actualElement,
  filter,
  onFilterChange,
  onSelect,
  onReset,
  onClose,
}) {
  const { addMessage } = useSnackbar()

  const { data, loading, error, fetchMore } = useLayersByMaskId(
    room.id,
    mask.id,
    filter
  )
  const layerCount = data?.layers?.totalCount

  const layers = useMemo(() => {
    if (loading) return []
    if (error) return null
    return data.layers.items
  }, [loading, data, error])

  useEffect(() => {
    if (error) {
      addMessage({
        title: LAYER_ERROR_MESSAGE,
        message: ERROR_REMINDER,
        type: 'error',
      })
    }
  }, [error])

  const handleFetchMore = () => {
    fetchMore({
      variables: { page: Math.floor(layers.length / PAGE_SIZE) },
    })
  }

  return (
    <BasePalettePicker
      viewName="Floor Covering"
      filterTitle="Search by Swatch Name or Number"
      open={open}
      mask={mask}
      originalElement={originalElement}
      actualElement={actualElement}
      loading={loading}
      error={error}
      elements={layers}
      totalElements={layerCount}
      errorText={LAYER_ERROR_MESSAGE}
      emptyText={LAYER_EMPTY_MESSAGE}
      fetchMore={handleFetchMore}
      onFilterChange={onFilterChange}
      onSelect={onSelect}
      onReset={onReset}
      onClose={onClose}
    />
  )
}

PalettePickerLayers.propTypes = {
  open: PropTypes.bool.isRequired,
  room: PropTypes.object,
  mask: PropTypes.object,
  originalElement: PropTypes.object,
  actualElement: PropTypes.object,
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
}

PalettePickerLayers.defaultProps = {
  room: null,
  mask: null,
  originalElement: null,
  actualElement: null,
  onSelect: null,
  onReset: null,
  onClose: null,
}

/**
 * @typedef PalettePickerProps
 * @prop {boolean} open
 * @prop {Room} room
 * @prop {Mask} mask
 * @prop {SchemeElement=} originalElement
 * @prop {SchemeElement=} actualElement
 * @prop {(layer: SchemeElement) => void} onSelect
 * @prop {() => void} onReset
 * @prop {() => void} onClose
 */

/**
 * @param {PalettePickerProps} props
 */
export default function PalettePicker({
  open,
  room,
  mask,
  originalElement,
  actualElement,
  onSelect,
  onReset,
  onClose,
}) {
  /** @type {ReactState<string>} */
  const [filter, setFilter] = useState('')

  const handleFilterChange = useDebounce(
    /** @param {InputEvent} event */
    (event) => {
      setFilter(event.target.value)
    }
  )

  return isLayer(originalElement) ? (
    <PalettePickerLayers
      open={open}
      room={room}
      mask={mask}
      filter={filter}
      originalElement={originalElement}
      actualElement={actualElement}
      onSelect={onSelect}
      onReset={onReset}
      onClose={onClose}
      onFilterChange={handleFilterChange}
    />
  ) : (
    <PalettePickerColors
      open={open}
      room={room}
      mask={mask}
      filter={filter}
      originalElement={originalElement}
      actualElement={actualElement}
      onSelect={onSelect}
      onReset={onReset}
      onClose={onClose}
      onFilterChange={handleFilterChange}
    />
  )
}

PalettePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  room: PropTypes.object,
  mask: PropTypes.object,
  originalElement: PropTypes.object,
  actualElement: PropTypes.object,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
}

PalettePicker.defaultProps = {
  room: null,
  mask: null,
  originalElement: null,
  actualElement: null,
  onSelect: null,
  onReset: null,
  onClose: null,
}

PalettePicker.Layers = PalettePickerLayers
PalettePicker.Colors = PalettePickerColors
