import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography } from '@material-ui/core'

const Loading = ({ message }) => (
  <div>
    <CircularProgress />
    <Typography variant="body1">{message}</Typography>
  </div>
)

Loading.propTypes = {
  message: PropTypes.string,
}

Loading.defaultProps = {
  message: 'Loading...',
}

export { Loading }
