export const CLOUDINARY_BASE =
  'https://res.cloudinary.com/renderinghouse/image/upload'

/**
 * Get the absolute path to cloudinary room image
 * @param {string} cloudFolderName
 * @param {string} fileName
 * @param {string} settings
 * @returns {string} Absolute path to a room image
 */
export function getRoomCloudinaryPath(
  cloudFolderName,
  fileName,
  settings = 'w_150,h_150,c_fill'
) {
  if (!cloudFolderName || !fileName)
    throw new Error(
      'Function getRoomCloudinaryPath() must receive cloudFolderName and fileName!'
    )

  return `${CLOUDINARY_BASE}/${settings}/app/${cloudFolderName}/rooms/${fileName}`
}

export function getSwatchCloudinaryPath(
  fileName,
  settings = 'w_150,h_150,c_fill'
) {
  if (!fileName)
    throw new Error('Function getSwatchCloudinaryPath() must receive fileName!')

  return `${CLOUDINARY_BASE}/${settings}/app/cv/swatches/${fileName}`
}

export function getMaskCloudinaryPath(
  cloudFolderName,
  fileName,
  roomId,
  settings = 'w_150,h_150,c_fill'
) {
  if (!cloudFolderName || !fileName || !roomId)
    throw Error(
      'Function getMaskCloudinaryPath() must receive cloudFolderName, fileName and roomId!'
    )

  return `${CLOUDINARY_BASE}/${settings}/app/${cloudFolderName}/rooms/${roomId}/masks/${fileName}`
}

export function getViewElementCloudinaryPath(
  cloudFolderName,
  fileName,
  settings = 'w_150,h_150,c_fill'
) {
  if (!cloudFolderName || !fileName)
    throw new Error(
      `Function getViewElementCloudinaryPath() must receive cloudFolderName and fileName!`
    )

  return `${CLOUDINARY_BASE}/${settings}/app/${cloudFolderName}/images/${fileName}`
}
