import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ImageSkeleton from '../ImageSkeleton'
import '../../types'
import getRoomImage from '../../utils/roomImage'

const useStyles = makeStyles({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
})

/**
 * @typedef RoomImageProps
 * @prop {Account} account
 * @prop {Room} room
 * @prop {Scheme} scheme
 */

/**
 *
 * @param {RoomImageProps} props
 */
export default function RoomImage({ account, room, scheme }) {
  const classes = useStyles()
  const imageUrl = getRoomImage(account, room, scheme)

  return <ImageSkeleton.Stable src={imageUrl} className={classes.image} />
}

RoomImage.propTypes = {
  account: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  scheme: PropTypes.object,
}

RoomImage.defaultProps = {
  scheme: null,
}
