import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Modal, Typography, Button } from '@material-ui/core'
import { compose } from 'recompose'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { PalettePicker } from './PalettePicker'
import MaterialCard from './MaterialCard'
import Input from './Input'
import { withConsumer } from '../utils'
import { StoreConsumer } from '../contexts'
import {
  customizeMaterialColor,
  resetMaterial,
} from '../reducers/designCartActionCreators'
import { MaterialNameInput } from './MaterialNameInput'
import { getCustomScheme } from '../utils/helper'

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30vw',
    marginLeft: 10,
    marginTop: 80,
    padding: 10,
    height: '88%',
    background: 'white',
    border: '1px solid #d8dee2',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      '@media screen and (orientation: landscape)': {
        maxWidth: '50vw',
        marginTop: 10,
      },
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      height: '60%',
      marginTop: '80%',
      margin: 5,
      overflow: 'auto',
    },
  },
  modalHeader: {
    display: 'flex',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  originalColorContainer: {
    border: '1px solid #d8dee2',
    borderRadius: 4,
    padding: 10,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  modalText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  closeButton: {
    marginLeft: 'auto',
    marginBottom: 12,
    padding: 0,
    minWidth: 0,
  },
  closeButtonIcon: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
}))

const PalettePickerModal = ({
  setModalOpen,
  isModalVisible,
  schemeMaterial,
  store,
}) => {
  const classes = styles()
  const { currentDesign } = store.state
  const [filterInput, setFilterInput] = useState('')

  const handleSelectColor = useCallback(
    (paletteSelection) => {
      store.dispatch(
        customizeMaterialColor({
          colorId: paletteSelection.color.id,
          paletteId: paletteSelection.palette_id,
          elementId: schemeMaterial.element_id,
          colorName: paletteSelection.color.name,
          colorIdentifier: paletteSelection.color.identifier,
          color: paletteSelection.color.hex,
          vendorPrefix:
            paletteSelection.color.color_vendor_product_info
              .product_identifier_prefix,
        })
      )
    },
    [schemeMaterial]
  )

  const customScheme = getCustomScheme(currentDesign)

  const currentCustomMaterial = customScheme?.materials?.filter(
    (material) => material.materialId === store.state.selectedMaterialId
  )[0]

  return (
    <Modal
      className={classes.modal}
      open={isModalVisible}
      hideBackdrop
      onClose={() => setModalOpen(false)}
    >
      <React.Fragment>
        <div className={classes.modalHeader}>
          <Typography className={classes.modalText} variant="h6">
            Choose a Color
          </Typography>
          <Button
            onClick={() => setModalOpen(false)}
            className={classes.closeButton}
          >
            <CloseRoundedIcon className={classes.closeButtonIcon} />
          </Button>
        </div>
        <MaterialNameInput schemeMaterial={schemeMaterial} />
        {schemeMaterial && (
          <div className={classes.originalColorContainer}>
            <div className={classes.textButtonContainer}>
              <Typography className={classes.modalText} variant="body1">
                Original Scheme Color
              </Typography>
              <Button
                className={classes.modalText}
                onClick={async () => {
                  await store.dispatch(resetMaterial())
                  setModalOpen(false)
                }}
                variant="outlined"
              >
                Use Scheme Color
              </Button>
            </div>
            <MaterialCard
              style={{ marginLeft: 'auto' }}
              key={`${schemeMaterial.id}`}
              schemeMaterial={schemeMaterial}
              setPalettePickerOpen={() => {}}
              defaultMaterialColor
            />
          </div>
        )}
        <Input
          placeholder="Search by Color Name or Number"
          size="small"
          setInput={setFilterInput}
        />
        <PalettePicker
          filterInput={filterInput}
          handleSelectColor={handleSelectColor}
          materialId={store.state.selectedMaterialId}
          selectedColorId={currentCustomMaterial?.colorId}
        />
      </React.Fragment>
    </Modal>
  )
}

PalettePickerModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  schemeMaterial: PropTypes.shape({
    id: PropTypes.number,
    element_id: PropTypes.number,
    material: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
    }),
    color: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
      hex: PropTypes.string,
      color_vendor_product_info: PropTypes.shape({
        id: PropTypes.number,
        product_identifier_prefix: PropTypes.string,
      }),
    }),
  }),
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
}

PalettePickerModal.defaultProps = {
  schemeMaterial: {},
}

const WrappedComponent = compose(
  withConsumer(StoreConsumer, { propName: 'store' })
)(PalettePickerModal)

export { WrappedComponent as PalettePickerModal }
