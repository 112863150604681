import { useEffect } from 'react'

export default function usePrintPageStyle(isPortrait) {
  const removeStyleTag = () => {
    const existingTag = document.querySelector('#page-style')
    if (existingTag) {
      existingTag.remove()
    }
  }

  useEffect(() => {
    removeStyleTag()

    const css = `@page { size: letter ${
      isPortrait ? 'portrait' : 'landscape'
    }; margin: 30px; padding: 0 }`
    const head = document.head || document.getElementsByTagName('head')[0]
    const style = document.createElement('style')

    style.id = 'page-style'
    style.media = 'print'
    style.appendChild(document.createTextNode(css))

    head.appendChild(style)

    return function cleanup() {
      removeStyleTag()
    }
  }, [isPortrait])
}
