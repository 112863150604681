import React, { useState, useRef, useEffect } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import PropTypes from 'prop-types'

/**
 * @typedef ImageSkeletonProps
 * @prop {string} src
 * @prop {string=} alt
 */

/**
 * @param {ImageSkeletonProps} props
 */
export default function ImageSkeleton({ src, alt, style, ...rest }) {
  const [loading, setLoading] = useState(/** @type {boolean} */ true)

  /** @type {React.MutableObjectRef<HTMLImageElement | null>} */
  const imageRef = useRef(null)

  /** @type {boolean} */
  const isLoaded = !!src && (imageRef?.current?.complete || !loading)

  useEffect(() => {
    setLoading(true)
  }, [src])

  return (
    <>
      <img
        ref={imageRef}
        src={src}
        onLoad={() => setLoading(false)}
        {...rest}
        style={{
          ...style,
          ...(isLoaded ? null : { display: 'none' }),
        }}
        alt={alt}
      />

      {!isLoaded && (
        <Skeleton
          {...rest}
          variant="rectangular"
          style={{
            ...style,
            ...{
              width: rest?.style?.width ?? '100%',
              height: rest?.style?.height ?? '100%',
            },
          }}
        />
      )}
    </>
  )
}

ImageSkeleton.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
}

ImageSkeleton.defaultProps = {
  alt: '',
  style: {},
}

/**
 * Always shows a loading state
 * @param {ImageSkeletonProps} props
 * @returns
 */
ImageSkeleton.Loading = function ImageSkeletonLoading({ style, ...props }) {
  return (
    <Skeleton
      {...props}
      variant="rectangular"
      style={{
        ...style,
        ...{
          width: '100%',
          height: '100%',
        },
      }}
    />
  )
}

ImageSkeleton.Loading.propTypes = {
  style: PropTypes.object,
}

ImageSkeleton.Loading.defaultProps = {
  style: {},
}

/**
 * @typedef ImageSkeletonStableProps
 * @prop {string} src
 * @prop {string=} alt
 * @prop {object=} style
 */

/**
 * Displays a skeleton during the first load, then keeps the old image
 * until the new one loads.
 * @param {ImageSkeletonStableProps} props
 */
ImageSkeleton.Stable = function ImageSkeletonStable({
  src,
  alt,
  style,
  ...restProps
}) {
  const [previousSource, setPreviousSource] = useState(src)
  const [firstLoaded, setFirstLoaded] = useState(false)

  useEffect(() => {
    const image = new Image()
    image.onload = () => {
      setPreviousSource(image.src)
      setFirstLoaded(true)
    }
    image.src = src

    // Should cancel the loading of a previous image
    // when a new one is set
    return () => {
      image.src = ''
    }
  }, [src])

  return (
    <>
      <img
        src={previousSource ?? ''}
        alt={alt}
        {...restProps}
        style={{
          ...style,
          ...(firstLoaded ? null : { display: 'none' }),
        }}
      />
      {!firstLoaded && (
        <Skeleton
          {...restProps}
          variant="rectangular"
          style={{
            ...style,
            ...{
              width: restProps?.style?.width ?? '100%',
              height: restProps?.style?.height ?? '100%',
            },
          }}
        />
      )}
    </>
  )
}

ImageSkeleton.Stable.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
}

ImageSkeleton.Stable.defaultProps = {
  src: '',
  alt: '',
  style: null,
}
