const SELECT_VIEW = 'SELECT_VIEW'
const SELECT_SCHEME = 'SELECT_SCHEME'
const UNSELECT_SCHEME = 'UNSELECT_SCHEME'
const SELECT_MATERIAL = 'SELECT_MATERIAL'
const CUSTOMIZE_MATERIAL_COLOR = 'CUSTOMIZE_MATERIAL_COLOR'
const CUSTOMIZE_MATERIAL_NAME = 'CUSTOMIZE_MATERIAL_NAME'
const RESET_MATERIAL = 'RESET_MATERIAL'
const ADD_DESIGN_TO_CART = 'ADD_DESIGN_TO_CART'
const REMOVE_DESIGN_FROM_CART = 'REMOVE_DESIGN_FROM_CART'
const LOAD_DESIGN_FROM_CART = 'LOAD_DESIGN_FROM_CART'
const EDIT_DESIGN_FROM_CART = 'EDIT_DESIGN_FROM_CART'
const RENAME_DESIGN_FROM_CART = 'RENAME_DESIGN_FROM_CART'
const SET_SESSION_ID = 'SET_SESSION_ID'
const SET_SELECTED_SCHEME_NAME = 'SET_SELECTED_SCHEME_NAME'
const SET_TOKEN = 'SET_TOKEN'

export {
  SELECT_VIEW,
  SELECT_SCHEME,
  UNSELECT_SCHEME,
  SELECT_MATERIAL,
  CUSTOMIZE_MATERIAL_COLOR,
  CUSTOMIZE_MATERIAL_NAME,
  RESET_MATERIAL,
  ADD_DESIGN_TO_CART,
  REMOVE_DESIGN_FROM_CART,
  LOAD_DESIGN_FROM_CART,
  EDIT_DESIGN_FROM_CART,
  RENAME_DESIGN_FROM_CART,
  SET_SESSION_ID,
  SET_SELECTED_SCHEME_NAME,
  SET_TOKEN,
}
