// This component is used by the original non-cart app.
import { compose } from 'recompose'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  ClickAwayListener,
  Fade,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { BannerConsumer, SelectionConsumer } from '../../contexts'
import {
  withConsumer,
  withMutationAsProp,
  getSessionIdAndToken,
} from '../../utils'
import { ADD_SESSION } from '../../graphql/sessions.graphql'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import Snackbar from '../Snackbar'

const styles = makeStyles({
  popover: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 10,
  },
  copyButton: {
    marginLeft: 10,
  },
})

const ShareLinkButton = ({ addSession, location, selection, banner }) => {
  const classes = navButtonStyles()
  const classesShare = styles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [showPopper, setShowPopper] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [shareURL, setShareURL] = useState('')

  const openPopover = (target) => {
    setAnchorEl(target)
    setShowPopper(true)
  }
  const closePopover = () => setShowPopper(false)

  return (
    <ClickAwayListener onClickAway={() => closePopover()}>
      <div className={classes.button}>
        <Button
          style={{ width: 165, whiteSpace: 'nowrap' }}
          color="primary"
          variant="contained"
          onClick={async (event) => {
            banner.setIsLinkCopiedOrDismissed(true)
            const target = event.currentTarget

            if (showPopper) {
              closePopover()
              return
            }

            const selectionState = selection.getState()
            // TODO: Use a different context for the shopping cart pages.
            const { token: sessionToken } = await getSessionIdAndToken(
              selectionState,
              addSession
            )

            // For some reason, React Router converts the URL encoding for %20
            // back into a space. When constructing a URL from the React Router
            // Change all spaces back to a "%20".
            const newShareURL = `${window.location.protocol}//${
              window.location.host
            }${location.pathname.replace(/ /g, '%20')}?token=${sessionToken}`
            setShareURL(() => newShareURL)
            openPopover(target)
          }}
        >
          <Typography className={classes.buttonContent}>
            <LinkIcon className={classes.buttonIcon} />
            <Typography className={classes.buttonText} variant="button">
              Share
            </Typography>
          </Typography>
        </Button>
        <Popper
          anchorEl={anchorEl}
          placement="bottom"
          open={showPopper}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper className={classesShare.popover}>
                <TextField
                  id="shareableURLField"
                  variant="outlined"
                  size="small"
                  defaultValue={shareURL}
                />
                <CopyToClipboard
                  text={shareURL}
                  onCopy={(_, result) => {
                    if (result) {
                      setOpenSnackbar(true)
                    } else {
                      console.error('Could not copy to the clipboard!')
                    }
                    closePopover()
                  }}
                >
                  <Button
                    className={classesShare.copyButton}
                    variant="outlined"
                    color="primary"
                    size="medium"
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Snackbar
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message="Share link copied."
          severity="success"
        />
      </div>
    </ClickAwayListener>
  )
}

ShareLinkButton.propTypes = {
  addSession: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  selection: PropTypes.shape({
    getState: PropTypes.func.isRequired,
  }).isRequired,
  banner: PropTypes.shape({
    isLinkCopiedOrDismissed: PropTypes.bool,
    setIsLinkCopiedOrDismissed: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withConsumer(BannerConsumer, { propName: 'banner' })
)(ShareLinkButton)

export { WrappedComponent as ShareLinkButton }
