// See https://babeljs.io/blog/2019/03/19/7.4.0#migration-from-core-js-2
// This intentionally pollutes the global namespace (an approach suitable to an app), instead of
// using transform-runtime (which is more suitable for a library).
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

// This is the root component. Strict mode and hot loading are defined there.
import App from './App'

ReactDOM.render(<App />, document.getElementById('app'))
