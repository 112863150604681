/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { unset } from '../utils/functions'

/** @typedef {import("./SelectionState").SelectionState} SelectionState */
/** @typedef {import("./SelectionState").PropertyBuildingScheme} PropertyBuildingScheme */
/** @typedef {import("./SelectionState").PropertyRoomScheme} PropertyRoomScheme */
/** @typedef {import("./SelectionState").ISelectionContext} ISelectionContext */

/** @type {React.Context<ISelectionContext>} */
const SelectionContext = React.createContext()

const SelectionConsumer = SelectionContext.Consumer

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.

/**
 * @extends {Component<any, SelectionState>}
 */
class SelectionProvider extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  /** @type {SelectionState} */
  state = this.props.initialSelectionState || {
    currentAccountId: null,
    propertyBuildingScheme: {},
    propertyRoomScheme: {},
    currentPalettePickerMaterialId: null,
    originalPalettePickerColorId: null,
    currentPalettePickerColorId: null,
  }

  setCurrentAccountId = (accountId) =>
    this.setState((state) => ({
      ...state,
      currentAccountId: accountId,
    }))

  setCurrentPalettePickerMaterialId = (materialId) =>
    this.setState((state) => ({
      ...state,
      currentPalettePickerMaterialId: materialId,
    }))

  setOriginalPalettePickerColorId = (colorId) =>
    this.setState((state) => ({
      ...state,
      originalPalettePickerColorId: colorId,
    }))

  setCurrentPalettePickerColorId = (colorId) =>
    this.setState((state) => ({
      ...state,
      currentPalettePickerColorId: colorId,
    }))

  setCurrentSchemeId = (propertyId, buildingId, currentSchemeId) =>
    this.setState((state) => ({
      ...state,
      propertyBuildingScheme: {
        ...state.propertyBuildingScheme,
        [propertyId]: {
          ...(state.propertyBuildingScheme[propertyId] || {}),
          propertyId,
          [buildingId]: {
            ...(state.propertyBuildingScheme[propertyId] &&
            state.propertyBuildingScheme[propertyId][buildingId]
              ? state.propertyBuildingScheme[propertyId][buildingId]
              : {}),
            buildingId,
            currentSchemeId,
          },
        },
      },
    }))

  setCustomMaterialColor = (
    propertyId,
    buildingId,
    schemeId,
    materialId,
    color
  ) =>
    this.setState((state) => ({
      ...state,
      propertyBuildingScheme: {
        ...state.propertyBuildingScheme,
        [propertyId]: {
          ...(state.propertyBuildingScheme[propertyId] || {}),
          propertyId,
          [buildingId]: {
            ...(state.propertyBuildingScheme[propertyId] &&
            state.propertyBuildingScheme[propertyId][buildingId]
              ? state.propertyBuildingScheme[propertyId][buildingId]
              : {}),
            buildingId,
            schemeMaterialColor: {
              ...(state.propertyBuildingScheme[propertyId] &&
              state.propertyBuildingScheme[propertyId][buildingId] &&
              state.propertyBuildingScheme[propertyId][buildingId]
                .schemeMaterialColor
                ? state.propertyBuildingScheme[propertyId][buildingId]
                    .schemeMaterialColor
                : {}),
              [schemeId]: {
                ...(state.propertyBuildingScheme[propertyId] &&
                state.propertyBuildingScheme[propertyId][buildingId] &&
                state.propertyBuildingScheme[propertyId][buildingId]
                  .schemeMaterialColor &&
                state.propertyBuildingScheme[propertyId][buildingId]
                  .schemeMaterialColor[schemeId]
                  ? state.propertyBuildingScheme[propertyId][buildingId]
                      .schemeMaterialColor[schemeId]
                  : {}),
                schemeId,
                [materialId]: {
                  // Make a shallow copy of the new color object.
                  ...color,
                  materialId,
                },
              },
            },
          },
        },
      },
    }))

  clearCustomMaterialColor = (propertyId, buildingId, schemeId, materialId) =>
    this.setState((state) => ({
      ...state,
      propertyBuildingScheme: {
        ...state.propertyBuildingScheme,
        [propertyId]: {
          ...(state.propertyBuildingScheme[propertyId] || {}),
          propertyId,
          [buildingId]: {
            ...(state.propertyBuildingScheme[propertyId] &&
            state.propertyBuildingScheme[propertyId][buildingId]
              ? state.propertyBuildingScheme[propertyId][buildingId]
              : {}),
            buildingId,
            schemeMaterialColor: {
              ...(state.propertyBuildingScheme[propertyId] &&
              state.propertyBuildingScheme[propertyId][buildingId] &&
              state.propertyBuildingScheme[propertyId][buildingId]
                .schemeMaterialColor
                ? state.propertyBuildingScheme[propertyId][buildingId]
                    .schemeMaterialColor
                : {}),
              [schemeId]: {
                ...(state.propertyBuildingScheme[propertyId] &&
                state.propertyBuildingScheme[propertyId][buildingId] &&
                state.propertyBuildingScheme[propertyId][buildingId]
                  .schemeMaterialColor &&
                state.propertyBuildingScheme[propertyId][buildingId]
                  .schemeMaterialColor[schemeId]
                  ? omit(
                      state.propertyBuildingScheme[propertyId][buildingId]
                        .schemeMaterialColor[schemeId],
                      [materialId]
                    )
                  : {}),
                schemeId,
              },
            },
          },
        },
      },
    }))

  setRoomSchemeId = (propertyId, roomId, currentSchemeId) => {
    this.setState((state) =>
      merge(cloneDeep(state), {
        propertyRoomScheme: {
          [propertyId]: {
            propertyId,
            [roomId]: {
              roomId,
              currentSchemeId,
            },
          },
        },
      })
    )
  }

  setRoomCustomElement = (propertyId, roomId, schemeId, maskId, element) => {
    this.setState((state) =>
      // Merge old state and new selection, but overwrite the selected element.
      // This is done because RoomColor and RoomLayer
      // don't share common data (only merging them would cause inconsistent state).
      mergeWith(
        (_, objValue, key) => {
          if (key !== 'element') return undefined
          return objValue
        },
        cloneDeep(state),
        {
          propertyRoomScheme: {
            [propertyId]: {
              propertyId,
              [roomId]: {
                roomId,
                schemeMaskElement: {
                  [schemeId]: {
                    schemeId,
                    [maskId]: {
                      maskId,
                      element,
                    },
                  },
                },
              },
            },
          },
        }
      )
    )
  }

  clearAllRoomCustomElements = (propertyId, roomId, schemeId) => {
    this.setState((state) =>
      unset(state, [
        'propertyRoomScheme',
        propertyId,
        roomId,
        'schemeMaskElement',
        schemeId,
      ])
    )
  }

  clearRoomCustomElement = (propertyId, roomId, schemeId, maskId) => {
    this.setState((state) =>
      unset(state, [
        'propertyRoomScheme',
        propertyId,
        roomId,
        'schemeMaskElement',
        schemeId,
        maskId,
      ])
    )
  }

  getState = () => cloneDeep(this.state)

  render() {
    return (
      <SelectionContext.Provider
        value={{
          currentAccountId: this.state.currentAccountId,
          currentPalettePickerMaterialId:
            this.state.currentPalettePickerMaterialId,
          originalPalettePickerColorId: this.state.originalPalettePickerColorId,
          currentPalettePickerColorId: this.state.currentPalettePickerColorId,
          propertyBuildingScheme: this.state.propertyBuildingScheme,
          propertyRoomScheme: this.state.propertyRoomScheme,
          getState: this.getState,
          setCurrentAccountId: this.setCurrentAccountId,
          setCurrentPalettePickerMaterialId:
            this.setCurrentPalettePickerMaterialId,
          setOriginalPalettePickerColorId: this.setOriginalPalettePickerColorId,
          setCurrentPalettePickerColorId: this.setCurrentPalettePickerColorId,
          setCurrentSchemeId: this.setCurrentSchemeId,
          setCustomMaterialColor: this.setCustomMaterialColor,
          clearCustomMaterialColor: this.clearCustomMaterialColor,
          setRoomSchemeId: this.setRoomSchemeId,
          setRoomCustomElement: this.setRoomCustomElement,
          clearRoomCustomElement: this.clearRoomCustomElement,
          clearAllRoomCustomElements: this.clearAllRoomCustomElements,
        }}
      >
        {this.props.children}
      </SelectionContext.Provider>
    )
  }
}

SelectionProvider.defaultProps = {
  initialSelectionState: null,
}

SelectionProvider.propTypes = {
  initialSelectionState: PropTypes.object,
}

function useSelection() {
  return useContext(SelectionContext)
}

export { SelectionProvider, SelectionConsumer, useSelection }
