import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import ImageSkeleton from '../ImageSkeleton'
import { getSwatchCloudinaryPath } from '../../utils/cloudinaryPaths'
import {
  getSwatchIdentifier,
  getColorIdentifier,
  getColorName,
  isLayer,
} from '../../utils/roomLayer'
import '../../types'

const useStyles = makeStyles((theme) => ({
  materialCard: {
    margin: 5,
    display: 'flex',
    flexFlow: 'column',
    minWidth: '160px',
    maxWidth: '200px',
  },
  materialCardResponsive: {
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
      gridColumn: '1 / -1',
      minWidth: 'unset',
      maxWidth: 'unset',
    },
  },
  materialName: {
    fontSize: 16,
    fontWeight: '500', // Should enable PDF editing
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  colorName: {
    fontWeight: '400', // Should enable PDF editing
  },
  materialImageContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  materialColor: {
    position: 'relative',
    width: '90px !important',
    height: '90px !important',
  },
  materialImage: {
    width: '90px !important',
    height: '90px !important',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    padding: 0,
    margin: 5,
    height: 'inherit',
  },
  cardContentResponsive: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
  },
  imageWrapper: {
    position: 'relative',
    maxHeight: 90,
    maxWidth: 90,
  },
  selectedIcon: {
    position: 'absolute',
    fill: 'white',
    stroke: '#646464',
    strokeWidth: '1px',
    strokeLinejoin: 'round',
    bottom: 0,
    right: 0,
  },
}))

/**
 * @typedef BaseMaterialCardProps
 * @prop {() => void} onClick
 * @prop {() => void} onMouseEnter
 * @prop {() => void} onMouseLeave
 * @prop {boolean} disableScaling
 * @prop {React.ReactNode} children
 */

const BaseMaterialCard = React.forwardRef(
  /**
   * @param {BaseMaterialCardProps} props
   * @param {React.ForwardedRef<Card>} ref
   */
  function BaseMaterialCard(
    { disableScaling, onClick, onMouseEnter, onMouseLeave, children },
    ref
  ) {
    const classes = useStyles()

    return (
      <Card
        ref={ref}
        // Inglenook (or Puppeteer) doesn't seem to respect media-queries properly
        // In that case, the brochure page will use material-cards with no reseponsive
        // media queries
        className={
          !disableScaling
            ? `${classes.materialCard} ${classes.materialCardResponsive}`
            : classes.materialCard
        }
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardActionArea className={classes.cardActionArea}>
          <CardContent
            className={
              !disableScaling
                ? `${classes.cardContent} ${classes.cardContentResponsive}`
                : classes.cardContent
            }
          >
            {children}
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
)

BaseMaterialCard.propTypes = {
  disableScaling: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
}

BaseMaterialCard.defaultProps = {
  disableScaling: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  children: null,
}

/**
 * @typedef MaterialCardLayerProps
 * @prop {React.ReactNode} children
 * @prop {Layer} layer
 * @prop {boolean} selected
 * @prop {boolean} hideMaskName
 * @prop {boolean} disableScaling
 * @prop {() => void=} onClick
 * @prop {() => void=} onMouseEnter
 * @prop {() => void=} onMouseLeave
 */

/**
 *
 * @param {MaterialCardLayerProps} props
 * @returns
 */
function MaterialCardLayer({
  children,
  layer,
  selected,
  disableScaling,
  hideMaskName,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const classes = useStyles()
  const swatchUrl = getSwatchCloudinaryPath(
    layer?.swatch?.image,
    'w_90,h_90,c_fill'
  )

  return (
    <BaseMaterialCard
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableScaling={disableScaling}
    >
      <div className={classes.materialImageContainer}>
        <div className={classes.imageWrapper}>
          <ImageSkeleton
            src={swatchUrl}
            alt={layer?.swatch?.name}
            className={classes.materialImage}
          />

          {selected && (
            <CheckRoundedIcon
              fontSize="large"
              className={classes.selectedIcon}
            />
          )}
        </div>
      </div>

      <div>
        {!hideMaskName && (
          <Typography variant="h6" className={classes.materialName}>
            {layer?.mask?.material?.display_name}
          </Typography>
        )}
        <Typography variant="body2" className={classes.colorName}>
          {getSwatchIdentifier(layer)}
        </Typography>
        <Typography variant="body2" className={classes.colorName}>
          {layer?.swatch?.name}
        </Typography>
      </div>

      {children}
    </BaseMaterialCard>
  )
}

MaterialCardLayer.propTypes = {
  children: PropTypes.node,
  layer: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  disableScaling: PropTypes.bool,
  hideMaskName: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

MaterialCardLayer.defaultProps = {
  children: null,
  disableScaling: false,
  hideMaskName: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
}

/**
 * @typedef MaterialCardColorProps
 * @prop {React.ReactNode} children
 * @prop {ColorLayer} color
 * @prop {boolean} hideMaskName
 * @prop {boolean} disableScaling
 * @prop {() => void} onClick
 * @prop {() => void=} onMouseEnter
 * @prop {() => void=} onMouseLeave
 */

/**
 * @param {MaterialCardColorProps} props
 */
function MaterialCardColor({
  children,
  color,
  selected,
  disableScaling,
  hideMaskName,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const classes = useStyles()

  return (
    <BaseMaterialCard
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableScaling={disableScaling}
    >
      <div className={classes.materialImageContainer}>
        <div
          className={classes.materialColor}
          style={{ backgroundColor: `#${color?.hex ?? color?.color?.hex}` }}
        >
          {selected && (
            <CheckRoundedIcon
              fontSize="large"
              className={classes.selectedIcon}
            />
          )}
        </div>
      </div>

      <div>
        {!hideMaskName && (
          <Typography variant="h6" className={classes.materialName}>
            {color?.mask?.material?.display_name}
          </Typography>
        )}
        <Typography variant="body2" className={classes.colorName}>
          {getColorIdentifier(color)}
        </Typography>
        <Typography variant="body2" className={classes.colorName}>
          {getColorName(color)}
        </Typography>
      </div>

      {children}
    </BaseMaterialCard>
  )
}

MaterialCardColor.propTypes = {
  children: PropTypes.node,
  color: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  disableScaling: PropTypes.bool,
  hideMaskName: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

MaterialCardColor.defaultProps = {
  children: null,
  selected: false,
  disableScaling: false,
  hideMaskName: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
}

/**
 * @typedef MaterialCardProps
 * @prop {SchemeElement} element
 */

/**
 * @param {MaterialCardProps} props
 */
function MaterialCard({ element, ...props }) {
  return isLayer(element) ? (
    <MaterialCardLayer layer={element} {...props} />
  ) : (
    <MaterialCardColor color={element} {...props} />
  )
}

MaterialCard.propTypes = {
  element: PropTypes.object.isRequired,
}

MaterialCard.defaultProps = {}

const MaterialCardLoading = React.forwardRef(function MaterialCardLoading(
  props,
  ref
) {
  const classes = useStyles()

  return (
    <BaseMaterialCard ref={ref} className={classes.materialCard} {...props}>
      <div className={classes.materialImageContainer}>
        <ImageSkeleton.Loading className={classes.materialImage} />
      </div>

      <div>
        <Skeleton variant="text" className={classes.materialName} width={60} />
        <Skeleton variant="text" className={classes.colorName} width={90} />
        <Skeleton variant="text" className={classes.colorName} width={90} />
      </div>
    </BaseMaterialCard>
  )
})

MaterialCard.Layer = MaterialCardLayer
MaterialCard.Color = MaterialCardColor
MaterialCard.Loading = MaterialCardLoading

export default MaterialCard
