import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import SchemeCard from './SchemeCard'
import '../../../../types'

const useStyles = makeStyles((theme) => ({
  schemesMaterialsContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    maxHeight: '100%',
    '@media screen and (orientation: landscape)': {
      padding: 20,
    },
    '@media screen and (orientation: portrait)': {
      padding: 5,
      height: 'calc(100vh - 92px)',
    },
  },
  schemesContainer: {
    padding: 5,
    overflowY: 'auto',
    height: '60%',
  },
  materialsContainer: {
    padding: 10,
    overflowY: 'auto',
    height: '40%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectScheme: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

/**
 * @typedef SchemeSelectProps
 * @prop {Scheme[]} schemes
 * @prop {boolean} loading
 * @prop {Scheme} selectedScheme
 * @prop {(scheme: Scheme) => void} onSelect
 */

/**
 * @param {SchemeSelectProps} props
 * @returns {React.ReactNode}
 */
export default function SchemeSelect({
  schemes,
  loading,
  selectedScheme,
  onSelect,
}) {
  const classes = useStyles()

  return (
    <Paper className={classes.schemesContainer}>
      {!loading ? (
        schemes.map((scheme) => (
          <SchemeCard
            key={scheme.id}
            scheme={scheme}
            selected={selectedScheme?.id === scheme.id}
            onClick={() => onSelect(scheme)}
          />
        ))
      ) : (
        <>
          <SchemeCard.Loading />
          <SchemeCard.Loading />
          <SchemeCard.Loading />
        </>
      )}
    </Paper>
  )
}

SchemeSelect.propTypes = {
  schemes: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedScheme: PropTypes.object,
  onSelect: PropTypes.func,
}

SchemeSelect.defaultProps = {
  selectedScheme: null,
  onSelect: null,
}
