import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Slider, makeStyles } from '@material-ui/core'
import { useDebounce } from '../utils/hooks'

const styles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: theme.palette.background.default,
  },
}))

const MaterialFilter = ({ homescapes, setHomescapes }) => {
  const classes = styles()
  const [value, setValue] = useState([1, 10])
  const [maxMaterials, setMaxMaterials] = useState(0)
  const marks = [
    { value: value[0], label: `${value[0]}` },
    { value: value[1], label: `${value[1]}` },
  ]

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  const debouncedValue = useDebounce(value, 300)

  useEffect(() => {
    let maxMaterialsToFilter = 0
    homescapes.forEach((homescape) => {
      if (homescape.views[0].view_elements.length > maxMaterialsToFilter) {
        maxMaterialsToFilter = homescape.views[0].view_elements.length
      }
    })
    setValue([1, maxMaterialsToFilter])
    setMaxMaterials(maxMaterialsToFilter)
  }, [])

  useEffect(() => {
    const filtered = homescapes.filter(
      (homescape) =>
        homescape.views[0].view_elements.length >= value[0] &&
        homescape.views[0].view_elements.length <= value[1]
    )
    setHomescapes(filtered)
  }, [debouncedValue])

  return (
    <div className={classes.filterContainer}>
      <Typography>Number of Materials</Typography>
      <Slider
        onChange={handleChange}
        value={value}
        aria-labelledby="range-slider"
        min={1}
        max={maxMaterials}
        style={{ width: 250 }}
        marks={marks}
        valueLabelDisplay="off"
      />
    </div>
  )
}

MaterialFilter.propTypes = {
  homescapes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      street_address: PropTypes.string,
      views: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          thumbnail_src: PropTypes.string,
          base_image_src: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  setHomescapes: PropTypes.func.isRequired,
}

export default MaterialFilter
