import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { BASE_IMAGE_URL } from './constants/Paths'
import { getImageThumbnail } from '../utils/helper'
import PropertyCard, { CARD_THUMBNAIL_SIZE } from './PropertyCard'

export default function BuildingCard({
  building,
  account,
  property,
  onSelect,
}) {
  const imageThumbnail = getImageThumbnail(account, property, building)
  const imageSrc =
    imageThumbnail &&
    `${BASE_IMAGE_URL}/q_auto,w_${CARD_THUMBNAIL_SIZE},c_scale/app/${account.cloud_folder_name}/${imageThumbnail}`

  return (
    <PropertyCard
      title={building?.name ?? property?.name ?? account.name}
      thumbnailSrc={imageSrc}
      onClick={() => onSelect(building || property || account)}
      content={(classes) => (
        <>
          {building?.name && (
            <Typography variant="h5" className={classes.contentText}>
              {building?.name}
            </Typography>
          )}
          {building?.street_address && (
            <Typography variant="body1" className={classes.contentTextBody}>
              {building?.street_address}
            </Typography>
          )}
          {property?.name && (
            <Typography variant="h5" className={classes.contentText}>
              {property?.name}
            </Typography>
          )}
          {property?.street_address && (
            <Typography variant="body1" className={classes.contentTextBody}>
              {property?.street_address}
            </Typography>
          )}
          {!building && !property && (
            <Typography variant="h5" className={classes.contentText}>
              {account?.account_name}
            </Typography>
          )}
        </>
      )}
    />
  )
}

BuildingCard.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    street_address: PropTypes.string,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        thumbnail_src: PropTypes.string,
        base_image_src: PropTypes.string,
      })
    ),
  }),
  property: PropTypes.shape({
    name: PropTypes.string,
    street_address: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    account_name: PropTypes.string,
    cloud_folder_name: PropTypes.string,
  }).isRequired,
}

BuildingCard.defaultProps = {
  building: null,
  property: null,
}
