import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, { useState } from 'react'

const AppGuideModal = () => {
  const [open, setOpen] = useState(true)

  return (
    <Dialog open={open}>
      <DialogTitle>Welcome to the Color Visualizer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you would like to watch a quick video tutorial, click{' '}
          <a
            onClick={() => setOpen(false)}
            target="_blank"
            rel="noopener noreferrer"
            href="https://cvhelp.anewgo.com/shopping_cart_mode/index.html"
          >
            here
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        >
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppGuideModal
