import { useQuery } from '@apollo/client'
import { ACCOUNT_BY_NAME } from '../graphql/accounts.graphql'
import '../types'

/**
 * @param {string} accountName
 * @returns {ApolloReturn<{ accountByName: Account }>}
 */
export default function useAccountByName(accountName) {
  return useQuery(ACCOUNT_BY_NAME, {
    variables: {
      accountName,
    },
  })
}
