import jwt from 'jsonwebtoken'

const getSessionIdAndToken = async (sessionState, addSession) => {
  const result = await addSession({
    input: {
      session_data: JSON.stringify(sessionState),
    },
  })

  let sessionId
  let token = null
  if (result?.data?.addSession?.id) {
    sessionId = result.data.addSession.id

    const payload = {
      session_id: sessionId,
    }

    // Create a token with no expiration.
    token = jwt.sign(payload, 'notasecret', {})
  }

  return { sessionId, token }
}

export { getSessionIdAndToken }
