import React from 'react'
import PropTypes from 'prop-types'
import { BASE_IMAGE_URL } from '../../../constants/Paths'

/**
 * @typedef LogoProps
 * @prop {'small' | 'large'} size
 */

/**
 * @param {LogoProps} props
 */
export default function Logo({ size }) {
  return (
    <img
      alt="LOGO"
      src={`${BASE_IMAGE_URL}/v1612887430/app/cv/shw/app_images/shwphotoImaging_546873545965.png`}
      height={size === 'small' ? '45px' : '60px'}
    />
  )
}

Logo.propTypes = {
  size: PropTypes.string,
}

Logo.defaultProps = {
  size: 'small',
}
