import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { Loading } from './common/Loading'
import { MATERIAL_WITH_PALETTES } from '../graphql/materials.graphql'
import { withQueryResultAsProp } from '../utils'
import Swatch from './Swatch'

const styles = makeStyles((theme) => ({
  palettePickerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      '@media screen and (orientation: portrait)': {
        height: '25vh',
      },
    },
  },
}))

const PalettePicker = ({
  material,
  filterInput,
  handleSelectColor,
  selectedColorId,
}) => {
  const classes = styles()
  const LIMIT = 50
  const [filteredPaletteSelection, setFilteredPaletteSelection] = useState(null)
  const [combinedPaletteSelections, setCombinedPaletteSelections] =
    useState(null)
  const [paginatedColors, setPaginatedColors] = useState()
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()

  useEffect(() => {
    // Merge all the palettes together by adding colors from each additional palette to the map only
    // if they are not already added.
    const combinedPaletteSelectionsMap = new Map()

    material.palettes.forEach((palette) => {
      palette.palette_selections.forEach((paletteSelection) => {
        if (!combinedPaletteSelectionsMap.has(paletteSelection.color.id)) {
          combinedPaletteSelectionsMap.set(
            paletteSelection.color.id,
            paletteSelection
          )
        }
      })
    })
    setCombinedPaletteSelections([...combinedPaletteSelectionsMap.values()])
  }, [])

  useEffect(() => {
    const filteredPalettes =
      combinedPaletteSelections &&
      combinedPaletteSelections.filter(
        (paletteSelection) =>
          filterInput === '' ||
          paletteSelection.color.name
            .toLowerCase()
            .includes(filterInput.toLowerCase()) ||
          (paletteSelection.color.identifier &&
            paletteSelection.color.identifier
              .toLowerCase()
              .includes(filterInput.toLowerCase()))
      )
    setFilteredPaletteSelection(filteredPalettes)
    setPaginatedColors(filteredPalettes && filteredPalettes.slice(0, LIMIT))
    setHasMore(true)
    const container = document.getElementById('palettePickerContainder')
    container.scrollTop = 0
  }, [filterInput, combinedPaletteSelections])

  const handleLoadItems = () => {
    const nextPaginatedColors = [
      ...filteredPaletteSelection.slice(
        paginatedColors.length,
        paginatedColors.length + LIMIT
      ),
    ]

    setPaginatedColors((prevPaginated) => [
      ...prevPaginated,
      ...nextPaginatedColors,
    ])

    if (filteredPaletteSelection.length === paginatedColors.length) {
      setHasMore(false)
    }
  }

  const lastElement = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadItems()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [hasMore, paginatedColors, filteredPaletteSelection]
  )

  return (
    <div
      id="palettePickerContainder"
      className={classes.palettePickerContainer}
    >
      {paginatedColors &&
        paginatedColors.map((paletteSelection, index) => {
          const selected = selectedColorId === paletteSelection.color.id
          return (
            <Swatch
              key={`${paletteSelection.id}`}
              paletteItem={paletteSelection}
              handleSelectColor={handleSelectColor}
              refElement={
                paginatedColors.length === index + 1 ? lastElement : undefined
              }
              selected={selected}
            />
          )
        })}
    </div>
  )
}

PalettePicker.propTypes = {
  material: PropTypes.shape({
    account_id: PropTypes.number,
    display_name: PropTypes.string,
    element_id: PropTypes.number,
    id: PropTypes.number,
    palettes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        palette_selections: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            palette_id: PropTypes.number,
            color: PropTypes.object,
          })
        ),
      })
    ),
  }).isRequired,
  filterInput: PropTypes.string.isRequired,
  handleSelectColor: PropTypes.func.isRequired,
  selectedColorId: PropTypes.number,
}

PalettePicker.defaultProps = {
  selectedColorId: null,
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: MATERIAL_WITH_PALETTES,
    variables: ({ materialId }) => ({
      materialId,
    }),
    resultPropName: 'material',
    LoadingComponent: Loading,
  })
)(PalettePicker)

export { WrappedComponent as PalettePicker }
