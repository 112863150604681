function mergeRefArray(existing, incoming) {
  const refSet = new Set()
  return [...(existing ?? []), ...(incoming ?? [])].filter(({ __ref }) => {
    const hasRef = refSet.has(__ref)
    if (!hasRef) refSet.add(__ref)
    return !hasRef
  })
}

export default {
  Query: {
    fields: {
      layers: {
        keyArgs: ['filter', 'ordering'],
        merge(existing, incoming) {
          return {
            ...incoming,
            items: mergeRefArray(existing?.items, incoming?.items),
          }
        },
      },
      colors: {
        keyArgs: ['filter', 'ordering'],
        merge(existing, incoming) {
          return {
            ...incoming,
            items: mergeRefArray(existing?.items, incoming?.items),
          }
        },
      },
    },
  },
}
