import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import {
  getColorIdentier,
  getColorName,
  getMaterialName,
} from '../utils/helper'

const styles = makeStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    flexFlow: 'row',
    width: 'max-content',
    alignItems: 'center',
  },
  itemText: {
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
}))

const MaterialList = ({ schemeMaterial, customScheme, viewElement }) => {
  const classes = styles()
  const customSchemeMaterial =
    customScheme &&
    customScheme.materials &&
    customScheme.materials.filter(
      (material) =>
        schemeMaterial && schemeMaterial.material.id === material.materialId
    )[0]

  return (
    <React.Fragment>
      <div className={classes.itemContainer}>
        <Typography
          className={classes.itemText}
          style={{ fontWeight: 500 }}
          variant="body2"
        >
          {getMaterialName(viewElement, customSchemeMaterial, schemeMaterial)}
        </Typography>
        <Typography className={classes.itemText} variant="body2">
          {getColorIdentier(customSchemeMaterial, schemeMaterial)}
        </Typography>
        <Typography className={classes.itemText} variant="body2">
          {getColorName(customSchemeMaterial, schemeMaterial)}
        </Typography>
      </div>
    </React.Fragment>
  )
}

MaterialList.propTypes = {
  schemeMaterial: PropTypes.shape({
    id: PropTypes.number,
    element_id: PropTypes.number,
    material: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
    }),
    color: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
      hex: PropTypes.string,
      color_vendor_product_info: PropTypes.shape({
        id: PropTypes.number,
        product_identifier_prefix: PropTypes.string,
      }),
    }),
  }).isRequired,
  customScheme: PropTypes.shape({
    schemeId: PropTypes.number,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        colorId: PropTypes.number,
        colorPaletteId: PropTypes.number,
        colorName: PropTypes.string,
        colorIdentifier: PropTypes.string,
        color: PropTypes.string,
        vendorPrefix: PropTypes.string,
        elementId: PropTypes.number,
        materialId: PropTypes.number,
      })
    ),
  }),
  viewElement: PropTypes.shape({
    id: PropTypes.number,
    list_order: PropTypes.number,
    material: PropTypes.shape({
      display_name: PropTypes.string,
      element_id: PropTypes.number,
      id: PropTypes.number,
    }),
  }).isRequired,
}

MaterialList.defaultProps = {
  customScheme: {},
}

export default MaterialList
