import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import '../types'

/**
 * @typedef Message
 * @prop {number} id
 * @prop {string} message
 * @prop {string=} title
 * @prop {number} duration
 * @prop {import("@material-ui/lab/Alert").Color} type
 * @prop {import("@material-ui/core/Snackbar").SnackbarOrigin} anchor
 */

const DEFAULT_MESSAGE_VALUES = Object.freeze({
  duration: 10000,
  type: 'info',
  anchor: {
    horizontal: 'center',
    vertical: 'top',
  },
})

/**
 * @typedef ISnackbarContext
 * @prop {(message: Message) => void} addMessage
 */

/** @type {React.Context<ISnackbarContext>} */
const SnackbarContext = React.createContext()

/**
 * @typedef SnackProviderProps
 * @prop {React.ReactNode} children
 */

/**
 * @param {SnackProviderProps} props
 */

export default function SnackProvider({ children }) {
  const snackCounter = useRef(0)

  /** @type {ReactState<Message[]>} */
  const [messages, setMessages] = useState([])

  /** @type {ReactState<Message>} */
  const [currentMessage, setCurrentMessage] = useState(null)

  const addMessage = useCallback(
    /** @param {Message} message */
    (message) => {
      const id = snackCounter.current++
      setMessages((existing) => [
        ...existing,
        {
          ...DEFAULT_MESSAGE_VALUES,
          ...message,
          id,
        },
      ])
    },
    []
  )

  const nextMessage = () => {
    const [newMessage, ...rest] = messages
    setCurrentMessage(newMessage)
    setMessages(rest)
  }

  const value = useMemo(() => ({ addMessage }), [addMessage])

  if (!currentMessage && messages.length > 0) {
    nextMessage()
  }

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        key={currentMessage?.id}
        open={currentMessage}
        autoHideDuration={currentMessage?.duration}
        anchorOrigin={currentMessage?.anchor}
        transitionDuration={300}
        ClickAwayListenerProps={{ mouseEvent: false }}
        onClose={nextMessage}
      >
        <Alert severity={currentMessage?.type} onClose={nextMessage}>
          {currentMessage?.title && (
            <AlertTitle>{currentMessage?.title}</AlertTitle>
          )}
          {currentMessage?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

SnackProvider.propTypes = {
  children: PropTypes.node,
}

SnackProvider.defaultProps = {
  children: null,
}

export function useSnackbar() {
  return useContext(SnackbarContext)
}
