import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { IconButton, InputAdornment, Typography } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { PROPERTIES_BY_ACCOUNT_NAME } from '../../graphql/properties.graphql'
import { withQueryResultAsProp } from '../../utils'
import BuildingCard from '../BuildingCard'

const styles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 40,
    },
  },
  headerText: {
    display: 'flex',
    padding: 10,
    width: '80%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px auto 0',
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  noResult: {
    marginTop: 20,
  },
}))

const isSearchFit = (search, property) => {
  // NAME
  const name = property.name.toLowerCase()
  // STREET ADDRESS
  const streetAddress = property.street_address?.toLowerCase() || ''

  const formattedSearch = search.trim().toLowerCase()
  const searchArr = formattedSearch.split(' ')
  return searchArr.every(
    (s) =>
      name.indexOf(s.toLowerCase()) !== -1 ||
      streetAddress.indexOf(s.toLowerCase()) !== -1
  )
}

// Display the first building's first view's thumbnail as the representative
// view of the property.
const AccountPage = ({ account, location, match, history }) => {
  const classes = styles()
  const [search, setSearch] = useState('')
  // Parse out token
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const tokenQueryParam = token ? `?token=${token}` : ''
  let displaySubAccounts = account?.sub_accounts?.length > 0
  const properties = account?.properties
  const subAccounts = account?.sub_accounts && [...account?.sub_accounts]

  // If there are properties and sub accounts at the same time
  // add sub account "Default Projects", which contains unasigned properties.
  if (
    properties?.length > 0 &&
    displaySubAccounts &&
    !subAccounts?.find((subAccount) => subAccount.id === account.id)
  ) {
    subAccounts.unshift({
      account_name: 'Default Projects',
      cloud_folder_name: account.cloud_folder_name,
      id: account.id,
      properties: account.properties,
    })
  }

  if (match.params.subAccountName && displaySubAccounts) {
    const selectedSubAccount = subAccounts.find(
      (subAccount) => subAccount.account_name === match.params.subAccountName
    )

    if (selectedSubAccount) {
      // We don't nest subaccounts. If we successfully locate the requested subAccount,
      // don't display subaccount cards, only properties.
      displaySubAccounts = false
      account = selectedSubAccount
    } else {
      window.location.replace(
        `${process.env.FRONT_END_APP_SERVER}/account/${account.account_name}`
      )
    }
  }

  const handleSelectProperty = (property) => {
    const baseUrl = match.url // replace('/subAccount/Default Projects', '')
    history.push(`${baseUrl}/project/${property.id}${tokenQueryParam}`)
  }

  const handleSelectSubAccount = (subAccount) => {
    const baseUrl = match.url
    history.push(`${baseUrl}/subAccount/${subAccount.account_name}`)
  }

  // eslint-disable-next-line react/display-name
  const renderSubAccounts = subAccounts?.map((subAccount) => (
    <BuildingCard
      key={subAccount.id}
      account={subAccount}
      onSelect={handleSelectSubAccount}
    />
  ))

  const renderProperties = account?.properties
    .filter((fProperty) => {
      if (fProperty.is_active === true) {
        return isSearchFit(search, fProperty)
      }

      return false
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((property) => (
      <BuildingCard
        key={property.id}
        account={account}
        property={property}
        onSelect={handleSelectProperty}
      />
    ))

  return (
    <React.Fragment>
      <div className={classes.headerText}>
        <Typography variant="h5">
          {displaySubAccounts ? 'Select Account' : 'Select Your Project'}
        </Typography>
        {!displaySubAccounts && (
          <span className={classes.search}>
            <Input
              className={classes.searchInput}
              label="Search"
              variant="outlined"
              placeholder="Type to search project..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                search ? (
                  <IconButton
                    onClick={() => setSearch('')}
                    variant="contained"
                    color="primary"
                  >
                    <CloseIcon />
                  </IconButton>
                ) : undefined
              }
            />
          </span>
        )}
      </div>
      <div className={classes.cardContainer}>
        {displaySubAccounts && renderSubAccounts}
        {!displaySubAccounts && renderProperties.length > 0 ? (
          renderProperties
        ) : (
          <h2 className={classes.noResult}>
            {search.length > 0
              ? `No Result for search: ${search}`
              : 'No Result'}
          </h2>
        )}
      </div>
    </React.Fragment>
  )
}

AccountPage.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    cloud_folder_name: PropTypes.string,
    account_name: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        buildings: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            street_address: PropTypes.string,
            views: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                base_image_src: PropTypes.string,
                thumbnail_src: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
    sub_accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }).isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: PROPERTIES_BY_ACCOUNT_NAME,
    variables: ({ match }) => ({ accountName: match.params.accountName }),
    resultPropName: 'accountByName',
    propName: 'account',
  }),
  withRouter
)(AccountPage)

export { WrappedComponent as AccountPage }
