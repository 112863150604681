import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import objectHash from 'object-hash'
import React, { useEffect, useState } from 'react'
import { formatCurrentDesignToCartItemFormat } from '../utils/helper'
import Snackbar from './Snackbar'
import NameSchemeDialog from './NameSchemeDialog'

const styles = makeStyles((theme) => ({
  addToCartButton: {
    maxWidth: '100%',
    padding: 15,
    gridColumn: 3,
    gridRow: 2,
    alignSelf: 'center',
    justifySelf: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '13px 8px',
      marginBottom: 50,
    },
  },
}))

const AddToCartButton = ({
  currentDesign,
  handleAddToCart,
  cart,
  selectedScheme,
}) => {
  const classes = styles()
  const [disable, setDisable] = useState(true)
  const [successOpen, setSuccessOpen] = useState(false)
  const [schemeNameDialogOpen, setSchemeNameDialogOpen] = useState(false)

  const handleClick = () => {
    setSchemeNameDialogOpen(true)
  }

  const handleClose = () => {
    setSchemeNameDialogOpen(false)
  }

  const handleSubmit = (schemeName) => {
    handleAddToCart(schemeName)
    setSuccessOpen(true)
    setSchemeNameDialogOpen(false)
  }

  useEffect(() => {
    const checkIfItemInCart = async () => {
      if (currentDesign && !currentDesign.schemeId) {
        setDisable(true)
        return
      }
      const currentDesignAddToCart =
        formatCurrentDesignToCartItemFormat(currentDesign)
      const currentDesignHash = objectHash(currentDesignAddToCart)
      setDisable(
        cart.some((cartDesign) => objectHash(cartDesign) === currentDesignHash)
      )
    }
    checkIfItemInCart()
  }, [currentDesign])

  return (
    <React.Fragment>
      <NameSchemeDialog
        key={selectedScheme?.name}
        open={schemeNameDialogOpen}
        cart={cart}
        initialName={selectedScheme?.name}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
      <Button
        className={classes.addToCartButton}
        size="large"
        variant="contained"
        color="primary"
        disabled={disable}
        onClick={() => handleClick()}
      >
        Add to cart
      </Button>
      <Snackbar
        open={successOpen}
        setOpen={setSuccessOpen}
        message="Design added to cart."
        severity="success"
      />
    </React.Fragment>
  )
}

AddToCartButton.propTypes = {
  handleAddToCart: PropTypes.func.isRequired,
  selectedScheme: PropTypes.object,
  currentDesign: PropTypes.shape({
    schemeId: PropTypes.number,
    viewId: PropTypes.number,
    customSchemes: PropTypes.arrayOf(
      PropTypes.shape({
        schemeId: PropTypes.number,
        materials: PropTypes.arrayOf(
          PropTypes.shape({
            colorId: PropTypes.number,
            colorPaletteId: PropTypes.number,
            colorName: PropTypes.string,
            colorIdentifier: PropTypes.string,
            color: PropTypes.string,
            vendorPrefix: PropTypes.string,
            elementId: PropTypes.number,
            materialId: PropTypes.number,
          })
        ),
      })
    ),
  }),
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      schemeId: PropTypes.number,
      viewId: PropTypes.number,
      customSchemes: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          materials: PropTypes.arrayOf(
            PropTypes.shape({
              colorId: PropTypes.number,
              colorPaletteId: PropTypes.number,
              elementId: PropTypes.number,
              materialId: PropTypes.number,
            })
          ),
        })
      ),
    })
  ),
}

AddToCartButton.defaultProps = {
  currentDesign: {},
  cart: [],
  selectedScheme: null,
}

export default AddToCartButton
