const privilegeMaterialNames = [
  '1st Floor',
  '2nd Floor',
  '3rd Floor',
  'Body',
  'Brick',
  'Horizontal Siding',
  'Lap Siding',
  'Lower Body',
  'Siding',
  'Stucco',
  'Upper Body',
  'Vinyl Siding',
  'Wall',
]

function getPath(path, obj, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}

export const materialCustomSorter = (elements, path) =>
  [...elements].sort((a, b) => {
    const nameA = getPath(path, a)
    const nameB = getPath(path, b)
    const privilegeMaterialNameA = privilegeMaterialNames.some((customName) =>
      nameA.startsWith(customName)
    )
    const privilegeMaterialNameB = privilegeMaterialNames.some((customName) =>
      nameB.startsWith(customName)
    )

    // If both material are privileges
    if (privilegeMaterialNameA && privilegeMaterialNameB) {
      return nameA.localeCompare(nameB)
    }

    // If material A is privilege
    if (privilegeMaterialNameA && !privilegeMaterialNameB) {
      return -1
    }

    // If material B is privilege
    if (!privilegeMaterialNameA && privilegeMaterialNameB) {
      return 1
    }

    // If both material are not privileges
    return nameA.localeCompare(nameB)
  })
