/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import objectHash from 'object-hash'

const StoreContext = React.createContext()

const StoreConsumer = StoreContext.Consumer

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.
class StoreProvider extends Component {
  state = this.props.initialState || {}

  getState = () => cloneDeep(this.state)

  getHash = () => objectHash(this.state)

  // Emulate redux-thunk store middleware.
  dispatch = async (action) => {
    if (typeof action === 'function') {
      // If the action is a thunk, run the thunk and return its value.
      const thunkResult = await action({
        dispatch: this.dispatch,
        getState: this.getState,
        getHash: this.getHash,
      })
      return thunkResult
    }

    // If the action is either a Promise or an object, attempt to resolve it first, then pass it
    // as an action object to the reducer.
    const actionResult = await action

    return this.setState((state) => this.props.reducer(state, actionResult))
  }

  render() {
    return (
      <StoreContext.Provider
        value={{
          state: cloneDeep(this.state),
          dispatch: this.dispatch,
          getState: this.getState,
          getHash: this.getHash,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }
}

StoreProvider.defaultProps = {
  initialState: null,
}

StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
  initialState: PropTypes.object,
  reducer: PropTypes.func.isRequired,
}

export { StoreProvider, StoreConsumer }
