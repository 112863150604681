import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Snackbar from './Snackbar'

const styles = makeStyles((theme) => ({
  saveEditsButton: {
    maxWidth: '100%',
    padding: 15,
    gridColumn: 3,
    gridRow: 2,
    alignSelf: 'center',
    justifySelf: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '13px 8px',
      marginBottom: 50,
    },
  },
}))

const SaveEditsButton = ({ handleSaveEdits, openAlert, setOpenAlert }) => {
  const classes = styles()

  return (
    <React.Fragment>
      <Button
        className={classes.saveEditsButton}
        size="large"
        variant="contained"
        color="primary"
        onClick={() => handleSaveEdits()}
      >
        Save Edits
      </Button>
      <Snackbar
        open={openAlert}
        setOpen={setOpenAlert}
        message="Design already in cart."
        severity="error"
      />
    </React.Fragment>
  )
}

SaveEditsButton.propTypes = {
  handleSaveEdits: PropTypes.func.isRequired,
  openAlert: PropTypes.bool.isRequired,
  setOpenAlert: PropTypes.func.isRequired,
}

export default SaveEditsButton
