import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {
  getMaterialName,
  getColorIdentier,
  getColorName,
  getTextColor,
} from '../utils/helper'

const styles = makeStyles((theme) => ({
  schemeCard: {
    width: '30%',
    margin: 5,
    display: 'flex',
    flexFlow: 'column',
    height: 90,
    [theme.breakpoints.down('md')]: {
      height: 70,
      margin: 3,
    },
    [theme.breakpoints.up(1921)]: {
      height: 'auto',
      padding: 10,
    },
  },
  schemeCard2: {
    width: '30%',
    margin: 5,
    display: 'flex',
    flexFlow: 'column',
    height: 'maxContent',
    [theme.breakpoints.down('md')]: {
      height: 70,
      margin: 3,
    },
    [theme.breakpoints.up(1921)]: {
      height: 'auto',
      padding: 10,
    },
  },
  cardContent: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
  materialName: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  materialName2: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginTop: 3,
    color: '#000',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const hasIdentifier = (ident, identPrefix) => {
  if (ident === null) {
    return ''
  }
  if (identPrefix !== null) {
    return `${identPrefix} ${ident}`
  }
  return `${ident}`
}

const materialFontSizer = (materialFont, index) => {
  if (materialFont.length >= 20) {
    // using a font weight attribute to let the text be editable in pdf. Look at FLAG-1998.
    return (
      <div
        key={`${materialFont}-${index}`}
        style={{ fontSize: 'inherit', fontWeight: '700' }}
      >
        {`${materialFont}\n`}
      </div>
    )
  }
  // using a font weight attribute to let the text be editable in pdf. Look at FLAG-1998.
  return (
    <div
      key={`${materialFont}-${index}`}
      style={{ fontSize: 'inherit', fontWeight: '700' }}
    >
      {`${materialFont}\n`}
    </div>
  )
}

const MaterialCard = ({
  schemeMaterial,
  defaultMaterialColor,
  style,
  customScheme,
  viewElement,
  handleSelectMaterial,
  colorObject,
  materials,
  group,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = styles()
  if (!group) {
    const customSchemeMaterial =
      !defaultMaterialColor &&
      customScheme &&
      customScheme.materials &&
      customScheme.materials.filter(
        (material) =>
          schemeMaterial && schemeMaterial.material.id === material.materialId
      )[0]
    const textColor = customSchemeMaterial
      ? getTextColor(customSchemeMaterial.color)
      : getTextColor(schemeMaterial.color.hex)

    return (
      <Card
        className={classes.schemeCard}
        style={{
          backgroundColor:
            customSchemeMaterial && customSchemeMaterial.color
              ? `#${customSchemeMaterial.color}`
              : `#${schemeMaterial.color.hex}`,
          color: `#${textColor}`,
          ...style,
        }}
        onClick={() => handleSelectMaterial(schemeMaterial.material.id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardActionArea style={{ height: 'inherit' }}>
          <CardContent style={{ padding: 0, margin: 5, height: 'inherit' }}>
            <Typography
              variant="h6"
              className={classes.materialName}
              style={{ color: `#${textColor}` }}
            >
              {getMaterialName(
                viewElement,
                customSchemeMaterial,
                schemeMaterial
              )}
            </Typography>
            <Typography className={classes.cardContent} variant="body2">
              {getColorIdentier(customSchemeMaterial, schemeMaterial)}
            </Typography>
            <Typography className={classes.cardContent} variant="body2">
              {getColorName(customSchemeMaterial, schemeMaterial)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
  const textColor = getTextColor(colorObject.hex)
  const key = `${colorObject}-${materials}`

  return (
    <div key={key}>
      <Card
        key={`Card-${key}`}
        className={classes.schemeCard2}
        style={{
          backgroundColor: `#${colorObject.hex}`,
          color: `#${textColor}`,
          ...style,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardActionArea
          className={classes.materialName2}
          style={{ height: 'inherit' }}
        >
          <CardContent style={{ padding: 0, margin: 5, height: 'inherit' }}>
            <Typography
              className={classes.cardContent}
              variant="body2"
              style={{ color: `#${textColor}` }}
            >
              {`${hasIdentifier(
                parseInt(colorObject.identifier, 10),
                colorObject.color_vendor_product_info.product_identifier_prefix
              )}`}
            </Typography>
            <Typography
              className={classes.cardContent}
              variant="body2"
              style={{ color: `#${textColor}` }}
            >
              {colorObject.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Typography
        key={`Typo-${key}`}
        component="div"
        className={classes.materialName2}
      >
        {materials.map((currMaterial, index) =>
          materialFontSizer(currMaterial, index)
        )}
      </Typography>
    </div>
  )
}

MaterialCard.propTypes = {
  style: PropTypes.object,
  defaultMaterialColor: PropTypes.bool,
  schemeMaterial: PropTypes.shape({
    id: PropTypes.number,
    element_id: PropTypes.number,
    material: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
    }),
    color: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
      hex: PropTypes.string,
      color_vendor_product_info: PropTypes.shape({
        id: PropTypes.number,
        product_identifier_prefix: PropTypes.string,
      }),
    }),
  }),
  customScheme: PropTypes.shape({
    schemeId: PropTypes.number,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        colorId: PropTypes.number,
        colorPaletteId: PropTypes.number,
        colorName: PropTypes.string,
        colorIdentifier: PropTypes.string,
        color: PropTypes.string,
        vendorPrefix: PropTypes.string,
        elementId: PropTypes.number,
        materialId: PropTypes.number,
      })
    ),
  }),
  handleSelectMaterial: PropTypes.func,
  viewElement: PropTypes.shape({
    id: PropTypes.number,
    list_order: PropTypes.number,
    material: PropTypes.shape({
      display_name: PropTypes.string,
      element_id: PropTypes.number,
      id: PropTypes.number,
    }),
  }),
  colorObject: PropTypes.object,
  materials: PropTypes.arrayOf(PropTypes.string),
  group: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

MaterialCard.defaultProps = {
  style: {},
  schemeMaterial: {},
  defaultMaterialColor: false,
  customScheme: {},
  handleSelectMaterial: () => {},
  viewElement: {},
  colorObject: {},
  materials: [],
  group: false,
  onMouseEnter: null,
  onMouseLeave: null,
}

export default MaterialCard
