import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import SchemeSelect from './components/SchemeSelect'
import MaterialCard from '../../floorCovering/MaterialCard'
import PalettePicker from './components/PalettePicker'
import { useSetBannerText, useSelection, useSnackbar } from '../../../contexts'
import { ROOM_AND_SCHEMES_BY_ID } from '../../../graphql/rooms.graphql'
import { useStable } from './hooks'
import {
  useSchemeWithOverrides,
  useSelectedScheme,
} from '../../../hooks/selectionHooks'
import useAccountByName from '../../../hooks/useAccountByName'
import '../../../types'
import InteractiveCanvas from '../../InteractiveCanvas'
import { getMaskCloudinaryPath } from '../../../utils/cloudinaryPaths'
import getRoomImage from '../../../utils/roomImage'

const useStyles = makeStyles((theme) => ({
  designPageContainer: {
    '@media screen and (orientation: portrait)': {
      display: 'flex',
      flexDirection: 'column',
    },
    '@media screen and (orientation: landscape)': {
      display: 'grid',
      gridTemplateColumns:
        'calc(260px + (600 - 260)*(100vw - 350px)/(1920 - 350)) 1fr',
      gridTemplateRows: '85fr 15fr',
      height: 'calc(100vh - 153px)',
    },
    backgroundColor: 'white',
  },
  imageContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 2,
    display: 'flex',
    minHeight: '40vh',
    padding: 0,
  },
  schemesMaterialsContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    maxHeight: '100%',
    '@media screen and (orientation: landscape)': {
      padding: 20,
    },
    '@media screen and (orientation: portrait)': {
      padding: 5,
      height: 'calc(100vh - 92px)',
    },
  },
  schemesContainer: {
    padding: 5,
    overflowY: 'auto',
    height: '60%',
  },
  materialsContainer: {
    padding: 10,
    overflowY: 'auto',
    height: '40%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },
  selectScheme: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

function useRoomPageParams() {
  const {
    accountName,
    propertyId: propertyIdRaw,
    roomId: roomIdRaw,
  } = useParams()

  const propertyId = parseInt(propertyIdRaw, 10)
  const roomId = parseInt(roomIdRaw, 10)

  if (!propertyId || !roomId)
    throw new TypeError(
      `The propertyId and roomId arguments only support integer values!`
    )

  return {
    accountName,
    propertyId,
    roomId,
  }
}

/**
 * @param {number} id
 * @returns {ApolloReturn<{ roomById: RoomWithSchemes }>}
 */
function useRoomById(id) {
  const { loading, error, data, ...rest } = useQuery(ROOM_AND_SCHEMES_BY_ID, {
    variables: {
      id,
    },
  })

  return useMemo(() => {
    if (loading || error) return { loading, error, data, ...rest }
    return {
      loading,
      error,
      data,
      ...rest,
    }
  }, [loading, error, data])
}

export default function RoomPage() {
  const classes = useStyles()

  const { addMessage } = useSnackbar()
  const { setRoomSchemeId, setRoomCustomElement, clearRoomCustomElement } =
    useSelection()

  const { propertyId, roomId, accountName } = useRoomPageParams()
  const { loading, data, error: roomError } = useRoomById(roomId)
  const {
    data: accountData,
    error: accountError,
    loading: accountLoading,
  } = useAccountByName(accountName)

  /** @type ReactState<Mask> */
  const [selectedMask, setSelectedMask] = useState(null)

  /** @type ReactState<Mask> */
  const [highlightedMask, setHighlightedMask] = useState(null)

  const error = roomError || accountError

  const history = useHistory()
  useEffect(() => {
    if (error) {
      addMessage({
        type: 'error',
        title:
          'An error occured while fetching room details, please try again later!',
        message: 'You can save your progress by clicking the "Share" button!',
      })

      history.push('../')
    }
  }, [error])

  const room = useMemo(() => {
    if (loading || error) return null
    return data.roomById
  }, [data, loading])

  useSetBannerText(room?.name)

  const schemes = useMemo(() => {
    if (loading || error) return []
    return data.roomById.schemes
  }, [data, loading])

  const selectedScheme = useSelectedScheme(schemes, propertyId, roomId)

  const actualScheme = useSchemeWithOverrides(
    selectedScheme,
    propertyId,
    roomId
  )

  const schemeMasks = useMemo(() => {
    if (!actualScheme) return []
    if (!accountData?.accountByName?.cloud_folder_name) return []

    return actualScheme.elements.map((el) => el.mask)
  }, [actualScheme, accountData])

  const roomMasks = useMemo(() => {
    if (!accountData?.accountByName?.cloud_folder_name) return []
    if (!room?.masks) return []

    return room.masks.map((mask) => ({
      ...mask,
      image: ({ width }) =>
        getMaskCloudinaryPath(
          accountData.accountByName.cloud_folder_name,
          mask.image,
          roomId,
          `w_${width},c_fill`
        ),
    }))
  })

  const originalElement = useMemo(() => {
    if (!selectedScheme || !selectedMask) return null
    return selectedScheme.elements.find(
      (element) => element.mask?.id === selectedMask.id
    )
  }, [selectedScheme, selectedMask])

  const actualElement = useMemo(() => {
    if (!actualScheme || !selectedMask) return null
    return actualScheme.elements.find(
      (element) => element.mask?.id === selectedMask.id
    )
  }, [actualScheme, selectedMask])

  const handleSelectScheme = useStable(
    /** @param {Scheme} scheme */
    (scheme) => setRoomSchemeId(propertyId, roomId, scheme.id)
  )

  const handleMaterialClick = useStable(
    /** @param {Mask} mask */
    (mask) => setSelectedMask(mask)
  )

  const handlePaletteSelect = useStable(
    /** @param {SchemeElement} element */
    (element) =>
      setRoomCustomElement(
        propertyId,
        roomId,
        selectedScheme.id,
        selectedMask.id,
        element
      )
  )

  const handlePaletteReset = useStable(() => {
    clearRoomCustomElement(
      propertyId,
      roomId,
      selectedScheme.id,
      selectedMask.id
    )
  })

  return (
    <div className={classes.designPageContainer}>
      <div className={classes.imageContainer}>
        {room && !accountLoading && (
          <InteractiveCanvas
            backdropSrc={({ width }) =>
              getRoomImage(
                accountData?.accountByName,
                room,
                actualScheme,
                width
              )
            }
            allLayers={roomMasks}
            availableLayers={schemeMasks}
            selectedLayer={highlightedMask}
            onHighlight={setHighlightedMask}
            onSelect={setSelectedMask}
          />
        )}
      </div>
      <div className={classes.schemesMaterialsContainer}>
        <SchemeSelect
          schemes={schemes}
          loading={loading}
          selectedScheme={selectedScheme}
          onSelect={handleSelectScheme}
        />
        <Paper className={classes.materialsContainer}>
          {actualScheme ? (
            actualScheme.elements.map((element) => (
              <MaterialCard
                key={`${element?.id}-${element?.color?.id}-${element?.mask?.id}-${element?.swatch?.id}`}
                element={element}
                onClick={() => handleMaterialClick(element.mask)}
                onMouseEnter={() => setHighlightedMask(element.mask)}
                onMouseLeave={() => setHighlightedMask(null)}
              />
            ))
          ) : (
            <Typography className={classes.selectScheme} variant="h6">
              Select Scheme
            </Typography>
          )}
        </Paper>
      </div>

      {!loading && selectedMask && room && (
        <PalettePicker
          open={!!selectedMask}
          room={room}
          mask={selectedMask}
          originalElement={originalElement}
          actualElement={actualElement}
          onSelect={handlePaletteSelect}
          onReset={handlePaletteReset}
          onClose={() => setSelectedMask(null)}
        />
      )}
    </div>
  )
}
