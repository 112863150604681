import { Button, Typography } from '@material-ui/core'
import React from 'react'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import { getSessionTokenFromUrl } from '../../utils/helper'

const HomePageButton = ({ history, match }) => {
  const classes = navButtonStyles()

  const handleGoToLandingPage = () => {
    const baseUrl = match.url.split('/project')[0]
    history.push(
      `${baseUrl}/project/${
        match.params.propertyId
      }/home${getSessionTokenFromUrl()}`
    )
  }

  return (
    <Button
      color="primary"
      variant="contained"
      className={classes.button}
      onClick={() => handleGoToLandingPage()}
    >
      <Typography className={classes.buttonContent}>
        <HomeRoundedIcon className={classes.buttonIcon} />
        <Typography className={classes.buttonText} variant="button">
          Home Page
        </Typography>
      </Typography>
    </Button>
  )
}
HomePageButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      propertyId: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

const WrappedComponent = compose(withRouter)(HomePageButton)

export { WrappedComponent as HomePageButton }
