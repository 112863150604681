import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import {
  SELECT_VIEW,
  SELECT_SCHEME,
  SET_SELECTED_SCHEME_NAME,
  UNSELECT_SCHEME,
  SELECT_MATERIAL,
  CUSTOMIZE_MATERIAL_COLOR,
  CUSTOMIZE_MATERIAL_NAME,
  RESET_MATERIAL,
  ADD_DESIGN_TO_CART,
  REMOVE_DESIGN_FROM_CART,
  LOAD_DESIGN_FROM_CART,
  EDIT_DESIGN_FROM_CART,
  RENAME_DESIGN_FROM_CART,
  SET_SESSION_ID,
  SET_TOKEN,
} from './designCartActionTypes'

import { getApolloClient } from '../utils/enhancers'

import { UPDATE_SESSION } from '../graphql/sessions.graphql'

const MILLISECONDS_TO_WAIT = 2000

// Generate an action creator.
// When the action creator is run, it will return action object.
const genActionCreator = (type) => (payload) => ({
  type,
  payload,
})

const mutationSideEffect = async ({ getState }) => {
  const apolloClient = getApolloClient()
  const sessionData = getState()
  const { sessionId } = sessionData
  // We NEVER want to store/persist the token
  const sessionDataCopy = omit(sessionData, 'token')
  if (sessionId) {
    await apolloClient.mutate({
      mutation: UPDATE_SESSION,
      variables: {
        input: {
          id: sessionId,
          session_data: JSON.stringify(sessionDataCopy),
        },
      },
    })
  } else {
    console.log('Session has not yet been created, not updating the database.')
  }
}

const debouncedMutationSideEffect = debounce(
  mutationSideEffect,
  MILLISECONDS_TO_WAIT
)

// Generate an action creator.
// When this action creator is run, it can dispatch other actions or perform side effects.
// In this one, the side effect is a GraphQL mutation.
const genActionCreatorWithMutation =
  (type) =>
  (payload) =>
  async ({ dispatch, getState, getHash }) => {
    // Get a hash of the store's original state.
    const previousHash = getHash()

    // Dispatch the original action, and wait for the store's state to transition.
    const actionResult = await dispatch(genActionCreator(type)(payload))

    // Get a hash of the store's updated state.
    const nextHash = getHash()

    if (previousHash === nextHash) {
      console.log('Store did not change, not updating database.')
    } else {
      // If the store's state changed, then run a side effect to update the database.
      // If there is a burst of updates, debounce and run the mutation only for the last update.
      debouncedMutationSideEffect({ previousHash, nextHash, getState })
    }

    // Return the original action's result.
    return actionResult
  }

// Define action creators.
const selectView = ({ viewId }) =>
  genActionCreatorWithMutation(SELECT_VIEW)({ viewId })
const selectScheme = ({ schemeId }) =>
  genActionCreatorWithMutation(SELECT_SCHEME)({ schemeId })
const unselectScheme = () => genActionCreatorWithMutation(UNSELECT_SCHEME)()
// eslint-disable-next-line max-len
const setSelectedSchemeName = ({ schemeName }) =>
  genActionCreatorWithMutation(SET_SELECTED_SCHEME_NAME)({ schemeName })

const selectMaterial = ({ materialId }) =>
  genActionCreatorWithMutation(SELECT_MATERIAL)({ materialId })

const customizeMaterialColor = ({
  colorId,
  paletteId,
  elementId,
  color,
  colorName,
  colorIdentifier,
  vendorPrefix,
}) =>
  genActionCreatorWithMutation(CUSTOMIZE_MATERIAL_COLOR)({
    colorId,
    paletteId,
    elementId,
    color,
    colorName,
    colorIdentifier,
    vendorPrefix,
  })

const customizeMaterialName = ({ materialName }) =>
  genActionCreatorWithMutation(CUSTOMIZE_MATERIAL_NAME)({ materialName })

const resetMaterial = () => genActionCreatorWithMutation(RESET_MATERIAL)()
const addDesignToCart = () => genActionCreatorWithMutation(ADD_DESIGN_TO_CART)()

const removeDesignFromCart = ({ index }) =>
  genActionCreatorWithMutation(REMOVE_DESIGN_FROM_CART)({ index })

const loadDesignFromCart = ({ index }) =>
  genActionCreatorWithMutation(LOAD_DESIGN_FROM_CART)({ index })

const editDesignFromCart = ({ index }) =>
  genActionCreatorWithMutation(EDIT_DESIGN_FROM_CART)({ index })

const renameDesignFromCart = ({ index, newName }) =>
  genActionCreatorWithMutation(RENAME_DESIGN_FROM_CART)({ index, newName })

const setSessionId = ({ sessionId }) =>
  genActionCreatorWithMutation(SET_SESSION_ID)({ sessionId })

const setToken = ({ token }) =>
  genActionCreatorWithMutation(SET_TOKEN)({ token })

export {
  selectView,
  selectScheme,
  unselectScheme,
  selectMaterial,
  customizeMaterialColor,
  customizeMaterialName,
  resetMaterial,
  addDesignToCart,
  removeDesignFromCart,
  loadDesignFromCart,
  editDesignFromCart,
  renameDesignFromCart,
  setSessionId,
  setSelectedSchemeName,
  setToken,
}
