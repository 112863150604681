import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { US_STATES } from '@anewgo/constants'
import { validateEmail, validateSherwinOrAnewgoEmail } from '../utils/helper'
import Input from './Input'
import StateInput from './StateInput'

const styles = makeStyles({
  contactInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const SendToRepForm = ({ openDialog, setOpenDialog, handleSubmit }) => {
  const classes = styles()
  const [repEmail, setRepEmail] = useState('')
  const [fromEmail, setFromEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyCity, setCompanyCity] = useState('')
  const [companyState, setCompanyState] = useState('')
  const [companyZip, setZip] = useState('')
  const [companyAddress, setAddress] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    if (!validateSherwinOrAnewgoEmail(repEmail) || !validateEmail(fromEmail)) {
      setInvalidEmail(true)
      return
    }

    handleSubmit(
      repEmail.toLowerCase(),
      fromEmail.toLowerCase(),
      contactName,
      contactPhone,
      companyName,
      companyAddress,
      companyCity,
      companyState,
      companyZip
    )
  }

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <form style={{ width: 500 }} onSubmit={onSubmit}>
        <DialogTitle>Send to Rep</DialogTitle>
        <DialogContent style={{ display: 'flex', flexFlow: 'column' }}>
          <Input
            placeholder="Rep email @sherwin.com"
            setInput={setRepEmail}
            sherwinEmail
            required
          />
          <Input
            placeholder="From email"
            setInput={setFromEmail}
            emailInput
            required
          />
          {invalidEmail && (
            <ClickAwayListener onClickAway={() => setInvalidEmail(false)}>
              <Typography
                variant="body2"
                color="secondary"
                style={{ marginLeft: 10 }}
              >
                Invalid email.
              </Typography>
            </ClickAwayListener>
          )}
          <div className={classes.contactInfoContainer}>
            <Input
              placeholder="Contact Name"
              setInput={setContactName}
              style={{ width: '48%' }}
              required
            />
            <Input
              placeholder="Contact Phone"
              setInput={setContactPhone}
              style={{ width: '48%' }}
              required
            />
          </div>
          <div>
            <Input
              placeholder="Community Name"
              setInput={setCompanyName}
              style={{ width: '100%' }}
              required
            />
            <Input
              placeholder="Address"
              setInput={setAddress}
              style={{ width: '100%' }}
              required
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input
                placeholder="City"
                setInput={setCompanyCity}
                style={{ width: '43%' }}
                required
              />
              <StateInput
                onChange={(_event, value) =>
                  value ? setCompanyState(value.abbreviation) : null
                }
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                  style: { maxHeight: '10rem' },
                  position: 'bottom-start',
                }}
                options={US_STATES}
                style={{ width: '33%' }}
                renderOption={(option) => option.name}
                placeholder="State"
                required
              />
              <Input
                placeholder="ZIP"
                setInput={setZip}
                style={{ width: '18%' }}
                required
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="Submit">
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

SendToRepForm.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default SendToRepForm
