import { useState, useEffect } from 'react'

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export const useFirstTimeVisit = () => {
  const [alreadyVisited, setAlreadyVisited] = useState(null)

  useEffect(() => {
    setAlreadyVisited(localStorage.getItem('alreadyVisited'))
    if (!alreadyVisited) {
      localStorage.setItem('alreadyVisited', 'yes')
    }
  }, [])

  return alreadyVisited
}
