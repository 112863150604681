import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import PropTypes from 'prop-types'

const styles = makeStyles((theme) => ({
  textField: {
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '2px 0',
    },
  },
  input: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const StateInput = ({
  options,
  placeholder,
  style,
  required,
  ListboxProps,
  getOptionLabel,
  onChange,
  renderOption,
  disableClearable,
}) => {
  const classes = styles()
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      ListboxProps={{ ...ListboxProps }}
      style={style}
      onChange={onChange}
      disableClearable={disableClearable}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          size="small"
          variant="outlined"
          label={placeholder}
          required={required}
        />
      )}
    />
  )
}

StateInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.object || PropTypes.number || PropTypes.string
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  ListboxProps: PropTypes.object,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  renderOption: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
}

StateInput.defaultProps = {
  placeholder: '',
  required: false,
  style: {},
  ListboxProps: {},
  onChange: (option) => option,
  disableClearable: false,
}

export default StateInput
