import { Badge, Button, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withConsumer } from '../../utils'
import { StoreConsumer } from '../../contexts'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import { getSessionTokenFromUrl } from '../../utils/helper'

const StyledBadge = withStyles(() => ({
  badge: {
    right: -5,
    top: 8,
    padding: '0 4px',
  },
}))(Badge)

const CartButton = ({ store, match, history }) => {
  const classes = navButtonStyles()
  const { params } = match
  const { cart } = store.state
  const numberOfItemsInCart = cart ? cart.length : 0

  const handleViewCart = () => {
    const baseUrl = match.url.split('/project')[0]
    history.push(
      `${baseUrl}/project/${params.propertyId}/cart${getSessionTokenFromUrl()}`
    )
  }

  return (
    <Button
      className={classes.button}
      disabled={numberOfItemsInCart === 0 && true}
      color="primary"
      variant="contained"
      onClick={() => handleViewCart()}
    >
      <Typography className={classes.buttonContent}>
        <StyledBadge badgeContent={numberOfItemsInCart} color="secondary">
          <ShoppingCartIcon className={classes.buttonIcon} />
        </StyledBadge>
        <Typography className={classes.buttonText} variant="button">
          View Cart
        </Typography>
      </Typography>
    </Button>
  )
}

CartButton.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      propertyId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(StoreConsumer, { propName: 'store' })
)(CartButton)

export { WrappedComponent as CartButton }
