/* eslint-disable react/no-this-in-sfc,camelcase */
import URLSearchParams from '@ungap/url-search-params'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import { onError } from '@apollo/client/link/error'
import jwt from 'jsonwebtoken'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import { branch, renderNothing } from 'recompose'
import inspect from 'util-inspect'

import PropTypes from 'prop-types'
import { TYPE } from '../graphql/introspection.graphql'

import { SESSION } from '../graphql/sessions.graphql'

import { SelectionProvider, StoreProvider } from '../contexts'
import { designCartReducer } from '../reducers/designCartReducer'

import { getLogger } from './logger'

import typePolicies from './typePolicies'

const log = getLogger('enhancers.jsx')

const renderNothingIf = (test) => branch(test, renderNothing)

let apolloClientSingleton = null

const getApolloClient = () => {
  if (apolloClientSingleton === null) {
    // Initialize if necessary.
    apolloClientSingleton = new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error(
                `[GraphQL error]: Message: '${message}', Location: ${inspect(
                  locations,
                  { depth: null, maxArrayLength: null }
                )}, Path: '${path}'`
              )
            })
          }
          if (networkError) console.error(`[Network error]: ${networkError}`)
        }),
        new HttpLink({
          uri: `${process.env.API_SERVER}/api/graphql_internal`,
          credentials: 'same-origin',
        }),
      ]),
      cache: new InMemoryCache({ typePolicies }),
    })
  }

  return apolloClientSingleton
} // getApolloClient()

const withApolloProvider = (OriginalComponent) => {
  const apolloClient = getApolloClient()

  const HOC = (props) => (
    <ApolloProvider client={apolloClient}>
      <OriginalComponent {...props} />
    </ApolloProvider>
  )
  return HOC
}

// Extract a token from a Route location.
// Extract state from the token JSON field.
// Initialize a SelectionProvider using the state.
const withSessionProvider = (OriginalComponent) => {
  const HOC = (props) => {
    const { location } = props
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    let session_id = null
    let initialSessionState = null

    if (token) {
      let payload
      try {
        payload = jwt.decode(token)
      } catch (error) {
        log.debug(
          'withSessionProvider(): Encountered an error while decoding JWT:',
          error
        )
      }
      log.debug('withSessionProvider(): token payload:', payload)
      if (payload && payload.session_id) {
        console.log(
          'withSessionProvider(): payload.session_id is:',
          payload.session_id
        )
        // eslint-disable-next-line prefer-destructuring
        session_id = payload.session_id
      }
    }

    log.debug('withSessionProvider(): session_id:', session_id)
    return session_id ? (
      <Query query={SESSION} variables={{ session_id }}>
        {({ loading, error, data }) => {
          if (loading) {
            log.debug('withSessionProvider(): session query is loading.')
            return null
          }
          // Return null here; the actual error message will be rendered in a
          // modal by Apollo Link.
          if (error) {
            log.debug(
              'withSessionProvider(): session query encountered an error:',
              error
            )
            return null
          }

          // Look up the resultPropName, and optionally transform it.
          const { session } = data

          if (session && session.session_data) {
            initialSessionState = JSON.parse(session.session_data)
            initialSessionState = {
              ...initialSessionState,
              sessionId: session_id,
            }
          }
          log.debug(
            'withSessionProvider: initialSessionState:',
            initialSessionState
          )

          return (
            <StoreProvider
              initialState={initialSessionState}
              reducer={designCartReducer}
            >
              <SelectionProvider initialSelectionState={initialSessionState}>
                <OriginalComponent {...props} />
              </SelectionProvider>
            </StoreProvider>
          )
        }}
      </Query>
    ) : (
      // If we don't have a session_id, don't query.
      <StoreProvider
        initialState={cloneDeep(initialSessionState)}
        reducer={designCartReducer}
      >
        <SelectionProvider
          initialSelectionState={cloneDeep(initialSessionState)}
        >
          <OriginalComponent {...props} />
        </SelectionProvider>
      </StoreProvider>
    )
  }

  HOC.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }

  HOC.propTypes = {
    location: PropTypes.object.isRequired,
  }

  return HOC
}

// HOC class for wrapping with a plain component (such as a Context Provider)
// that doesn't need any props.
const withComponent = (Component) => (OriginalComponent) => {
  const HOC = (props) => (
    <Component>
      <OriginalComponent {...props} />
    </Component>
  )
  return HOC
}

// The name of the injected prop is an option.
const withConsumer =
  (Consumer, { propName = 'contextValue' } = {}) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Consumer>
        {
          // Inject the context value as a new prop in the original component.
          (value) => <OriginalComponent {...{ [propName]: value }} {...props} />
        }
      </Consumer>
    )
    return HOC
  }

const withMutationAsProp =
  ({ gqlDocument, mutationPropName, refetchQueries }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Mutation
        mutation={gqlDocument}
        refetchQueries={
          refetchQueries && refetchQueries.length
            ? refetchQueries.map(
                ({ gqlDocument: refetchGqlDocument, variables }) => ({
                  query: refetchGqlDocument,
                  // The variables option object is an optional object or a function
                  // that returns an object. Apollo client will send an  empty object
                  // by default.
                  variables:
                    typeof variables === 'function'
                      ? variables(props)
                      : variables,
                })
              )
            : undefined
        }
      >
        {(mutationFunction /* , { loading, error, called, data } */) => {
          const simplifiedMutationFunction = (variables) =>
            mutationFunction({
              variables,
              // optimisticResponse,
              // refetchQueries,
              // update,
            })
          // Always render the component, don't for example render null while
          // the mutation is in progress.
          return (
            <OriginalComponent
              {...{ [mutationPropName]: simplifiedMutationFunction }}
              {...props}
            />
          )
        }}
      </Mutation>
    )
    return HOC
  }

// Don't render the inner component if the query is in progress or fails.
// This has the advantage of allowing the inner component to require the
// data the query provides.
const withQueryResultAsProp =
  ({
    gqlDocument,
    variables,
    resultPropName,
    transformFunc,
    // The name of the injected prop is resultPropName by default, unless we
    // provide propName.
    propName,
    // The component to display while loading, null otherwise
    LoadingComponent,
  }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Query
        query={gqlDocument}
        variables={
          typeof variables === 'function' ? variables(props) : variables
        }
      >
        {({ loading, error, data }) => {
          if (loading) {
            log.debug('withQueryResultAsProp:', resultPropName, 'is loading.')
            return LoadingComponent ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingComponent />
              </div>
            ) : null
          }
          // Return null here; the actual error message will be rendered in a
          // modal by Apollo Link.
          if (error) {
            log.debug(
              'withQueryResultAsProp:',
              resultPropName,
              'encountered an error:',
              error
            )
            return null
          }
          // Look up the resultPropName, and optionally transform it.
          const propValue = transformFunc
            ? transformFunc(data[resultPropName], data)
            : data[resultPropName]
          return (
            <OriginalComponent
              {...{ [propName || resultPropName]: propValue }}
              {...props}
            />
          )
        }}
      </Query>
    )
    return HOC
  }

const withEnumValuesAsProp =
  ({
    typeName,
    // The name of the injected prop is enumValues by default, unless we
    // provide propName.
    propName,
  }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Query
        query={TYPE}
        variables={{
          typeName,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return null
          // Return null here; the actual error message will be rendered in a
          // modal by Apollo Link.
          if (error) return null
          return (
            <OriginalComponent
              {...{
                [propName || 'enumValues']: data.__type.enumValues.map(
                  (enumValue) => enumValue.name
                ),
              }}
              {...props}
            />
          )
        }}
      </Query>
    )
    return HOC
  }

export {
  getApolloClient,
  renderNothingIf,
  withApolloProvider,
  withComponent,
  withConsumer,
  withEnumValuesAsProp,
  withMutationAsProp,
  withQueryResultAsProp,
  withSessionProvider,
}
