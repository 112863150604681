/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ApolloConsumer } from '@apollo/client/react/context'

import { TOKEN } from '../graphql/authentication.graphql'

const AuthenticationContext = React.createContext()

const AuthenticationConsumer = AuthenticationContext.Consumer

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.
class AuthenticationProvider extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  state = {
    token: null,
  }

  // Connect to the server and send credentials.
  // If they are accepted, update the token and resolve to true.
  // Otherwise resolve to false.
  signIn = (apolloClient) => async (email, password) => {
    const { data } = await apolloClient.query({
      query: TOKEN,
      variables: { email, password },
    })

    const { token } = data

    this.setState({
      // This will be null if the server rejects the authentication attempt.
      token,
    })
    return Promise.resolve(true)
  }

  signOut = () =>
    this.setState({
      token: null,
    })

  render() {
    return (
      <ApolloConsumer>
        {(apolloClient) => (
          <AuthenticationContext.Provider
            value={{
              token: this.state.token,
              signIn: this.signIn(apolloClient),
              signOut: this.signOut,
            }}
          >
            {this.props.children}
          </AuthenticationContext.Provider>
        )}
      </ApolloConsumer>
    )
  }
}

export { AuthenticationProvider, AuthenticationConsumer }
