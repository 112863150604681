// This component is used by the shopping cart version.
import {
  Badge,
  Button,
  ClickAwayListener,
  Fade,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useState } from 'react'
import LinkIcon from '@material-ui/icons/Link'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import {
  getSessionIdAndToken,
  withConsumer,
  withMutationAsProp,
} from '../../utils'
import { BannerConsumer, StoreConsumer } from '../../contexts'

import { ADD_SESSION } from '../../graphql/sessions.graphql'
import { setSessionId } from '../../reducers/designCartActionCreators'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import Snackbar from '../Snackbar'

const styles = makeStyles({
  popover: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 10,
  },
  copyButton: {
    marginLeft: 10,
  },
})

const StyledBadge = withStyles(() => ({
  badge: {
    right: -5,
    top: 8,
    padding: '0 4px',
  },
}))(Badge)

const ShareButton = ({ store, location, addSession, banner }) => {
  const classes = navButtonStyles()
  const classesShare = styles()
  const { share } = store.state
  const numberOfItemsInShare = share ? share.length : 0
  const [url, setURL] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [showPopper, setShowPopper] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const openPopover = (target) => {
    setAnchorEl(target)
    setShowPopper(true)
  }
  const closePopover = () => setShowPopper(false)

  return (
    <ClickAwayListener onClickAway={() => closePopover()}>
      <div style={{ float: 'right' }}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={async (event) => {
            banner.setIsLinkCopiedOrDismissed(true)
            const target = event.currentTarget

            if (showPopper) {
              closePopover()
              return
            }

            const sessionState = store.getState()
            if (!sessionState.sessionId) {
              // If the session didn't exist, create a new session, and a new URL.
              const { sessionId, token: sessionToken } =
                await getSessionIdAndToken(sessionState, addSession)

              // For some reason, React Router converts the URL encoding for %20
              // back into a space. When constructing a URL from the React Router
              // Change all spaces back to a "%20".
              const newShareURL = `${window.location.protocol}//${
                window.location.host
              }${location.pathname.replace(/ /g, '%20')}?token=${sessionToken}`
              setURL(newShareURL)
              await store.dispatch(setSessionId({ sessionId }))
              window.history.pushState(null, '', `?token=${sessionToken}`)
            } else {
              setURL(window.location.href)
            }
            // Else, if the session already exists, re-use the existing sharing URL.

            // Show the pop over once we've determined our URL.
            // Use this button as the anchorElement.
            openPopover(target)
          }}
        >
          <Typography className={classes.buttonContent}>
            <StyledBadge badgeContent={numberOfItemsInShare} color="secondary">
              <LinkIcon className={classes.buttonIcon} />
            </StyledBadge>
            <Typography className={classes.buttonText} variant="button">
              Create Save Link
            </Typography>
          </Typography>
        </Button>
        <Popper
          anchorEl={anchorEl}
          placement="bottom"
          open={showPopper}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper className={classesShare.popover}>
                <TextField
                  id="shareableURLField"
                  variant="outlined"
                  size="small"
                  defaultValue={url}
                />
                <CopyToClipboard
                  text={url}
                  onCopy={(_, result) => {
                    if (result) {
                      setOpenSnackbar(true)
                    } else {
                      console.error('Could not copy to the clipboard!')
                    }
                    closePopover()
                  }}
                >
                  <Button
                    className={classesShare.copyButton}
                    variant="outlined"
                    color="primary"
                    size="medium"
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Snackbar
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message="Share link copied."
          severity="success"
        />
      </div>
    </ClickAwayListener>
  )
}

ShareButton.propTypes = {
  addSession: PropTypes.func.isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    state: PropTypes.shape({
      share: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
  location: PropTypes.object.isRequired,
  banner: PropTypes.shape({
    isLinkCopiedOrDismissed: PropTypes.bool,
    setIsLinkCopiedOrDismissed: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(StoreConsumer, { propName: 'store' }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withConsumer(BannerConsumer, { propName: 'banner' })
)(ShareButton)

export { WrappedComponent as ShareButton }
