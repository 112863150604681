import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { withConsumer, withQueryResultAsProp } from '../../../utils'
import { PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS } from '../../../graphql/properties.graphql'
import DigitalHomescapesPage from './components/DigitalHomescapesPage'
import DefaultPage from './components/DefaultPage'
import RoomPage from './components/RoomPage'
import { SelectionConsumer } from '../../../contexts'
import { BASE_IMAGE_URL } from '../../constants/Paths'

function BrochurePage({ property, selection, isPortrait, location }) {
  const sortedBuildings = useMemo(
    () =>
      property?.buildings.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.buildings]
  )

  const sortedRooms = useMemo(
    () => property?.rooms.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.rooms]
  )

  const renderAllSchemes = location.search.includes('allSchemes=true')

  if (renderAllSchemes) {
    let index = 0
    return (
      <>
        {property.schemes.map((scheme) => {
          return sortedBuildings.map((building) => {
            const propertyId = property.id
            const buildingId = building.id
            const schemeId = scheme.id

            const customCelection = {
              propertyBuildingScheme: {
                [propertyId]: {
                  [buildingId]: {
                    buildingId,
                    currentSchemeId: schemeId,
                  },
                  propertyId,
                },
              },
            }

            const key = `${propertyId}-${buildingId}-${schemeId}`
            return building?.views[0]?.brochure_layout?.name ===
              'digital_homescapes' ? (
              <DigitalHomescapesPage
                key={key}
                property={property}
                selection={customCelection}
                building={building}
              />
            ) : (
              <DefaultPage
                key={key}
                property={property}
                index={index++}
                selection={customCelection}
                building={building}
                isPortrait={isPortrait}
              />
            )
          })
        })}

        {sortedRooms.map((room) => {
          return room.schemes.map((scheme) => {
            const propertyId = property.id
            const roomId = room.id
            const currentSchemeId = scheme.id

            const customSelection = {
              propertyRoomScheme: {
                [propertyId]: {
                  [roomId]: {
                    roomId,
                    currentSchemeId,
                  },
                  propertyId,
                },
              },
            }

            const key = `${propertyId}-${roomId}-${currentSchemeId}`
            return (
              <RoomPage
                key={key}
                property={property}
                selection={customSelection}
                room={room}
                portrait={isPortrait}
              />
            )
          })
        })}
      </>
    )
  }

  return (
    <>
      {sortedBuildings.map((building, index) =>
        building?.views[0]?.brochure_layout?.name === 'digital_homescapes' ? (
          <DigitalHomescapesPage
            key={building.name}
            property={property}
            selection={selection}
            building={building}
          />
        ) : (
          <DefaultPage
            key={building.name}
            property={property}
            index={index}
            selection={selection}
            building={building}
            isPortrait={isPortrait}
          />
        )
      )}
      {sortedRooms.map((room) => (
        <RoomPage
          key={room.id}
          property={property}
          selection={selection}
          room={room}
          portrait={isPortrait}
        />
      ))}
    </>
  )
}

BrochurePage.propTypes = {
  property: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  isPortrait: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    transformFunc: (property, data) => ({
      ...property,
      rooms: data.roomsByProjectId,
    }),
    resultPropName: 'property',
  }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withState('isPortrait', 'setIsPortrait', false),
  withHandlers({
    handleIfPortrait: (props) => () => {
      const { property } = props
      const { buildings } = property
      if (props.setIsPortait !== true) {
        buildings.forEach((building) => {
          const img = new Image()
          img.src = `${BASE_IMAGE_URL}/q_auto/app/${property.account.cloud_folder_name}/images/${building.views[0].base_image_src}`
          img.onload = () => {
            if (
              building?.views[0]?.brochure_layout?.name === 'digital_homescapes'
            ) {
              props.setIsPortrait(true)
            }
            if (img.naturalHeight >= img.naturalWidth) {
              props.setIsPortrait(true)
            }
          }
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleIfPortrait, property } = this.props
      window.addEventListener('load', handleIfPortrait(property))
    },
    componentWillUnmount() {
      const { handleIfPortrait } = this.props
      window.removeEventListener('load', handleIfPortrait)
    },
  })
)(BrochurePage)

export default WrappedComponent
