import React, { useState, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer } from 'react-virtualized'
import { identity } from 'lodash'
import { useDebounce } from '../utils/hooks'
import '../types'

const requestIdle =
  typeof window.requestIdleCallback === 'function'
    ? window.requestIdleCallback
    : window.requestAnimationFrame

/**
 * @param {HTMLImageElement} image
 * @returns {Promise<[HTMLCanvasElement, ImageData]>}
 */
function createOutline(image) {
  return new Promise((resolve) => {
    requestIdle(() => {
      // Setup offscreen canvas and draw outline
      const offscreenCanvas = document.createElement('canvas')

      // Setup canvas resolution
      offscreenCanvas.width = image.width
      offscreenCanvas.height = image.height

      const offscreenContext = offscreenCanvas.getContext('2d')

      // Draw once to get image data
      offscreenContext.drawImage(image, 0, 0)
      const data = offscreenContext.getImageData(
        0,
        0,
        image.width,
        image.height
      )

      const smallerSize = Math.min(image.width, image.height)
      const blurRadius = (smallerSize / 900) * 4

      // Draw layer several times to get the blur
      offscreenContext.shadowBlur = blurRadius
      offscreenContext.shadowColor = '#FF0000'
      for (let i = 0; i < 7; i++) {
        offscreenContext.drawImage(image, 0, 0)
      }

      // Turn off blur and subtract the image from canvas
      // leaving only the blur behind
      offscreenContext.shadowBlur = 0
      offscreenContext.globalCompositeOperation = 'destination-out'
      offscreenContext.drawImage(image, 0, 0)

      console.log(`Resolved outline for layer with source ${image.src}!`)
      resolve([offscreenCanvas, data])
    })
  })
}

/**
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.crossOrigin = 'Anonymous'
    img.src = src
  })
}

/**
 * @typedef ImageMapValue
 * @prop {HTMLCanvasElement} outline
 * @prop {ImageData} buffer
 * @prop {HTMLImageElement} image
 * @prop {string} src
 */

/**
 * @typedef {{ [id: number]: ImageMapValue }} ImageMap
 */

/**
 * @typedef InteractiveLayer
 * @prop {number} id
 * @prop {string} image
 * @prop {number} renderIndex
 */

/**
 * @typedef InteractiveCanvasProps
 * @prop {string} backdropSrc
 * @prop {InteractiveLayer[]} allLayers
 * @prop {InteractiveLayer[]} availableLayers
 * @prop {InteractiveLayer=} selectedLayer
 * @prop {(layer: InteractiveLayer) => void} onHighlight
 * @prop {(layer: InteractiveLayer) => void} onSelect
 */

/**
 *
 * @param {InteractiveCanvasProps} props
 */
export default function InteractiveCanvas({
  backdropSrc,
  allLayers,
  availableLayers,
  selectedLayer,
  onHighlight,
  onSelect,
}) {
  /** @type {ReactState<HTMLCanvasElement>} */
  const [canvas, setCanvas] = useState(null)

  /** @type {ReactState<CanvasRenderingContext2D>} */
  const [context, setContext] = useState(null)

  /** @type {ReactState<{ width: number, height: number }>} */
  const [size, setSize] = useState({ width: 800, height: 600 })

  /** @type {ReactState<ImageMap>} */
  const [imageMap, setImageMap] = useState({})

  /** @type {ReactState<HTMLImageElement>} */
  const [backdropImage, setBackdropImage] = useState(null)

  const debouncedSize = useDebounce(size, 300)
  const _backdropSrc =
    typeof backdropSrc === 'function' ? backdropSrc(debouncedSize) : backdropSrc

  const render = () => {
    if (!canvas || !context) return

    if (!backdropImage) return

    // Scale down all images to fit
    const ratio = Math.min(
      size.width / backdropImage.width,
      size.height / backdropImage.height
    )

    const imageWidth = ratio * backdropImage.width
    const imageHeight = ratio * backdropImage.height

    // Find drawing point for all images
    const dpX = size.width / 2 - imageWidth / 2
    const dpY = size.height / 2 - imageHeight / 2

    context.clearRect(0, 0, size.width, size.height)
    context.drawImage(backdropImage, dpX, dpY, imageWidth, imageHeight)

    const highlightedLayer =
      selectedLayer &&
      availableLayers.find((layer) => layer.id === selectedLayer.id)
    if (!highlightedLayer) return

    const layerOutline = imageMap[highlightedLayer?.id]?.outline
    if (!layerOutline) return

    context.drawImage(layerOutline, dpX, dpY, imageWidth, imageHeight)
  }

  useEffect(async () => {
    const image = await loadImage(_backdropSrc)
    setBackdropImage(image)
  }, [_backdropSrc])

  useEffect(() => {
    const newLayers = allLayers
      .map((layer) => ({
        ...layer,
        image:
          typeof layer.image === 'function'
            ? layer.image(debouncedSize)
            : layer.image,
      }))
      .filter((layer) => imageMap[layer.id]?.src !== layer.image)

    // Prevent re-adding of layers by updating the imageMap object
    setImageMap((map) => ({
      ...map,
      ...Object.fromEntries(
        newLayers.map((layer) => [layer.id, { src: layer.image }])
      ),
    }))

    newLayers.forEach(async (layer) => {
      const image = await loadImage(layer.image)
      const [outline, buffer] = await createOutline(image)
      setImageMap((map) => ({
        ...map,
        [layer.id]: {
          outline,
          buffer,
          image,
          src: layer.image,
        },
      }))
    })
  }, [allLayers, debouncedSize])

  const handleResize = (sz) => {
    setSize(sz)
  }

  const handleCanvasRef = (cvs) => {
    setCanvas(cvs)
    setContext(cvs?.getContext('2d'))
  }

  useLayoutEffect(() => {
    render()
  }, [size, context, imageMap, backdropImage, selectedLayer])

  const handleMouseMove = (ev) => {
    if (!backdropImage) return

    // Scale down all images to fit
    const ratio = Math.min(
      size.width / backdropImage.width,
      size.height / backdropImage.height
    )

    const imageWidth = ratio * backdropImage.width
    const imageHeight = ratio * backdropImage.height

    // Find drawing point for all images
    const dpX = size.width / 2 - imageWidth / 2
    const dpY = size.height / 2 - imageHeight / 2

    // Convert mouse coordinates to 0-1 coords
    const mX = (ev.nativeEvent.offsetX - dpX) / imageWidth
    const mY = (ev.nativeEvent.offsetY - dpY) / imageHeight

    if (mX >= 0 && mX <= 1 && mY >= 0 && mY <= 1) {
      const sortedLayers = availableLayers
        .slice()
        .sort((a, b) => b.renderIndex - a.renderIndex)

      for (const layer of sortedLayers) {
        const { buffer: layerData, image: layerImage } =
          imageMap[layer.id] ?? {}

        // eslint-disable-next-line no-continue
        if (!layerData || !layerImage) continue

        // Scale 0-1 coords back to image coords
        const rX = Math.floor(mX * layerImage.width)
        const rY = Math.floor(mY * layerImage.height)

        const pixelIndex = Math.floor(rY * layerImage.width + rX)
        const alpha = layerData.data[Math.floor(pixelIndex * 4 + 3)]

        // Did we hit something?
        // eslint-disable-next-line no-continue
        if (alpha < 100) continue

        canvas.style.cursor = 'pointer'
        onHighlight(layer)
        return
      }
    }

    canvas.style.cursor = 'unset'
    onHighlight(null)
  }

  const handleMouseOut = () => {
    onHighlight(null)
  }

  const handleMouseClick = () => {
    onSelect(selectedLayer)
  }

  return (
    <AutoSizer onResize={handleResize}>
      {({ width, height }) => (
        <canvas
          ref={handleCanvasRef}
          width={width}
          height={height}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          onBlur={identity}
          onClick={handleMouseClick}
        />
      )}
    </AutoSizer>
  )
}

const Layer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.oneOf([PropTypes.string, PropTypes.func]).isRequired,
  renderIndex: PropTypes.number.isRequired,
})

InteractiveCanvas.propTypes = {
  backdropSrc: PropTypes.oneOf(PropTypes.string, PropTypes.func).isRequired,
  allLayers: PropTypes.arrayOf(Layer).isRequired,
  availableLayers: PropTypes.arrayOf(Layer),
  selectedLayer: Layer,
  onHighlight: PropTypes.func,
  onSelect: PropTypes.func,
}

InteractiveCanvas.defaultProps = {
  availableLayers: [],
  selectedLayer: null,
  onHighlight: null,
  onSelect: null,
}
