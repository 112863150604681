import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  addToCartDialog: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}))

export default function NameSchemeDialog({
  open,
  cart,
  initialName,
  onSubmit,
  onClose,
}) {
  const classes = useStyles()

  const [schemeName, setSchemeName] = useState(initialName ?? '')
  const [errorMessage, setErrorMessage] = useState(false)

  const schemeNames = cart
    .filter((item) => item.schemeName)
    .map((item) => item.schemeName.toLowerCase())

  const validateSchemeName = (name) => {
    if (name && name !== '' && !schemeNames.includes(name.toLowerCase())) {
      setErrorMessage(false)
    } else {
      setErrorMessage(
        name === '' ? 'Cannot leave scheme name empty' : 'Name already in use'
      )
    }
  }

  const handleNameChange = (event) => {
    const name = event.target.value

    setSchemeName(name)
    validateSchemeName(name)
  }

  const handleSubmit = () => {
    if (!errorMessage && onSubmit) {
      onSubmit(schemeName)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.addToCartDialog}>
        <DialogTitle>Add to cart</DialogTitle>
        <DialogContent style={{ display: 'flex', flexFlow: 'column' }}>
          <DialogContentText>
            Choose a scheme name. This page is static and will wipe itself if
            refreshed or accessed later. Please save your changes by sharing the
            link, downloading the PDF or sending them to a representative.
          </DialogContentText>
          <Input
            placeholder="Scheme Name"
            onChange={handleNameChange}
            required
            value={schemeName}
          />
          {errorMessage && (
            <Typography style={{ fontSize: '0.75rem', color: 'red' }}>
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

NameSchemeDialog.propTypes = {
  open: PropTypes.bool,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      schemeId: PropTypes.number,
      viewId: PropTypes.number,
      customSchemes: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          materials: PropTypes.arrayOf(
            PropTypes.shape({
              colorId: PropTypes.number,
              colorPaletteId: PropTypes.number,
              elementId: PropTypes.number,
              materialId: PropTypes.number,
            })
          ),
        })
      ),
    })
  ),
  initialName: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

NameSchemeDialog.defaultProps = {
  open: false,
  cart: [],
  initialName: '',
  onSubmit: () => {},
  onClose: () => {},
}
