import curry from 'lodash/fp/curry'
import flow from 'lodash/fp/flow'
import cloneDeep from 'lodash/fp/cloneDeep'

// /////////////// //
// Building blocks //
// /////////////// //

// Returns itself
export const identity = (x) => x

// Takes a key-value pair and makes it an object.
export const wrap = curry((key, value) => ({ [key]: value }))

// apply -- run a function if the source object is non-null
export const apply = curry((fn, obj) => obj && fn(obj))

// getProp -- curried function to safely check for a property on an object
export const getProp = curry((key, obj) => obj && obj[key])

// assoc -- immutably associate a key-value pair to an object. Lodash's
// equivalent, assign, mutates the source object.
export const assoc = curry((key, value, obj) => ({ ...obj, [key]: value }))

// assocAll -- immutably associate a bunch of key-value pairs to an object.
// Uses Object.assign, which inherently mutates the object.
export const assocAll = curry((pairs, obj) => Object.assign({ ...obj }, pairs))

// computeProp -- immutably assign a property to an object using a function of
// that object.
//
// Example:
// const data = { a: 1, b: 2, c: 3 }
// computeProp('z', getProp('a'), data) ---> { a: 1, b: 2, c: 3, z: 1 }
export const computeProp = curry((key, fn, obj) => assoc(key, fn(obj), obj))

// ///////////// //
// Array methods //
// ///////////// //

export const map = curry((fn, arr) => arr && arr.map(fn))
export const find = curry((fn, arr) => arr && arr.find(fn))
export const some = curry((fn, arr) => arr && arr.some(fn))
export const filter = curry((fn, arr) => arr && arr.filter(fn))
export const sort = curry((fn, arr) => arr && arr.slice().sort(fn))
export const includedBy = curry(
  (array, value) => array && array.includes(value)
)
export const includes = curry((value, array) => includedBy(array, value))
export const reduce = curry(
  (fn, initial, array) => array && array.reduce(fn, initial)
)

// /////// //
// Getters //
// /////// //

// pick -- generate a "sub-object" by selecting only the key-value pairs we
// want. Note: this function DOES return missing keys (i.e. { missingKey: undefined })
// and this behavior IS utilized in the app.
export const pick =
  (...keys) =>
  (sourceObj) =>
    sourceObj && keys.reduce((obj, key) => assoc(key, sourceObj[key], obj), {})

// getPath -- function to safely check for a path to a property on a
// nested object.
export const getPath =
  (...keys) =>
  (obj) => {
    const prop = (key) => (object) => object && object[key] // lodash curry sucks
    const fns = map(prop, keys)
    return flow(...fns)(obj)
  }

// /////// //
// Setters //
// /////// //

// overrideProp -- set a new value to a property of an object using a function of
// the existing value.
//
// Example:
// const data = { a: 'ace', b: 'BEEHIVE', c: 'chandelier' }
// overrideProp(
//   'b',
//   value => value.toLowerCase(),
//   data,
// ) ---> { a: 'ace', b: 'beehive', c: 'chandelier' }
export const overrideProp = curry(
  (key, fn, obj) => obj && assoc(key, fn(obj[key]), obj)
)

// dissoc -- immutably "delete" a key from an object.
export const dissoc = curry((key, obj) => {
  const keys = Object.keys(obj).filter((k) => k !== key)
  return pick(...keys)(obj)
})

// renameProp -- immutably rename a property on an object
export const renameProp = curry(
  (oldKey, newKey, obj) =>
    obj && flow(computeProp(newKey, getProp(oldKey)), dissoc(oldKey))(obj)
)

// assocProp -- essentially a "nested" assoc; associates a key-value pair on a
// path of the source object.
export const assocProp = curry((key1, key2, value, obj) =>
  assoc(key1, assoc(key2, value, getProp(key1, obj)), obj)
)

// dissocProp -- immutably "delete" a key from a property of an object.
export const dissocProp = curry((key1, key2, obj) =>
  assoc(key1, dissoc(key2, getProp(key1, obj)), obj)
)

// appendToProp -- immutably append an item to an array, accessed as a property
// on another object. Useful for setting state concisely.
export const appendToProp = curry((key, newEntry, sourceObj) =>
  assoc(key, [...getProp(key, sourceObj), newEntry], sourceObj)
)

// rejectFromProp -- immutably remove some items from an array property of an
// object.
export const rejectFromProp = curry((key, pred, sourceObj) =>
  assoc(
    key,
    getProp(key, sourceObj).filter((x) => !pred(x)),
    sourceObj
  )
)

// ///////////// //
// Boolean logic //
// ///////////// //

export const equals = curry((a, b) => a === b)

export const either = (fn1, fn2) => (obj) => fn1(obj) || fn2(obj)
export const both = (fn1, fn2) => (obj) => fn1(obj) && fn2(obj)

export const propIsTruthy = curry((key, obj) => !!getProp(key, obj))
export const pathIsTruthy =
  (...keys) =>
  (obj) =>
    !!getPath(...keys)(obj)
export const propIsFalsy = curry((key, obj) => !getProp(key, obj))
export const pathIsFalsy =
  (...keys) =>
  (obj) =>
    !getPath(...keys)(obj)

// ///////////// //
// Miscellaneous //
// ///////////// //

// Convert an array to an object
export const mapToObject = curry(
  (fn, array) =>
    array && array.reduce((memo, acc) => assocAll(fn(acc), memo), {})
)

export const mapOverObject = curry(
  (fn, obj) => obj && Object.keys(obj).map((key) => fn(key, obj[key]))
)

// setIf -- set a property on an object if the value is not falsy.
export const setIf = (obj, property, value) => {
  if (value) {
    obj[property] = value
  }
}

// spreadAndFlatten -- Generate an array by combining a two-dimensional array.
//
// Example:
// const parents = [
//   { name: "Eric", children: [
//     { name: "Adam" },
//     { name: "Dave" },
//   ]},
//   { name: "Wendy", children: [
//     { name: "Adam" },
//     { name: "Dave" },
//   ]},
//   { name: "Charlie", children: [
//     { name: "Bob" },
//     { name: "Linda" },
//   ]},
//   { name: "Sarah", children: [
//     { name: "John" },
//     { name: "April" },
//   ]},
// ]
// const children2d = map(getProp('children'), parents)
// const children = spreadAndFlatten(
//   (child1, child2) => child1.name === child2.name,
//   children,
// ) --> [
//   { name: "Adam" },
//   { name: "Dave" },
//   { name: "Bob" },
//   { name: "Linda" },
//   { name: "John" },
//   { name: "April" },
// ]
export const spreadAndFlatten = curry(
  (match, array) =>
    array &&
    array.reduce((memo, acc) => {
      acc.forEach((x) => {
        if (!memo.find((y) => match(x, y))) memo.push(x)
      })
      return memo
    }, [])
)

export const flatten = curry(
  (match, array) =>
    array &&
    array.reduce((memo, x) => {
      if (!memo.find((y) => match(x, y))) memo.push(x)
      return memo
    }, [])
)

/**
 * Returns a copy of the original object without the property specified in the path.
 * @param {object} obj
 * @param {string | (string | number)[]} path
 * @returns {object}
 */
export const unset = (obj, path) => {
  const newObj = cloneDeep(obj)
  const paths = cloneDeep(path)
  const finalPath = paths.pop()

  const parent = paths.reduce((current, key) => current?.[key], newObj)
  if (parent?.[finalPath]) {
    delete parent[finalPath]
  }

  return newObj
}
