import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import ImageSkeleton from '../../../ImageSkeleton'
import { getSwatchCloudinaryPath } from '../../../../utils/cloudinaryPaths'
import '../../../../types'
import { isLayer } from '../../../../utils'

const useStyles = makeStyles(() => ({
  card: {
    margin: 10,
  },
  cardHeader: {
    whiteSpace: 'nowrap',
    '@media screen and (orientation: portrait)': {
      fontSize: 'calc(12px + (24 - 12)*(100vw - 350px)/(1920 - 350))',
    },
    '@media screen and (orientation: landscape)': {
      fontSize: 'calc(10px + (20 - 12)*(100vw - 350px)/(1920 - 350))',
    },
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
  },
  schemeElement: {
    width: 100,
    height: 45,
  },
}))

/**
 * @typedef SchemeCardProps
 * @prop {Scheme} scheme
 * @prop {boolean} selected
 * @prop {() => void} onClick
 */

/**
 * @param {SchemeCardProps} props
 * @returns {React.ReactNode}
 */
export default function SchemeCard({ scheme, selected, onClick }) {
  const classes = useStyles()

  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: selected ? '#cccccc' : 'white',
      }}
      onClick={onClick}
    >
      <CardActionArea>
        <CardContent>
          <Typography className={classes.cardHeader} variant="h6">
            {scheme.name}
          </Typography>
          <div className={classes.cardContent}>
            {scheme.elements.map((element) => (
              <div
                key={element.id}
                className={classes.schemeElement}
                style={{
                  backgroundColor: !isLayer(element)
                    ? `#${element.color.hex}`
                    : 'unset',
                }}
              >
                {isLayer(element) && (
                  <ImageSkeleton
                    src={getSwatchCloudinaryPath(
                      element.swatch.image,
                      'w_100,h_45,c_fill'
                    )}
                    alt={element.swatch.name}
                  />
                )}
              </div>
            ))}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

SchemeCard.propTypes = {
  scheme: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

SchemeCard.defaultProps = {
  onClick: null,
}

SchemeCard.Loading = function SchemeCardLoading() {
  const classes = useStyles()

  return (
    <Card className={classes.card} style={{ backgroundColor: 'white' }}>
      <CardActionArea>
        <CardContent>
          <Typography className={classes.cardHeader} variant="h6">
            <Skeleton width={200} />
          </Typography>
          <div className={classes.cardContent}>
            <div className={classes.schemeElement}>
              <ImageSkeleton.Loading />
            </div>
            <div className={classes.schemeElement}>
              <ImageSkeleton.Loading />
            </div>
            <div className={classes.schemeElement}>
              <ImageSkeleton.Loading />
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

SchemeCard.Loading.propTypes = {}
SchemeCard.Loading.defaultProps = {}
