import { generatePath } from 'react-router-dom'
import { getRoomCloudinaryPath } from './cloudinaryPaths'
import '../types'
import { isLayer } from './roomLayer'

const HEMI_BASE_URL = process.env.HEMI_SERVER_PREFIX
const BASE_SCHEME = `api/cv/client/:clientName/nbr/:nbrhoodName/rooms/:roomId/:roomName`
const HYBRID_SCHEME = `${BASE_SCHEME}/hybrid/scheme/:schemeId/:schemeName`

/**
 * @param {Account} account
 * @param {Room} room
 * @param {Scheme} scheme
 * @returns {string}
 */
export default function getRoomImage(account, room, scheme, width = undefined) {
  const accountName = account?.account_name
  const cloudFolderName = account?.cloud_folder_name

  const baseImage =
    cloudFolderName &&
    room?.image &&
    getRoomCloudinaryPath(
      cloudFolderName,
      room.image,
      width ? `w_${width},c_fill` : ''
    )

  if (!scheme) return baseImage

  const pathSegment = generatePath(HYBRID_SCHEME, {
    clientName: accountName,
    nbrhoodName: room.project.name,
    roomId: room.id,
    roomName: room.name,
    schemeId: scheme.id,
    schemeName: scheme.name,
  })

  // TODO: Only send overrides
  // Low priority

  const queryArgs = {
    mats: scheme.elements
      .map((element) => (isLayer(element) ? 0 : element.mask.materialId))
      .join(','),
    pals: scheme.elements
      .map((element) =>
        isLayer(element) ? element.id : element.color.palette_id
      )
      .join(','),
    sels: scheme.elements
      .map((element) =>
        isLayer(element) ? element.id : element.color.color_id
      )
      .join(','),
    w: width,
    key: room.id,
    points: scheme.elements
      .map((element) => {
        const id = isLayer(element)
          ? `l${element.id}`
          : `m${element.mask.materialId}`
        return element.pointNumber && id ? `${id}_${element.pointNumber}` : ''
      })
      .join(','),
  }

  const overrideSegment = Object.entries(queryArgs)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  return `${HEMI_BASE_URL}/${pathSegment}?${overrideSegment}`
}
