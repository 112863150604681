import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { BannerConsumer, StoreConsumer } from '../../contexts'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import { PROPERTY_DETAILS_WITH_BUILDINGS } from '../../graphql/properties.graphql'
import BuildingCard from '../BuildingCard'
import MaterialFilter from '../MaterialFilter'
import { selectView } from '../../reducers/designCartActionCreators'
import { getSessionTokenFromUrl } from '../../utils/helper'
import { useFirstTimeVisit } from '../../utils/hooks'
import AppGuideModal from '../AppGuideModal'

const styles = makeStyles((theme) => ({
  homescapesContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 40,
    },
  },
}))

const HomePage = ({ property, match, history, store }) => {
  const classes = styles()
  const { buildings, account } = property
  const [homescapes, setHomescapes] = useState(buildings)

  const alreadyVisited = useFirstTimeVisit()

  const handleHomescapeSelect = async (homescape) => {
    await store.dispatch(selectView({ viewId: parseInt(homescape.id, 10) }))
    const baseUrl = match.url.replace('/home', '')
    history.push(
      `${baseUrl}/building/${homescape.id}/design${getSessionTokenFromUrl()}`
    )
  }

  return (
    <React.Fragment>
      <MaterialFilter homescapes={buildings} setHomescapes={setHomescapes} />
      <div className={classes.homescapesContainer}>
        {homescapes.map((homescape) => (
          <BuildingCard
            key={homescape.id}
            onSelect={handleHomescapeSelect}
            building={homescape}
            account={account}
          />
        ))}
      </div>
      {!alreadyVisited && <AppGuideModal />}
    </React.Fragment>
  )
}

HomePage.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    account: PropTypes.shape({
      id: PropTypes.number,
      cloud_folder_name: PropTypes.string,
    }).isRequired,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withConsumer(StoreConsumer, { propName: 'store' }),
  withRouter,
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  })
)(HomePage)

export { WrappedComponent as HomePage }
