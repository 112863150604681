import React from 'react'
import { compose } from 'recompose'
import { hot, setConfig } from 'react-hot-loader'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import BrochurePage from './components/pages/brochurePage/BrochurePage'
import { AppLayout } from './components/layouts/AppLayout'
import { BannerProvider } from './contexts'
import { withApolloProvider, withComponent, withSessionProvider } from './utils'
import {
  FinalizePage,
  InactivePage,
  HomescapesBrochurePage,
} from './components/pages'
import {
  ACCOUNT_PAGE,
  BROCHURE_PAGE,
  HOMESCAPES_BROCHURE_PAGE,
  SUBACC_BROCHURE_PAGE,
  SUBACC_HOMESCAPES_BROCHURE_PAGE,
} from './components/constants/Paths'
import theme from './theme'
import { S3RedirectPage } from './components/pages/S3RedirectPage'
import SnackProvider from './contexts/SnackProvider'

setConfig({
  showReactDomPatchNotification: false,
})

const NoMatch = () => <React.Fragment>404 Not Found.</React.Fragment>

let AppSwitch = () => {
  const { pathname } = useLocation()

  return (
    <Switch>
      {/* Catch-all route that will remove any trailing '/' */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route path={SUBACC_BROCHURE_PAGE} component={BrochurePage} />
      <Route path={BROCHURE_PAGE} component={BrochurePage} />
      <Route
        path={SUBACC_HOMESCAPES_BROCHURE_PAGE}
        component={HomescapesBrochurePage}
      />
      <Route
        path={HOMESCAPES_BROCHURE_PAGE}
        component={HomescapesBrochurePage}
      />
      <Route path={ACCOUNT_PAGE} component={AppLayout} />
      <Route path="/finalizeproject" component={FinalizePage} />
      <Route path="/inactiveproject" component={InactivePage} />
      <Route path="/fetchPaintGuide" component={S3RedirectPage} />
      <Route path="/403" component={S3RedirectPage} />
      <Route component={NoMatch} />
    </Switch>
  )
}

AppSwitch = compose(
  withApolloProvider,
  withComponent(SnackProvider),
  withComponent(BannerProvider),
  withSessionProvider
)(AppSwitch)

// Define all the valid routes, in the order a match should be attempted, and
// load a layout for any of the valid routes.
// Route anything else to the 404 page.
const App = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Route component={AppSwitch} />
      </BrowserRouter>
    </ThemeProvider>
  </React.Fragment>
)

const WrappedApp = compose(hot(module))(App)

export default WrappedApp
