/* eslint-disable max-len */
import React from 'react'

export const S3RedirectPage = () => {
  if (window.location.href.includes('/403')) {
    return (
      <React.Fragment>
        The file you are trying to access has been deleted or updated from the
        dashboard. Please check on dashboard.
      </React.Fragment>
    )
  }
  const urlComponents = window.location.href.split('fetchPaintGuide?=')
  if (urlComponents.length === 2) {
    const s3Url = urlComponents[1]
    const request = new XMLHttpRequest()
    try {
      request.open('HEAD', s3Url, false)
      request.send()
    } catch (e) {
      window.location.replace(`${urlComponents[0]}403`)
    }

    if (request.status === 200) {
      window.location.replace(s3Url)
    } else {
      window.location.replace(`${urlComponents[0]}403`)
    }
  }

  return <React.Fragment>Fetching the file, please wait.</React.Fragment>
}
