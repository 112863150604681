import { Button, makeStyles, Modal, Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import React, { useState } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { SelectionConsumer } from '../contexts'
import { withConsumer } from '../utils'
import Input from './Input'
import MaterialCard from './MaterialCard'
import { PalettePicker } from './PalettePicker'

const styles = makeStyles((theme) => ({
  modal: {
    background: 'white',
    border: '1px solid #d8dee2',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    '@media screen and (orientation: landscape)': {
      maxWidth: 'calc(260px + (600 - 260)*(100vw - 350px)/(1920 - 350))',
      margin: '5px',
    },
    '@media screen and (orientation: portrait)': {
      maxWidth: '100%',
      margin: '5px',
    },
  },
  originalColorContainer: {
    border: '1px solid #d8dee2',
    borderRadius: 4,
    padding: 10,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  modalText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  closeButton: {
    marginLeft: 'auto',
    marginBottom: 12,
    padding: 0,
    minWidth: 0,
  },
  closeButtonIcon: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
}))

const PalettePickerModalOld = ({
  setModalOpen,
  isModalVisible,
  schemeMaterial,
  selection,
  handleSelectColor,
  handleResetColor,
  customScheme,
}) => {
  const classes = styles()
  const [filterInput, setFilterInput] = useState('')

  const currentCustomMaterial = customScheme?.materials?.filter(
    (material) =>
      material.materialId === selection.currentPalettePickerMaterialId
  )[0]

  return (
    <Modal
      className={classes.modal}
      open={isModalVisible}
      hideBackdrop
      onClose={() => setModalOpen(false)}
    >
      <React.Fragment>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Typography className={classes.modalText} variant="h6">
            Choose a Color
          </Typography>
          <Button
            onClick={() => setModalOpen(false)}
            className={classes.closeButton}
          >
            <CloseRoundedIcon className={classes.closeButtonIcon} />
          </Button>
        </div>
        {schemeMaterial && (
          <div className={classes.originalColorContainer}>
            <div className={classes.textButtonContainer}>
              <Typography className={classes.modalText} variant="body1">
                Original Scheme Color
              </Typography>
              <Button
                className={classes.modalText}
                onClick={() => {
                  handleResetColor()
                  setModalOpen(false)
                }}
                variant="outlined"
              >
                Use Scheme Color
              </Button>
            </div>
            <MaterialCard
              style={{ marginLeft: 'auto' }}
              key={`${schemeMaterial.id}`}
              schemeMaterial={schemeMaterial}
              setPalettePickerOpen={() => {}}
              defaultMaterialColor
            />
          </div>
        )}
        <Input
          placeholder="Search by Color Name or Number"
          setInput={setFilterInput}
        />
        <PalettePicker
          filterInput={filterInput}
          handleSelectColor={handleSelectColor}
          materialId={selection.currentPalettePickerMaterialId}
          selectedColorId={currentCustomMaterial?.colorId}
        />
      </React.Fragment>
    </Modal>
  )
}

PalettePickerModalOld.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  schemeMaterial: PropTypes.object,
  selection: PropTypes.object.isRequired,
  handleSelectColor: PropTypes.func.isRequired,
  handleResetColor: PropTypes.func.isRequired,
  customScheme: PropTypes.object,
}

PalettePickerModalOld.defaultProps = {
  customScheme: {},
  schemeMaterial: {},
}

const WrapperComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' })
)(PalettePickerModalOld)

export { WrapperComponent as PalettePickerModalOld }
