import { createTheme } from '@material-ui/core'

const theme = createTheme({
  palette: {
    primary: { 500: '#0069af' },
  },
  typography: {
    // Used by the brochure page disclaimer in
    // src/components/pages/brochurePage/components/DefaultPage.jsx
    caption: {
      fontSize: 8,
    },
  },
})

export default theme
