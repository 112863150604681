import ExteriorImage from '@anewgo/exterior-image'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useMemo } from 'react'
import MaterialList from './MaterialList'
import { formatCustomMaterialsColors } from '../utils/helper'
import { materialCustomSorter } from '../utils/materialCustomSorter'

const styles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexFlow: 'column',
    minWidth: '30%',
    maxWidth: '31%',
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  schemeName: {
    display: 'flex',
    flexFlow: 'row-reverse',
    margin: 10,
    fontWeight: 500,
  },
  cardContent: {
    height: '100%',
    marginLeft: '7%',
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardActions: {
    width: 'max-content',
    marginLeft: 'auto',
    padding: 15,
  },
  actionButtons: {
    padding: '10px 30px',
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      padding: '5px 15px',
    },
  },
}))

const ShoppingCard = ({
  account,
  item,
  building,
  scheme,
  elevation,
  community,
  handleRemoveItem,
  handleEditItem,
  handleRenameItem,
  index,
  refElement,
  isBrochure,
}) => {
  const classes = styles()
  const view = building.views[0]
  const { customSchemes } = item

  const customMaterialsColors = formatCustomMaterialsColors(
    customSchemes && customSchemes[0]
  )

  const sortedViewElements = useMemo(
    () => materialCustomSorter(view.view_elements, 'material.display_name'),
    [view.view_elements]
  )

  return (
    <Card ref={refElement} className={classes.card}>
      <Typography className={classes.schemeName}>{item.schemeName}</Typography>
      <CardMedia>
        <ExteriorImage
          engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
          client={account.account_name}
          directoryName={account.cloud_folder_name}
          maxWidth={350} // responsive
          community={community}
          plan={building.name}
          elevation={elevation}
          selectedScheme={scheme}
          colorMtd="HYBRID"
          colorSelections={customMaterialsColors}
        />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        {sortedViewElements.map((element) => (
          <MaterialList
            key={element.id}
            schemeMaterial={scheme.scheme_elements.find(
              (schemeElement) =>
                schemeElement.material.id === element.material.id
            )}
            customScheme={customSchemes && customSchemes[0]}
            viewElement={element}
          />
        ))}
      </CardContent>
      {!isBrochure && (
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => handleRenameItem(index)}
          >
            Rename
          </Button>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => handleEditItem(index, item.viewId)}
          >
            Edit
          </Button>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => handleRemoveItem(index)}
          >
            Delete
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

ShoppingCard.propTypes = {
  index: PropTypes.number,
  handleRemoveItem: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleRenameItem: PropTypes.func,
  isBrochure: PropTypes.bool,
  community: PropTypes.string.isRequired,
  item: PropTypes.shape({
    schemeId: PropTypes.number,
    schemeName: PropTypes.string,
    viewId: PropTypes.number,
    customSchemes: PropTypes.arrayOf(
      PropTypes.shape({
        schemeId: PropTypes.number,
        materials: PropTypes.arrayOf(
          PropTypes.shape({
            colorId: PropTypes.number,
            colorPaletteId: PropTypes.number,
            elementId: PropTypes.number,
            materialId: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    account_name: PropTypes.string,
    cloud_folder_name: PropTypes.string,
  }).isRequired,
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    street_address: PropTypes.string,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        thumbnail_src: PropTypes.string,
        base_image_src: PropTypes.string,
        view_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            material: PropTypes.shape({
              id: PropTypes.number,
              element_id: PropTypes.number,
              display_name: PropTypes.string,
            }),
          })
        ),
      })
    ),
  }).isRequired,
  scheme: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    scheme_elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        material: PropTypes.shape({
          id: PropTypes.number,
          display_name: PropTypes.string,
        }),
        color: PropTypes.shape({
          id: PropTypes.number,
          identifier: PropTypes.string,
          name: PropTypes.string,
          hex: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  refElement: PropTypes.func,
  elevation: PropTypes.shape({
    base: PropTypes.string,
    caption: PropTypes.string,
    id: PropTypes.number,
    schemes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        scheme_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
          })
        ),
      })
    ),
    elevation: PropTypes.shape({
      base_image_src: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      thumbnail_src: PropTypes.string,
      brochure_layout: PropTypes.string,
      view_elements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          list_order: PropTypes.number,
          material: PropTypes.shape({
            id: PropTypes.number,
            element_id: PropTypes.number,
            display_name: PropTypes.string,
          }),
        })
      ),
    }),
  }).isRequired,
}

ShoppingCard.defaultProps = {
  refElement: undefined,
  index: null,
  handleRemoveItem: () => {},
  handleEditItem: () => {},
  handleRenameItem: () => {},
  isBrochure: false,
}

export default ShoppingCard
