import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { computeExteriorUrl } from '@anewgo/utils'
import InteractiveCanvas from './InteractiveCanvas'
import { getViewElementCloudinaryPath } from '../utils/cloudinaryPaths'

export default function InteractiveExteriorImage({
  apiKey,
  engineUrl,
  client,
  maxWidth,
  community,
  plan,
  elevation,
  selectedScheme,
  directoryName,
  colorMtd,
  colorSelections,
  viewElements,
  selectedMaterial,
  onHighlight,
  onSelect,
}) {
  const computedUrl = useMemo(
    () =>
      computeExteriorUrl(
        apiKey,
        client,
        colorMtd,
        community,
        directoryName,
        elevation,
        engineUrl,
        plan,
        selectedScheme,
        colorSelections,
        maxWidth,
        maxWidth > 0
      ),
    [
      apiKey,
      client,
      colorMtd,
      community,
      directoryName,
      elevation,
      engineUrl,
      plan,
      selectedScheme,
      colorSelections,
      maxWidth,
    ]
  )

  const allLayers = viewElements.map((element) => ({
    id: element.material.id,
    renderIndex: element.list_order ?? 1,
    image: getViewElementCloudinaryPath(
      directoryName,
      element.src,
      `w_${maxWidth},c_fit`
    ),
  }))

  const availableLayers = allLayers.filter((layer) =>
    selectedScheme.scheme_elements.some(
      (element) => layer.id === element.material?.id
    )
  )

  return (
    <InteractiveCanvas
      backdropSrc={computedUrl}
      allLayers={allLayers}
      availableLayers={availableLayers}
      selectedLayer={selectedMaterial}
      onSelect={onSelect}
      onHighlight={onHighlight}
    />
  )
}

InteractiveExteriorImage.propTypes = {
  apiKey: PropTypes.string,
  engineUrl: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  community: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  elevation: PropTypes.string.isRequired,
  selectedScheme: PropTypes.string.isRequired,
  directoryName: PropTypes.string.isRequired,
  colorMtd: PropTypes.string.isRequired,
  colorSelections: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewElements: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMaterial: PropTypes.object,
  onHighlight: PropTypes.func,
  onSelect: PropTypes.func,
}

InteractiveExteriorImage.defaultProps = {
  apiKey: '123',
  maxWidth: null,
  selectedMaterial: null,
  onHighlight: null,
  onSelect: null,
}
