import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose, lifecycle } from 'recompose'
import MenuIcon from '@material-ui/icons/Menu'
import { Route, Switch } from 'react-router-dom'
import {
  isWidthDown,
  makeStyles,
  Menu,
  MenuItem,
  withWidth,
  Paper,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import { ACCOUNT_BY_NAME } from '../../graphql/accounts.graphql'
import { BannerConsumer, SelectionConsumer } from '../../contexts'
import DesignPageButtonContainer from '../navButtons/DesignPageButtonContainer'
import { CartButton } from '../navButtons/CartButton'
import CartPageButtonContainer from '../navButtons/CartPageButtonContainer'
import { ShareButton } from '../navButtons/ShareButton'
import { FinalizePropertyButton } from './FinalizePropertyButton'
import { ShareLinkButton } from './ShareLinkButton'
import {
  BASE_IMAGE_URL,
  ACCOUNT_PAGE,
  BUILDING_PAGE,
  ROOM_PAGE,
  CART_PAGE,
  DESIGN_PAGE,
  HOME_PAGE,
  PROPERTY_PAGE,
  SUMMARY_PAGE,
  SUBACC_ACCOUNT_PAGE,
  SUBACC_BUILDING_PAGE,
  SUBACC_ROOM_PAGE,
  SUBACC_CART_PAGE,
  SUBACC_DESIGN_PAGE,
  SUBACC_HOME_PAGE,
  SUBACC_PROPERTY_PAGE,
  SUBACC_SUMMARY_PAGE,
  CART_PATHS,
} from '../constants/Paths'
import { SelectSchemeButton } from './SelectSchemeButton'
import { PdfButtonOld } from './PdfButtonOld'
import { Accounts } from '../../utils/accounts'

const styles = makeStyles({
  bannerWrapper: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto auto auto',
    alignItems: 'center',
    '@media (max-width : 590px)': {
      gridTemplateColumns: '1fr 5fr 1fr',
    },
  },
  banner: {
    background:
      'radial-gradient(ellipse 1200px 1200px at 50%,rgb(223, 223, 223) 0,rgb(236, 236, 236) 100%),radial-gradient(ellipse 1200px 500px at 50%,rgba(255, 255, 255, 0) 0,rgba(255,255,255,0) 100%)',
    gridColumn: '1 / end',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '5px',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  accountLogo: {
    gridColumn: '1',
    height: '70px',
    marginLeft: '5px',
    zIndex: 1000,
  },
  shwAccountLogo: {
    height: 'calc(40px + (70 - 40)*(100vw - 350px)/(1920 - 350))',
  },
  accountNameText: {
    color: '#171123',
    gridColumn: '2',
    textAlign: 'center',
    fontSize: 'calc(12px + (24 - 12)*(100vw - 350px)/(1920 - 350))',
  },
  bannerSubText: {
    color: '#171123',
    textAlign: 'center',
    fontSize: 'calc(8px + (16 - 8)*(100vw - 350px)/(1920 - 350))',
  },
  burgerMenu: {
    backgroundColor: '#ccc',
    borderRadius: '10px',
    paddingBottom: '10px',
    paddingTop: '2px',
    border: '2px solid #0069af !important',
  },
  buttonContainer: {
    gridColumn: '3',
  },
  disclaimerText: {
    width: 400,
    textAlign: 'justify',
    display: 'inline-flex',
    background: 'white',
    margin: 5,
    padding: 5,
    fontSize: '0.75rem',
  },
  mobileDisclaimerText: {
    width: '100%',
    margin: 0,
    padding: 25,
    borderRadius: 0,
  },
  mobileCloseIcon: {
    position: 'absolute',
    right: '3px',
    top: '3px',
  },
  burgerContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
})

const getAccountLogo = (account) => {
  // If current account logo is available use it
  if (account.account_logo) {
    return {
      cloud_folder_name: account.cloud_folder_name,
      account_logo: account.account_logo,
    }
  }

  // Otherwise try to use parent account logo
  if (account.parent_account) {
    return {
      cloud_folder_name: account.parent_account.cloud_folder_name,
      account_logo: account.parent_account.account_logo,
    }
  }

  return null
}

const AccountBanner = ({ account, banner, location, width }) => {
  const classes = styles()
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const isMobile = isWidthDown('md', width)
  const [showDisclaimer, setShowDisclaimer] = useState(true)
  const [isPortrait, setIsPortrait] = useState(false)

  const renderFinalizePropertyButton = (history, matchInput, locationInput) => (
    <>
      <FinalizePropertyButton
        propertyId={parseInt(matchInput.params.propertyId, 10)}
        history={history}
        location={locationInput || location}
        isPortrait={isPortrait}
        setIsPortrait={setIsPortrait}
      />
      <PdfButtonOld match={matchInput} location={locationInput || location} />
    </>
  )

  const navBarButtonsSwitch = () => (
    <>
      <Switch>
        <Route
          path={SUBACC_DESIGN_PAGE}
          component={DesignPageButtonContainer}
        />
        <Route path={SUBACC_HOME_PAGE} component={CartButton} />
        <Route path={SUBACC_CART_PAGE} component={CartPageButtonContainer} />
        <Route path={DESIGN_PAGE} component={DesignPageButtonContainer} />
        <Route path={HOME_PAGE} component={CartButton} />
        <Route path={CART_PAGE} component={CartPageButtonContainer} />
        <Route
          path={SUBACC_SUMMARY_PAGE}
          render={({ match, history }) =>
            renderFinalizePropertyButton(history, match, location)
          }
        />
        <Route
          path={SUMMARY_PAGE}
          render={({ match, history }) =>
            renderFinalizePropertyButton(history, match, location)
          }
        />
        <Route path={SUBACC_BUILDING_PAGE} component={SelectSchemeButton} />
        <Route path={BUILDING_PAGE} component={SelectSchemeButton} />
        <Route path={SUBACC_ROOM_PAGE} component={SelectSchemeButton} />
        <Route path={ROOM_PAGE} component={SelectSchemeButton} />
        <Route
          path={SUBACC_PROPERTY_PAGE}
          render={() => <SelectSchemeButton disabled />}
        />
        <Route
          path={PROPERTY_PAGE}
          render={() => <SelectSchemeButton disabled />}
        />
      </Switch>
      <Switch>
        {CART_PATHS.map((path) => (
          <Route path={path} key={path} component={ShareButton} />
        ))}
        {
          // Anything else is non-cart UI, and should have an inexact match for the
          // ACCOUNT_PAGE route.
        }
        <Route path={SUBACC_ACCOUNT_PAGE} component={ShareLinkButton} />
        <Route path={ACCOUNT_PAGE} component={ShareLinkButton} />
      </Switch>
    </>
  )

  const renderMobileMenu = () => (
    <Menu anchorEl={anchorEl} keepMounted open={openMobileMenu}>
      <MenuItem className={classes.burgerContainer}>
        {navBarButtonsSwitch()}
      </MenuItem>
    </Menu>
  )

  const renderBannerDisclaimer = () => (
    <Paper
      className={
        isMobile
          ? `${classes.disclaimerText} ${classes.mobileDisclaimerText}`
          : `${classes.disclaimerText}`
      }
    >
      The changes made on this page will be lost if refreshed or accessed later.
      Please save your changes by sharing the link, downloading the PDF, or
      sending them to a representative.
      {isMobile && (
        <CloseIcon
          className={classes.mobileCloseIcon}
          onClick={() => setShowDisclaimer(false)}
        />
      )}
    </Paper>
  )

  const accountLogo = getAccountLogo(account)

  return (
    <React.Fragment>
      {isMobile && showDisclaimer && (
        <Switch>
          {CART_PATHS.map((path) => (
            <Route path={path} key={path} component={renderBannerDisclaimer} />
          ))}
        </Switch>
      )}
      <div className={`${classes.bannerWrapper} ${classes.banner}`}>
        {
          // Display logo if we're a national account AND a logo is set.
          // Otherwise, default to the Sherwin-Williams logo.
          accountLogo?.account_logo ? (
            <img
              className={classes.accountLogo}
              alt="Account Logo"
              src={`${BASE_IMAGE_URL}/app/${accountLogo.cloud_folder_name}/assets/custom/${accountLogo.account_logo}`}
            />
          ) : (
            <img
              className={`${classes.shwAccountLogo} ${classes.accountLogo}`}
              alt="Account Logo"
              src={`${BASE_IMAGE_URL}/c_scale,h_100/v1612887431/app/cv/shw/app_images/clientlogo-alt_5b4e3a29f0a01.png`}
            />
          )
        }
        <div className={classes.accountNameText}>
          {account?.account_type === 'STOREFRONT' ? '' : account?.account_name}
          <div className={classes.bannerSubText}>{banner.subText}</div>
        </div>
        {isMobile ? (
          <div
            onClick={(event) => {
              setOpenMobileMenu(!openMobileMenu)
              setAnchorEl(event.currentTarget)
            }}
          >
            <MenuIcon style={{ float: 'right', marginRight: 10 }} />
            {renderMobileMenu()}
          </div>
        ) : (
          <div className={classes.buttonContainer}>
            <Switch>
              {CART_PATHS.map((path) => (
                <Route
                  path={path}
                  key={path}
                  component={renderBannerDisclaimer}
                />
              ))}
            </Switch>
            {navBarButtonsSwitch()}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

AccountBanner.propTypes = {
  banner: PropTypes.shape({
    subText: PropTypes.string,
  }).isRequired,
  location: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  selection: PropTypes.shape({
    setCurrentAccountId: PropTypes.func.isRequired,
  }).isRequired,
  width: PropTypes.string.isRequired,
}

const WrappedComponent = compose(
  withWidth(),
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_BY_NAME,
    variables: ({ match }) => {
      if (match.params.subAccountName === Accounts.DEFAULT_NAME) {
        return { accountName: match.params.accountName }
      }

      return {
        accountName: match.params.subAccountName || match.params.accountName,
      }
    },
    resultPropName: 'accountByName',
    propName: 'account',
  }),
  lifecycle({
    componentDidMount() {
      const { account, selection } = this.props
      if (account && account.id) {
        selection.setCurrentAccountId(account.id)
      }
    },
  })
)(AccountBanner)

export { WrappedComponent as AccountBanner }
