const downloadBrochureFile = (blob, property) => {
  const newBlob = new Blob([blob], { type: 'application/pdf' })
  // for IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  const prefix = property.name ? property.name.split(' ').join('_') : ''
  const fname = prefix === '' ? 'brochure.pdf' : `${prefix}_brochure.pdf`
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = fname
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const fetchBrochure = async (
  dst,
  isPortrait,
  property,
  setIsDownloading
) => {
  const url = `${process.env.REACT_APP_INGLENOOK_ENGINE}/api/cv/pdf`
  const getTheBrochure = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dst,
      clientName: property.account.account_name,
      communityName: property.name,
      isPortrait,
      paintGuideUrl: property.paint_guide_url,
    }),
  })

  if (getTheBrochure.status !== 200) {
    const error = new Error(
      `Server error, returned code ${getTheBrochure.status}`
    )
    error.code = getTheBrochure.status
    console.log('Failed to generate brochure', getTheBrochure)
    throw error
  } else {
    const blob = await getTheBrochure.blob()
    downloadBrochureFile(blob, property)

    setIsDownloading(false)
  }
}

export const fetchBrochureURL = async (
  dst,
  isPortrait,
  property,
  setIsGenerating
) => {
  setIsGenerating(true)
  const url = `${process.env.REACT_APP_INGLENOOK_ENGINE}/api/cv/pdfURL`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dst,
      clientName: property.account.account_name,
      communityName: property.name,
      isPortrait,
      paintGuideUrl: property.paint_guide_url,
    }),
  })

  setIsGenerating(false)
  if (!response || response.status !== 200) {
    const error = new Error(`Server error, returned code ${response.status}`)
    error.code = response.status
    console.log('Failed to generate brochure', response)
    throw error
  }

  const data = await response.json()
  return data.brochureS3URL
}

export const emailBrochure = async (
  dst,
  property,
  isPortrait,
  repEmail,
  fromEmail,
  contactName
) => {
  const url = `${process.env.REACT_APP_INGLENOOK_ENGINE}/api/email`

  const emailTheBrochure = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dst,
      clientName: property.account.account_name,
      communityName: property.name,
      isPortrait,
      repEmail,
      prospect: {
        email: fromEmail,
        name: contactName,
      },
    }),
  })

  if (emailTheBrochure.status !== 200) {
    const text = await emailTheBrochure.text()
    const error = new Error(text)
    error.code = emailTheBrochure.status
    throw error
  } else {
    console.log(emailTheBrochure)
  }
}
