import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { compose } from 'recompose'
import { withConsumer, withQueryResultAsProp } from '../utils'
import { StoreConsumer } from '../contexts'
import { customizeMaterialName } from '../reducers/designCartActionCreators'
import { HOA_MATERIALS } from '../graphql/materials.graphql'

const filter = createFilterOptions()

const styles = makeStyles((theme) => ({
  labelRoot: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
    input: {
      [theme.breakpoints.down('sm')]: {
        height: 10,
      },
    },
  },
  textField: {
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
    },
  },
}))

const MaterialNameInput = ({ schemeMaterial, store, hoaMaterials }) => {
  const classes = styles()
  const { currentDesign } = store.state
  const { customSchemes } = currentDesign
  const [value, setValue] = useState(null)
  const [open, toggleOpen] = useState(false)

  useEffect(() => {
    const customScheme =
      customSchemes &&
      customSchemes.filter(
        (scheme) => scheme.schemeId === currentDesign.schemeId
      )[0]

    const customSchemeMaterial =
      customScheme &&
      customScheme.materials.filter(
        (material) =>
          schemeMaterial && schemeMaterial.material.id === material.materialId
      )[0]

    setValue(
      (customSchemeMaterial && customSchemeMaterial.materialName) || null
    )
  }, [schemeMaterial])

  const handleClose = () => {
    toggleOpen(false)
    setValue(null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await store.dispatch(customizeMaterialName({ materialName: value }))
    toggleOpen(false)
  }

  return (
    <React.Fragment>
      <Autocomplete
        className={classes.textField}
        id="material-name-input"
        value={value}
        size="small"
        onChange={(e, newValue) => {
          if (newValue && newValue.inputValue) {
            setValue(newValue.inputValue.trim())
            toggleOpen(true)
          } else if (newValue && newValue.display_name) {
            const trimmedValue = newValue.display_name.trim()
            setValue(trimmedValue)
            store.dispatch(
              customizeMaterialName({ materialName: trimmedValue })
            )
          } else {
            setValue(newValue.trim())
          }
        }}
        options={hoaMaterials}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.display_name
        }}
        filterOptions={(options, params) => {
          const trimmed = params.inputValue.trim()
          const filtered = filter(options, { ...params, inputValue: trimmed })

          if (trimmed !== '') {
            filtered.push({
              inputValue: params.inputValue,
              display_name: `Add "${params.inputValue.trim()}"`,
            })
          }

          return filtered
        }}
        renderOption={(option) => option.display_name}
        renderInput={(params) => (
          <TextField
            size="small"
            InputProps={{ classes: { root: classes.input } }}
            {...params}
            label="Choose a Substrate"
            variant="outlined"
          />
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Custom Substrate</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Custom substrate will not be added to HOA archives.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

MaterialNameInput.propTypes = {
  store: PropTypes.object.isRequired,
  schemeMaterial: PropTypes.object.isRequired,
  hoaMaterials: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const WrappedComponent = compose(
  withConsumer(StoreConsumer, { propName: 'store' }),
  withQueryResultAsProp({
    gqlDocument: HOA_MATERIALS,
    resultPropName: 'hoaMaterials',
  })
)(MaterialNameInput)

export { WrappedComponent as MaterialNameInput }
