import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ImageSkeleton from './ImageSkeleton'

const styles = makeStyles(() => ({
  card: {
    // https://www.smashingmagazine.com/2016/05/fluid-typography/
    // calc(
    //   min_font_size +
    //   (max_font_size - min_font_size)*
    //   (100vw - min_screen_size)/(max_screen_size - min_screen_size)
    // )
    width: 'calc(300px + (450 - 300)*(100vw - 350px)/(1920 - 350))',
    margin: 10,
    display: 'flex',
  },
  cardActionArea: {
    display: 'flex',
    flexFlow: 'column',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    width: 'inherit',
    justifyContent: 'center',
    marginTop: 'auto',
  },
  cardImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 'calc(170px + (300 - 170)*(100vw - 350px)/(1920 - 350))',
    width: '100%',
    padding: 10,
  },
  contentText: {
    fontSize: 'calc(12px + (24 - 12)*(100vw - 350px)/(1920 - 350))',
  },
  contentTextBody: {
    fontSize: 'calc(10px + (16 - 10)*(100vw - 350px)/(1920 - 350))',
  },
}))

export const CARD_THUMBNAIL_SIZE = 500

export default function PropertyCard({
  title,
  thumbnailSrc,
  content,
  onClick,
}) {
  const classes = styles()

  return (
    <Card onClick={onClick} className={classes.card}>
      <CardActionArea className={classes.cardActionArea}>
        {thumbnailSrc && (
          <div className={classes.imageContainer}>
            <ImageSkeleton
              className={classes.cardImage}
              alt={`${title} Image`}
              src={thumbnailSrc}
            />
          </div>
        )}
        <CardContent className={classes.cardContent}>
          {content?.(classes)}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

PropertyCard.propTypes = {
  title: PropTypes.string,
  thumbnailSrc: PropTypes.string,
  content: PropTypes.func,
  onClick: PropTypes.func,
}

PropertyCard.defaultProps = {
  title: null,
  thumbnailSrc: null,
  content: null,
  onClick: null,
}
