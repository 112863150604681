import { makeStyles, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebounce } from '../utils/hooks'
import { validateEmail, validateSherwinOrAnewgoEmail } from '../utils/helper'

const styles = makeStyles((theme) => ({
  textField: {
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '2px 0',
    },
  },
  input: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const Input = ({
  placeholder,
  setInput,
  emailInput,
  style,
  required,
  sherwinEmail,
}) => {
  const classes = styles()
  const [inputText, setInputText] = useState('')
  const [validEmail, setValidEmail] = useState(true)

  const debouncedInput = useDebounce(inputText, 300)

  useEffect(() => {
    setInput(debouncedInput && debouncedInput.trim())
    if (debouncedInput && sherwinEmail) {
      setValidEmail(validateSherwinOrAnewgoEmail(debouncedInput))
    } else if (debouncedInput && emailInput) {
      setValidEmail(validateEmail(debouncedInput))
    }
  }, [debouncedInput])

  return (
    <TextField
      className={classes.textField}
      style={{ ...style }}
      size="small"
      variant="outlined"
      InputProps={{ classes: { input: classes.input } }}
      label={placeholder}
      error={!validEmail}
      required={required}
      onChange={(event) => setInputText(event.target.value)}
    />
  )
}

Input.propTypes = {
  emailInput: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setInput: PropTypes.func.isRequired,
  style: PropTypes.object,
  sherwinEmail: PropTypes.bool,
}

Input.defaultProps = {
  emailInput: false,
  placeholder: '',
  required: false,
  style: {},
  sherwinEmail: false,
}

export default Input
