import { useRef, useLayoutEffect } from 'react'
import { debounce } from 'lodash'

/**
 * Implements the proposed `useEvent` hook.
 * The returned function keeps a stable reference.
 * @template {Function} Handler
 * @param {Handler} handler
 * @returns {Handler}
 */
export function useStable(handler) {
  const unstableHandler = useRef(null)
  useLayoutEffect(() => {
    unstableHandler.current = handler
  }, [handler])

  const stableHandler = useRef((...args) => {
    unstableHandler.current(...args)
  })

  return stableHandler.current
}

/**
 * @template {Function} FnType
 * @param {FnType} fn
 * @param {number=} delay
 * @returns {FnType}
 */
export function useDebounce(fn, delay = 300) {
  return useRef(debounce(fn, delay)).current
}

/**
 * @typedef UseIntersectOptions
 * @prop {HTMLElement=} root
 * @prop {number=} threshold
 * @prop {number=} rootMargin
 */

/**
 * @param {(intersecting: boolean) => void} effect
 * @param {UseIntersectOptions} options
 * @returns {(elem: HTMLElement) => void}
 */
export function useIntersect(effect, options) {
  const handler = useStable(effect)

  const listener = useStable((entries) => {
    const entry = entries?.[0]
    handler?.(!!entry?.isIntersecting)
  })

  const observer = useRef(null)

  return (element) => {
    if (element) {
      // It would be better to reuse the same instance
      // and call `observer.unobserve(previousElement)`, but
      // that does not seem to work. Instead, we create a
      // new observer instance for every new element.
      observer.current?.disconnect()
      observer.current = new IntersectionObserver(listener, {
        root: null,
        ...options,
      })
      observer.current.observe(element)
    }
  }
}
