const cloudBase = 'https://res.cloudinary.com/renderinghouse/image/upload/'

const cloudinaryDir = (
  directoryName,
  type,
  mods,
  planId,
  inventoryId,
  communityId
) => {
  let dir = ''
  let noDirectoryNameDir = ''
  if (mods) {
    dir = `${mods}/`
    noDirectoryNameDir = `${mods}/`
  }
  dir = `${dir}app/${directoryName}`

  switch (type) {
    case 'assets':
      dir = `${dir}/assets/`
      break
    case 'communityAssets':
      dir = `${dir}/assets/community_${communityId}/imgs/`
      break
    case 'custom':
      dir = `${dir}/assets/custom/`
      break
    case 'thumbnail':
      dir = `${dir}/thumbnails/`
      break
    case 'image':
      dir = `${dir}/images/`
      break
    case 'imageNotFound':
      dir = 'app/cv/shw/app_images/imageNotFound.png'
      break
    case 'inventoryPhoto':
      dir = `${dir}/assets/inventory-${inventoryId}/`
      break
    case 'planExtPhotos':
      dir = `${dir}/assets/photos/exterior/0_0_${planId}_extPhotos/`
      break
    case 'planIntPhotos':
      dir = `${dir}/assets/photos/interior/0_0_${planId}_intPhotos/`
      break
    case 'texturePhoto':
      dir = `${noDirectoryNameDir}anewgo/library/textures/`
      break
    case 'texturePhotosBrick':
      dir = `${noDirectoryNameDir}anewgo/library/textures/`
      break
    case 'texturePhotosStone':
      dir = `${noDirectoryNameDir}anewgo/library/textures/`
      break
    default:
      dir = `${dir}/images/`
  }
  return dir
}

const generateUrl = (...args) => `${cloudBase}${cloudinaryDir(...args)}`

export { cloudinaryDir, generateUrl }
