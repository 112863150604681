import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import Snackbar from '../Snackbar'
import { SelectionConsumer, BannerConsumer } from '../../contexts'
import { BUILDING_DETAILS } from '../../graphql/buildings.graphql'
import { getLogger, withConsumer, withQueryResultAsProp } from '../../utils'
import {
  createElevation,
  getCustomSchemeFromSelection,
  getCustomColorsForSelectedScheme,
  mapCustomSchemeToMaterialCardFormat,
} from '../../utils/helper'
import { BASE_IMAGE_URL } from '../constants/Paths'
import SchemeCard from '../SchemeCard'
import MaterialCard from '../MaterialCard'
import InteractiveExteriorImage from '../InteractiveExteriorImage'
import { PalettePickerModalOld } from '../PalettePickerModalOld'

const log = getLogger('BuildingPage.jsx')

const styles = makeStyles((theme) => ({
  designPageContainer: {
    '@media screen and (orientation: portrait)': {
      display: 'flex',
      flexDirection: 'column',
    },
    '@media screen and (orientation: landscape)': {
      display: 'grid',
      gridTemplateColumns:
        'calc(260px + (600 - 260)*(100vw - 350px)/(1920 - 350)) 1fr',
      gridTemplateRows: '85fr 15fr',
      height: 'calc(100vh - 153px)',
    },
    backgroundColor: 'white',
  },
  imageContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 2,
    display: 'flex',
    minHeight: '40vh',
    padding: 0,
  },
  imageContainerCentered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  schemesMaterialsContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    maxHeight: '100%',
    '@media screen and (orientation: landscape)': {
      padding: 20,
    },
    '@media screen and (orientation: portrait)': {
      padding: 5,
      height: 'calc(100vh - 92px)',
    },
  },
  schemesContainer: {
    padding: 5,
    overflowY: 'auto',
    height: '60%',
  },
  materialsContainer: {
    padding: 10,
    overflowY: 'auto',
    height: '40%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectScheme: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const BuildingPage = ({ building, selection, history }) => {
  const { property } = building
  if (property.is_active === false) {
    history.push('/inactiveproject')
  }
  const classes = styles()
  const { schemes, account } = property
  const { views } = building
  const viewMaterialIds =
    building?.views[0]?.view_elements?.map((ve) => ve.material.id) || []
  log.debug('viewMaterialIds:', viewMaterialIds)

  const [openPropertyFinalized, setOpenPropertyFinalized] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [imageHeight, setImageHeight] = useState(null)
  const imageContainerRef = useRef(null)

  const [highlightedMaterial, setHighlightedMaterial] = useState(null)

  useEffect(() => {
    setImageHeight(
      imageContainerRef.current &&
        Math.round(imageContainerRef.current.offsetHeight)
    )
  }, [imageContainerRef])

  const selectedScheme = getCustomSchemeFromSelection(
    building,
    property,
    selection
  )

  const customColorsForSelectedScheme = getCustomColorsForSelectedScheme(
    building,
    property,
    selection,
    selectedScheme
  )

  const customScheme = mapCustomSchemeToMaterialCardFormat(
    selectedScheme,
    customColorsForSelectedScheme
  )
  const elevation = createElevation(schemes, views[0])

  const schemeMaterial = selectedScheme?.scheme_elements?.find(
    (schemeElement) =>
      schemeElement.material.id === selection.currentPalettePickerMaterialId
  )

  const handleSchemeSelect = (scheme) => {
    if (property.is_finalized) {
      setOpenPropertyFinalized(true)
    } else if ((selectedScheme && selectedScheme.id) === scheme.id) {
      // If this scheme was already selected, unselect it.
      selection.setCurrentSchemeId(property.id, building.id, undefined)
    } else {
      selection.setCurrentSchemeId(property.id, building.id, scheme.id)
    }
  }

  const handleSelectMaterial = (materialId) => {
    selection.setCurrentPalettePickerMaterialId(materialId)
    setOpenModal(true)
  }

  const handleSelectColor = (paletteItem) => {
    if (property.is_finalized) {
      setOpenPropertyFinalized(true)
    } else {
      selection.setCustomMaterialColor(
        property.id,
        building.id,
        selectedScheme.id,
        selection.currentPalettePickerMaterialId,
        {
          ...paletteItem.color,
          // Add some custom fields needed by the
          // ExteriorImage component later.
          materialElementId: schemeMaterial.element_id,
          materialPaletteId: paletteItem.palette_id,
        }
      )
      selection.setCurrentPalettePickerColorId(paletteItem.color.id)
      // selection.setOriginalPalettePickerColorId(schemeElement.color.id)
    }
  }

  const handleResetColor = () => {
    selection.clearCustomMaterialColor(
      property.id,
      building.id,
      selectedScheme.id,
      selection.currentPalettePickerMaterialId
    )
    selection.setCurrentPalettePickerColorId(null)
  }

  return (
    <div className={classes.designPageContainer}>
      <div
        ref={imageContainerRef}
        className={
          selectedScheme
            ? classes.imageContainer
            : `${classes.imageContainer} ${classes.imageContainerCentered}`
        }
      >
        {selectedScheme ? (
          <InteractiveExteriorImage
            engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
            client={account.account_name}
            maxHeight={
              imageContainerRef.current &&
              Math.round(imageContainerRef.current.offsetHeight)
            }
            maxWidth={
              imageContainerRef.current &&
              Math.round(imageContainerRef.current.offsetWidth)
            }
            community={building.property.name}
            plan={building.name}
            elevation={elevation}
            selectedScheme={selectedScheme}
            directoryName={account.cloud_folder_name}
            colorMtd="HYBRID"
            viewElements={building?.views?.[0]?.view_elements}
            colorSelections={
              customColorsForSelectedScheme
                ? Object.assign(
                    {},
                    ...Object.entries(customColorsForSelectedScheme)
                      .filter((key) => !Number.isNaN(parseInt(key, 10)))
                      .map(([, value]) => ({
                        [value.materialElementId]: {
                          paletteId: value.materialPaletteId,
                          id: value.id,
                        },
                      }))
                  )
                : {}
            }
            selectedMaterial={highlightedMaterial}
            onHighlight={setHighlightedMaterial}
            onSelect={(layer) => handleSelectMaterial(layer.id)}
          />
        ) : (
          imageHeight && (
            <img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              alt="Thumbnail_Image"
              src={`${BASE_IMAGE_URL}/q_auto,h_${imageHeight}/app/${account.cloud_folder_name}/images/${building.views[0].thumbnail_src}`}
            />
          )
        )}
      </div>
      <div className={classes.schemesMaterialsContainer}>
        <Paper className={classes.schemesContainer}>
          {schemes.map((scheme) => (
            <SchemeCard
              key={scheme.id}
              scheme={scheme}
              currentDesign={{ schemeId: selectedScheme?.id }}
              viewElements={viewMaterialIds}
              handleSchemeSelect={handleSchemeSelect}
            />
          ))}
        </Paper>
        <Paper className={classes.materialsContainer}>
          {selectedScheme ? (
            selectedScheme.scheme_elements
              .filter((schemeElement) =>
                viewMaterialIds.includes(schemeElement.material.id)
              )
              .map((element) => (
                <MaterialCard
                  key={element.id}
                  schemeMaterial={selectedScheme.scheme_elements.find(
                    (schemeElement) =>
                      schemeElement.material.id === element.material.id
                  )}
                  customScheme={customScheme}
                  viewElement={element}
                  handleSelectMaterial={handleSelectMaterial}
                  onMouseEnter={() =>
                    setHighlightedMaterial({ id: element.material.id })
                  }
                  onMouseLeave={() => setHighlightedMaterial(null)}
                />
              ))
          ) : (
            <Typography className={classes.selectScheme} variant="h6">
              Select Scheme
            </Typography>
          )}
        </Paper>
      </div>
      <PalettePickerModalOld
        isModalVisible={openModal}
        setModalOpen={setOpenModal}
        schemeMaterial={schemeMaterial}
        handleSelectColor={handleSelectColor}
        handleResetColor={handleResetColor}
        customScheme={customScheme}
      />
      <Snackbar
        open={openPropertyFinalized}
        setOpen={setOpenPropertyFinalized}
        message="This project's selections have been finalized and cannot be changed."
        severity="warning"
      />
    </div>
  )
}

BuildingPage.propTypes = {
  banner: PropTypes.shape({
    setSubText: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    street_address: PropTypes.string,
    property: PropTypes.shape({
      id: PropTypes.number,
      is_finalized: PropTypes.bool.isRequired,
      is_active: PropTypes.bool.isRequired,
      name: PropTypes.string,
      account: PropTypes.shape({
        id: PropTypes.number,
        account_name: PropTypes.string,
        cloud_folder_name: PropTypes.string,
      }).isRequired,
      schemes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          scheme_elements: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              material: PropTypes.shape({
                id: PropTypes.number,
                display_name: PropTypes.string,
              }),
              color: PropTypes.shape({
                id: PropTypes.number,
                identifier: PropTypes.string,
                name: PropTypes.string,
                hex: PropTypes.string,
              }),
            })
          ),
        })
      ),
    }).isRequired,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        base_image_src: PropTypes.string,
        thumbnail_src: PropTypes.string,
        view_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            material: PropTypes.shape({
              id: PropTypes.number,
            }),
            src: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  selection: PropTypes.shape({
    currentPalettePickerMaterialId: PropTypes.number,
    propertyBuildingScheme: PropTypes.shape({}),
    setCurrentPalettePickerMaterialId: PropTypes.func.isRequired,
    setCurrentPalettePickerColorId: PropTypes.func.isRequired,
    setCurrentSchemeId: PropTypes.func.isRequired,
    setCustomMaterialColor: PropTypes.func.isRequired,
    clearCustomMaterialColor: PropTypes.func.isRequired,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withQueryResultAsProp({
    gqlDocument: BUILDING_DETAILS,
    variables: ({ match }) => ({
      buildingId: parseInt(match.params.buildingId, 10),
    }),
    resultPropName: 'building',
  }),
  lifecycle({
    componentDidMount() {
      // Update what the banner needs to render, rather than rendering it in this
      // page.
      this.props.banner.setSubText(
        `${this.props.building.name || ''} ${
          this.props.building.street_address
            ? `| ${this.props.building.street_address}`
            : ''
        }`
      )
    },
    componentWillUnmount() {
      this.props.banner.setSubText('')
    },
  }),
  withRouter
)(BuildingPage)

export { WrappedComponent as BuildingPage }
