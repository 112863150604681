import { makeStyles } from '@material-ui/core'

export const navButtonStyles = makeStyles((theme) => ({
  button: {
    width: 165,
    margin: 5,
    float: 'right',
    whiteSpace: 'nowrap',
  },
  buttonContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  buttonText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
  buttonIcon: {
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  emptyCardDialogActions: {
    justifyContent: 'center',
  },
}))
