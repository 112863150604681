import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import { PROPERTY_DETAILS_WITH_BUILDINGS } from '../../graphql/properties.graphql'
import {
  ADD_SESSION,
  SEND_TO_INTEGRATION_ENDPOINT,
} from '../../graphql/sessions.graphql'
import { HOA_MATERIALS } from '../../graphql/materials.graphql'
import {
  getSessionIdAndToken,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils'
import { BannerConsumer, StoreConsumer } from '../../contexts'
import { setSessionId } from '../../reducers/designCartActionCreators'
import { getSessionTokenFromUrl, getSchemesMaterials } from '../../utils/helper'
import { emailBrochure } from '../../utils/pdfHelper'
import Snackbar from '../Snackbar'
import SendToRepForm from '../SendToRepForm'
import { HOAValidationErrorDialog } from '../HOAValidationErrorDialog'
import { HomePageButton } from './HomePageButton'

const styles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
})

const SendToRepButton = ({
  addSession,
  sendToIntegrationEndpoint,
  store,
  location,
  banner,
  property,
}) => {
  const classes = navButtonStyles()
  const classesSend = styles()
  const [openDialog, setOpenDialog] = useState(false)
  const [showHoaErrorDialog, setShowHoaErrorDialog] = useState(false)
  const [sendingEmailFailed, setSendingEmailFailed] = useState(false)
  const [sendingEmailSuccess, setSendingEmailSuccess] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { buildings } = property

  const handleSendToRepAndApi = async (
    repEmail,
    fromEmail,
    contactName,
    contactPhone,
    companyName,
    companyAddress,
    companyCity,
    companyState,
    companyZip
  ) => {
    setIsSending(true)
    setOpenDialog(false)

    // Sending to Rep and sending to API are both done in this function in order to share this
    // common session initialization code, and avoid a race.
    let sessionState = store.getState()

    if (!sessionState.sessionId) {
      const { sessionId, token: sessionToken } = await getSessionIdAndToken(
        sessionState,
        addSession
      )

      // Update the store.
      await store.dispatch(setSessionId({ sessionId }))

      // Get updated state from the updated store.
      sessionState = store.getState()

      window.history.pushState(null, '', `?token=${sessionToken}`)
    }

    const integrationEndpointInput = {
      repEmail,
      fromEmail,
      contactName,
      contactPhone,
      companyName,
      companyAddress,
      companyCity,
      companyState,
      companyZip,
    }

    try {
      await sendToIntegrationEndpoint({
        sessionId: sessionState.sessionId,
        input: integrationEndpointInput,
      })
    } catch (error) {
      setErrorMessage(
        `A problem occurred while sending information to the HOA Archive:\n\n${error
          .toString()
          .replace('GraphQL error: ', '')
          .replace('Error: ', '')}`
      )
      setSendingEmailFailed(true)
      setIsSending(false)
    }

    const pdfUrl = `${process.env.FRONT_END_APP_SERVER}${location.pathname
      .replace(/ /g, '%20')
      .replace('cart', 'brochure')}${getSessionTokenFromUrl()}`

    try {
      await emailBrochure(
        pdfUrl,
        banner.property,
        true,
        repEmail,
        fromEmail,
        contactName
      )
      setSendingEmailSuccess(true)
      setIsSending(false)
    } catch (error) {
      if (error.message) {
        setErrorMessage(error.message)
      } else if (error.code === 400) {
        setErrorMessage('Failed to send email, please check your input fields.')
      } else {
        setErrorMessage('Failed to send email, please try again later.')
      }
      setSendingEmailFailed(true)
      setIsSending(false)
    }
  }

  const isEmptyCart = useMemo(
    () => store.getState().cart == null || store.getState().cart.length <= 0,
    [store.getState().cart]
  )

  return (
    <React.Fragment>
      <Dialog open={isEmptyCart}>
        <DialogTitle>Your cart is empty!</DialogTitle>
        <DialogContent>
          Please go to home page and add items to cart.
        </DialogContent>
        <DialogActions className={classes.emptyCardDialogActions}>
          <HomePageButton />
        </DialogActions>
      </Dialog>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={isSending}
        onClick={() => {
          const sessionState = store.getState()
          const buildingIds = sessionState.cart?.map(
            (cartItem) => cartItem.viewId
          )

          const selectedBuildings = buildings.filter((building) =>
            buildingIds.includes(building.id)
          )

          const unknownMaterials = getSchemesMaterials(
            sessionState,
            selectedBuildings
          )

          if (unknownMaterials?.length > 0) {
            setShowHoaErrorDialog(true)
          } else {
            setOpenDialog(true)
          }
        }}
      >
        <Typography className={classes.buttonContent}>
          <EmailOutlinedIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonText} variant="button">
            Send to Rep
          </Typography>
        </Typography>
        {isSending && (
          <CircularProgress
            size={40}
            className={classesSend.circularProgress}
          />
        )}
      </Button>
      <SendToRepForm
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleSubmit={handleSendToRepAndApi}
      />
      <Snackbar
        open={sendingEmailFailed}
        setOpen={setSendingEmailFailed}
        severity="error"
        message={errorMessage}
      />
      <Snackbar
        open={sendingEmailSuccess}
        setOpen={setSendingEmailSuccess}
        severity="success"
        message="Email successfully sent."
      />
      {showHoaErrorDialog && (
        <HOAValidationErrorDialog
          property={property}
          openError={showHoaErrorDialog}
          setOpenError={setShowHoaErrorDialog}
        />
      )}
    </React.Fragment>
  )
}

SendToRepButton.propTypes = {
  addSession: PropTypes.func.isRequired,
  sendToIntegrationEndpoint: PropTypes.func.isRequired,
  banner: PropTypes.shape({
    property: PropTypes.shape({
      name: PropTypes.string.isRequired,
      account: PropTypes.shape({
        account_name: PropTypes.string.isRequired,
        cloud_folder_name: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  store: PropTypes.shape({
    state: PropTypes.shape({
      sessionId: PropTypes.number,
    }),
    getState: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
            view_elements: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                material: PropTypes.shape({
                  id: PropTypes.number,
                  element_id: PropTypes.number,
                  display_name: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ),
  }).isRequired,
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(StoreConsumer, { propName: 'store' }),
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withMutationAsProp({
    gqlDocument: SEND_TO_INTEGRATION_ENDPOINT,
    mutationPropName: 'sendToIntegrationEndpoint',
  }),
  withQueryResultAsProp({
    gqlDocument: HOA_MATERIALS,
    resultPropName: 'hoaMaterials',
  }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  })
)(SendToRepButton)

export { WrappedComponent as SendToRepButton }
