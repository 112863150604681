import React from 'react'
import { HomePageButton } from './HomePageButton'
import { PdfButton } from './PdfButton'
import { SendToRepButton } from './SendToRepButton'

const CartPageButtonContainer = () => (
  <React.Fragment>
    <SendToRepButton />
    <PdfButton />
    <HomePageButton />
  </React.Fragment>
)

export default CartPageButtonContainer
