export const BASE_ACCOUNT_PATH = '/account/:accountName'
export const BASE_SUBACCOUNT_PATH =
  '/account/:accountName/subAccount/:subAccountName'
export const BASE_ACCOUNT_PROJECT_PATH = `${BASE_ACCOUNT_PATH}/project/:propertyId`
export const BASE_SUBACCOUNT_PROJECT_PATH = `${BASE_SUBACCOUNT_PATH}/project/:propertyId`

export const DESIGN_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/building/:buildingId/design`
export const BUILDING_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/building/:buildingId`
export const ROOM_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/room/:roomId`
export const BROCHURE_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/print/brochure`
export const HOMESCAPES_BROCHURE_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/brochure`
export const SUMMARY_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/summary`
export const HOME_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/home`
export const CART_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}/cart`
export const PROPERTY_PAGE = `${BASE_ACCOUNT_PROJECT_PATH}`
export const ACCOUNT_PAGE = `${BASE_ACCOUNT_PATH}`

export const SUBACC_DESIGN_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/building/:buildingId/design`
export const SUBACC_BUILDING_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/building/:buildingId`
export const SUBACC_ROOM_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/room/:roomId`
export const SUBACC_BROCHURE_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/print/brochure`
export const SUBACC_HOMESCAPES_BROCHURE_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/brochure`
export const SUBACC_SUMMARY_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/summary`
export const SUBACC_HOME_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/home`
export const SUBACC_CART_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}/cart`
export const SUBACC_PROPERTY_PAGE = `${BASE_SUBACCOUNT_PROJECT_PATH}`
export const SUBACC_ACCOUNT_PAGE = `${BASE_SUBACCOUNT_PATH}`

export const CART_PATHS = [
  SUBACC_DESIGN_PAGE,
  SUBACC_HOME_PAGE,
  SUBACC_CART_PAGE,
  DESIGN_PAGE,
  HOME_PAGE,
  CART_PAGE,
]

export const BASE_IMAGE_URL =
  'https://res.cloudinary.com/renderinghouse/image/upload'
export const SHERWIN_WILLIAMS_LOGO = `${BASE_IMAGE_URL}/v1612887430/app/cv/shw/app_images/sherwin_williams_text_only_logo.svg`
