import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import Snackbar from '../Snackbar'
import {
  getSessionIdAndToken,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils'
import { SelectionConsumer } from '../../contexts'
import { ADD_SESSION } from '../../graphql/sessions.graphql'
import { fetchBrochure } from '../../utils/pdfHelper'
import { TRACK_BROCHURE_DOWNLOAD } from '../../graphql/tracking.graphql'
import { PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS } from '../../graphql/properties.graphql'

const styles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
})

const PdfButtonOld = ({
  selection,
  addSession,
  location,
  trackBrochureDownload,
  property,
  isPortrait,
}) => {
  const classes = navButtonStyles()
  const classesPdf = styles()
  const [isGenerating, setIsGenerating] = useState(false)
  const [failedToGenerate, setFailedToGenerate] = useState(false)

  const handleGeneratePdf = async () => {
    setIsGenerating(true)
    const selectionState = selection.getState()
    const { token: stateToken } = await getSessionIdAndToken(
      selectionState,
      addSession
    )
    // For some reason, React Router converts the URL encoding for %20
    // back into a space. When constructing a URL from the React Router
    // Change all spaces back to a "%20".
    const pdfUrl = `${process.env.FRONT_END_APP_SERVER}${location.pathname
      .replace(/ /g, '%20')
      .replace('/summary', '/print/brochure')}?token=${stateToken}`
    try {
      await fetchBrochure(`${pdfUrl}`, isPortrait, property, setIsGenerating)
      // If we get to this point, we can expect the PDF was successfully downloaded.

      // TODO: Collect more client-side info once we have a privacy policy available to the
      // front end app users, and a way for them to explicitly agree to it.
      const result = await trackBrochureDownload({
        input: {
          brochureDownloadTrackingData: JSON.stringify({
            property,
            selectionState,
          }),
        },
      })
      console.log('trackBrochureDownload result:', result)
    } catch (error) {
      setIsGenerating(false)
      setFailedToGenerate(true)
    }
  }

  return (
    <div className={classes.button} style={{ position: 'relative' }}>
      <Button
        onClick={() => handleGeneratePdf()}
        style={{ width: 165, whiteSpace: 'nowrap' }}
        color="primary"
        variant="contained"
        disabled={isGenerating}
      >
        <Typography className={classes.buttonContent}>
          <PrintRoundedIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonText} variant="button">
            Download PDF
          </Typography>
        </Typography>
      </Button>
      {isGenerating && (
        <CircularProgress size={40} className={classesPdf.circularProgress} />
      )}
      <Snackbar
        open={failedToGenerate}
        setOpen={setFailedToGenerate}
        message="Failed to download PDF file."
        severity="error"
      />
    </div>
  )
}

PdfButtonOld.propTypes = {
  selection: PropTypes.object.isRequired,
  addSession: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  trackBrochureDownload: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  isPortrait: PropTypes.bool.isRequired,
}

const WrapperComponent = compose(
  withState('isPortrait', 'setIsPortrait', false),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withMutationAsProp({
    gqlDocument: TRACK_BROCHURE_DOWNLOAD,
    mutationPropName: 'trackBrochureDownload',
  }),
  withHandlers({
    handleIfPortrait: (props) => () => {
      const { property } = props
      const { buildings } = property
      let img
      let building

      for (let i = 0; i < buildings.length; i++) {
        if (props.isPortrait === true) break
        building = buildings[i]
        img = new Image()
        img.src = `https://res.cloudinary.com/renderinghouse/image/upload/q_auto/app/${property.account.cloud_folder_name}/images/${building.views[0].base_image_src}`
        // eslint-disable-next-line no-loop-func
        img.onload = () => {
          if (
            building.views[0].brochure_layout &&
            building.views[0].brochure_layout.name === 'digital_homescapes'
          ) {
            props.setIsPortrait(true)
          }
          if (img.naturalHeight >= img.naturalWidth) {
            props.setIsPortrait(true)
          }
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleIfPortrait, property } = this.props
      window.addEventListener('load', handleIfPortrait(property))
    },
    componentWillUnmount() {
      const { handleIfPortrait } = this.props
      window.removeEventListener('load', handleIfPortrait)
    },
  })
)(PdfButtonOld)

export { WrapperComponent as PdfButtonOld }
