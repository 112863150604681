import React from 'react'
import { HomePageButton } from './HomePageButton'
import { CartButton } from './CartButton'

const DesignPageButtonContainer = () => (
  <React.Fragment>
    <CartButton />
    <HomePageButton />
  </React.Fragment>
)

export default DesignPageButtonContainer
