import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { BannerConsumer } from '../../contexts'
import { PROPERTY_DETAILS_BUILDINGS_ROOMS } from '../../graphql/properties.graphql'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import BuildingCard from '../BuildingCard'
import RoomCard from '../RoomCard'

const styles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 40,
    },
  },
  viewModeSelectRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  viewModeButton: {
    width: 120,
  },
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: theme.palette.background.default,
  },
}))

const PropertyPage = ({ location, match, property, history }) => {
  if (property.is_active === false) {
    history.push('/inactiveproject')
  }

  const classes = styles()
  const { account } = property
  // Parse out token
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const tokenQueryParam = token ? `?token=${token}` : ''

  const handlePropertySelect = (building) => {
    const baseUrl = match.url
    history.push(`${baseUrl}/building/${building.id}${tokenQueryParam}`)
  }

  const handleRoomSelect = (room) => {
    const baseUrl = match.url
    history.push(`${baseUrl}/room/${room.id}${tokenQueryParam}`)
  }

  const sortedBuildings = useMemo(
    () =>
      property?.buildings.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.buildings]
  )

  const sortedRooms = useMemo(
    () => property?.rooms.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.rooms]
  )

  return (
    <React.Fragment>
      <div className={classes.headerText}>
        <Typography variant="h5">Select a Building/Room</Typography>
      </div>
      <div className={classes.cardContainer}>
        {sortedBuildings.map((building) => (
          <BuildingCard
            key={building.id}
            account={account}
            building={building}
            onSelect={handlePropertySelect}
          />
        ))}
        {sortedRooms.map((room) => (
          <RoomCard
            key={room.id}
            account={account}
            room={room}
            onSelect={handleRoomSelect}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

PropertyPage.propTypes = {
  banner: PropTypes.shape({
    setSubText: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    account: PropTypes.shape({
      id: PropTypes.number,
      cloud_folder_name: PropTypes.string,
    }).isRequired,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
          })
        ),
      })
    ),
    rooms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        image: PropTypes.string,
      })
    ),
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_BUILDINGS_ROOMS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    transformFunc: (property, data) => ({
      ...property,
      rooms: data.roomsByProjectId,
    }),
    resultPropName: 'property',
  }),
  lifecycle({
    componentDidMount() {
      // Update what the banner needs to render, rather than rendering it in this
      // page.
      this.props.banner.setSubText(
        `${this.props.property.name || ''} ${
          this.props.property.street_address
            ? `| ${this.props.property.street_address}`
            : ''
        }`
      )
    },
    componentWillUnmount() {
      this.props.banner.setSubText('')
    },
  }),
  withRouter
)(PropertyPage)

export { WrappedComponent as PropertyPage }
