import '../types'

export const ROOM_LAYER_TYPE = 'RoomLayer'
export const ROOM_COLOR_TYPE = 'RoomColor'

/**
 * @param {SchemeElement} element
 * @returns {element is Layer}
 */
export function isLayer(element) {
  if (element.__typename) {
    return element.__typename === ROOM_LAYER_TYPE
  }

  return !!element.id
}

/**
 * @param {SchemeElement} element
 * @returns {number}
 */
export function getElementId(element) {
  return isLayer(element) ? element.id : element.color.color_id
}

/**
 * @param {SchemeElement} el1
 * @param {SchemeElement} el2
 * @returns {true}
 */
export function elementsMatch(el1, el2) {
  if (isLayer(el1) !== isLayer(el2)) return false
  return getElementId(el1) === getElementId(el2)
}

/** @param {Color} color */
export function getColorIdentifier(color) {
  return `${color?.color?.color?.color_vendor_product_info?.product_identifier_prefix} ${color?.color?.color?.identifier}`
}

/** @param {Color} color */
export function getColorName(color) {
  return color?.name ?? color?.color?.name
}

/** @param {Layer} layer */
export function getSwatchIdentifier(layer) {
  const prefix = layer?.swatch?.vendor?.product_identifier_prefix
  const identifier = layer?.swatch?.identifier

  // NOTE: A swatch should always have both an associtated product line
  // and an identifier in that product line.
  // This is here for a demo and can be removed after.
  // TODO: Possibly remove the following line
  if (!prefix || !identifier) return layer?.name

  return `${prefix} ${identifier}`
}
