// import debug from 'debug'

// const getLogger = moduleName => ({
//   trace: debug(`color-visualizer:${moduleName}:trace`),
//   debug: debug(`color-visualizer:${moduleName}:debug`),
//   info: debug(`color-visualizer:${moduleName}:info`),
//   warn: debug(`color-visualizer:${moduleName}:warn`),
//   error: debug(`color-visualizer:${moduleName}:error`),
// })

const getLogger = () => ({
  trace: console.log,
  debug: console.log,
  info: console.log,
  warn: console.log,
  error: console.log,
})

export { getLogger }
