import React from 'react'
import PropTypes from 'prop-types'
import objectHash from 'object-hash'
import { compose } from 'recompose'
import { makeStyles, Typography } from '@material-ui/core'
import ExteriorImage from '@anewgo/exterior-image'
import { StoreConsumer } from '../../contexts'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import {
  createElevation,
  formatCustomMaterialsColors,
  getColorIdentier,
  getColorName,
  getMaterialName,
  islistOrderGreater,
} from '../../utils/helper'
import { PROPERTY_DETAILS_WITH_BUILDINGS } from '../../graphql/properties.graphql'
import { SHERWIN_WILLIAMS_LOGO } from '../constants/Paths'
import usePrintPageStyle from '../../hooks/usePrintPageStyle'

const PAGE_WIDTH = 756 // at 72 dpi 8 1/2"
const PAGE_HEIGHT = 996

const styles = makeStyles((theme) => ({
  portraitPage: {
    height: `${PAGE_HEIGHT}px`,
    width: `${PAGE_WIDTH}px`,
    display: 'flex',
    flexFlow: 'column',
    pageBreakAfter: 'always',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  disclaimerContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  disclaimerText: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
  materialListContainer: {
    display: 'flex',
    flexFlow: 'column',
    margin: 15,
    justifyContent: 'center',
  },
  materialListItem: {
    display: 'flex',
  },
  itemText: {
    marginRight: 10,
  },
  imageContainer: {
    position: 'relative',
    flex: 1,
    margin: 15,
  },
  fitImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}))

const HomescapesBrochurePage = ({ store, property }) => {
  const classes = styles()
  const { account, buildings, schemes } = property
  const { cart } = store.state

  usePrintPageStyle(true)

  return (
    <React.Fragment>
      {cart &&
        cart.map((cartItem) => {
          const { customSchemes, schemeName } = cartItem
          const building = buildings.find((item) => item.id === cartItem.viewId)
          const scheme = schemes.find((item) => item.id === cartItem.schemeId)
          const view = building.views[0]
          const elevation = createElevation(schemes, view)

          // Get custom materials colors for image to display
          // If custom colors are not present default colors will be used
          // given by selected scheme for this cart item
          const customMaterialsColors = formatCustomMaterialsColors(
            customSchemes?.[0]
          )

          return (
            <div key={objectHash(cartItem)} className={classes.portraitPage}>
              <div className={classes.header}>
                <img alt="Logo" src={SHERWIN_WILLIAMS_LOGO} height="30px" />
                <Typography variant="h5" style={{ marginLeft: 'auto' }}>
                  {schemeName}
                </Typography>
              </div>
              <div className={classes.imageContainer}>
                <div className={classes.fitImage}>
                  <ExteriorImage
                    engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
                    client={account.account_name}
                    directoryName={account.cloud_folder_name}
                    maxWidth={PAGE_WIDTH}
                    community={property.name}
                    plan={building.name}
                    elevation={elevation}
                    selectedScheme={scheme}
                    colorMtd="HYBRID"
                    colorSelections={customMaterialsColors}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
              </div>
              <div className={classes.materialListContainer}>
                {view.view_elements
                  .slice()
                  .sort(islistOrderGreater)
                  .map((element) => {
                    const schemeMaterial = scheme.scheme_elements.find(
                      (schemeElement) =>
                        schemeElement.material.id === element.material.id
                    )

                    // Get material customization for current material
                    // If found, material list will display this material with custom name and color
                    // If not found, default material name and color will be used
                    // given by selected scheme for this cart item
                    const customSchemeMaterial =
                      customSchemes?.[0]?.materials?.filter(
                        (material) =>
                          schemeMaterial?.material.id === material.materialId
                      )[0]

                    return (
                      <div
                        key={element.id}
                        className={classes.materialListItem}
                      >
                        <div style={{ width: '40%', marginLeft: '10%' }}>
                          <Typography variant="body1">
                            {getMaterialName(
                              element,
                              customSchemeMaterial,
                              schemeMaterial
                            )}
                          </Typography>
                        </div>
                        <div style={{ width: '50%', display: 'flex' }}>
                          <Typography
                            className={classes.itemText}
                            variant="body2"
                          >
                            {getColorIdentier(
                              customSchemeMaterial,
                              schemeMaterial
                            )}
                          </Typography>
                          <Typography
                            className={classes.itemText}
                            variant="body2"
                          >
                            {getColorName(customSchemeMaterial, schemeMaterial)}
                          </Typography>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className={classes.disclaimerContainer}>
                <Typography
                  style={{ fontWeight: 'bold', fontSize: 12 }}
                  variant="body1"
                >
                  {property && property.rep ? property.rep.full_name : ''}
                </Typography>
                <Typography className={classes.disclaimerText} variant="body2">
                  {property && property.rep
                    ? `${property.rep.phone} • ${property.rep.email}`
                    : ''}
                </Typography>
                <Typography className={classes.disclaimerText} variant="body2">
                  Actual color may vary from on-screen representation. To
                  confirm your color choices prior to purchase, please view a
                  physical color chip, color card, or painted sample.
                  Sherwin-Williams is not responsible for the content and photos
                  shared by users of their color selection tools. The Cover The
                  Earth logo and the Sherwin-Williams logo are trademarks owned
                  or licensed by The Sherwin-Williams Company. ©
                  {`${new Date().getFullYear()} `}
                  The Sherwin-Williams Company.
                </Typography>
              </div>
            </div>
          )
        })}
    </React.Fragment>
  )
}

HomescapesBrochurePage.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    account: PropTypes.shape({
      id: PropTypes.number,
      account_name: PropTypes.string,
      cloud_folder_name: PropTypes.string,
    }).isRequired,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
            view_elements: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                material: PropTypes.shape({
                  id: PropTypes.number,
                  element_id: PropTypes.number,
                  display_name: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ),
    schemes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        scheme_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            material: PropTypes.shape({
              id: PropTypes.number,
              display_name: PropTypes.string,
            }),
            color: PropTypes.shape({
              id: PropTypes.number,
              identifier: PropTypes.string,
              name: PropTypes.string,
              hex: PropTypes.string,
            }),
          })
        ),
      })
    ),
    rep: PropTypes.shape({
      full_name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(StoreConsumer, { propName: 'store' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  })
)(HomescapesBrochurePage)

export { WrappedComponent as HomescapesBrochurePage }
