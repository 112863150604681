import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import { fetchBrochure } from '../../utils/pdfHelper'
import {
  getSessionIdAndToken,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils'
import { BannerConsumer, StoreConsumer } from '../../contexts'
import { getSessionTokenFromUrl } from '../../utils/helper'
import { ADD_SESSION } from '../../graphql/sessions.graphql'
import { setSessionId, setToken } from '../../reducers/designCartActionCreators'
import Snackbar from '../Snackbar'
import { TRACK_CART_BROCHURE_DOWNLOAD } from '../../graphql/tracking.graphql'
import { PROPERTY_DETAILS_WITH_BUILDINGS } from '../../graphql/properties.graphql'

const styles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
})

const PdfButton = ({
  banner,
  location,
  store,
  addSession,
  trackCartBrochureDownload,
  property,
}) => {
  const classes = navButtonStyles()
  const classesPdf = styles()
  const [isGenerating, setIsGenerating] = useState(false)
  const [failedToGenerate, setFailedToGenerate] = useState(false)

  const handleGeneratePdf = async () => {
    if (isGenerating) return
    setIsGenerating(true)

    const selectionState = store.getState()
    if (!selectionState.sessionId) {
      const { sessionId, token: sessionToken } = await getSessionIdAndToken(
        selectionState,
        addSession
      )
      store.dispatch(setSessionId({ sessionId }))
      store.dispatch(setToken({ token: sessionToken }))
      window.history.pushState(null, '', `?token=${sessionToken}`)
    }

    if (!window.location.href.includes('token') && selectionState.token) {
      window.history.pushState(null, '', `?token=${selectionState.token}`)
    }

    const pdfUrl = `${process.env.FRONT_END_APP_SERVER}${location.pathname
      .replace(/ /g, '%20')
      .replace('cart', 'brochure')}${getSessionTokenFromUrl()}`

    try {
      await fetchBrochure(pdfUrl, true, banner.property, setIsGenerating)
      // If we get to this point, we can expect the PDF was successfully downloaded.

      // TODO: Collect more client-side info once we have a privacy policy available to the
      // front end app users, and a way for them to explicitly agree to it.
      await trackCartBrochureDownload({
        input: {
          cartBrochureDownloadTrackingData: JSON.stringify({
            property,
            sessionState: selectionState,
          }),
        },
      })
    } catch (error) {
      setIsGenerating(false)
      setFailedToGenerate(true)
    }
  }

  return (
    <div className={classes.button} style={{ position: 'relative' }}>
      <Button
        onClick={() => handleGeneratePdf()}
        style={{ width: 165, whiteSpace: 'nowrap' }}
        color="primary"
        variant="contained"
        disabled={isGenerating}
      >
        <Typography className={classes.buttonContent}>
          <PrintRoundedIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonText} variant="button">
            Download PDF
          </Typography>
        </Typography>
      </Button>
      {isGenerating && (
        <CircularProgress size={40} className={classesPdf.circularProgress} />
      )}
      <Snackbar
        open={failedToGenerate}
        setOpen={setFailedToGenerate}
        message="Failed to download PDF file."
        severity="error"
      />
    </div>
  )
}

PdfButton.propTypes = {
  addSession: PropTypes.func.isRequired,
  trackCartBrochureDownload: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  banner: PropTypes.shape({
    property: PropTypes.shape({
      name: PropTypes.string.isRequired,
      account: PropTypes.shape({
        account_name: PropTypes.string.isRequired,
        cloud_folder_name: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  store: PropTypes.shape({
    state: PropTypes.shape({
      sessionId: PropTypes.number,
    }),
    getState: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withConsumer(StoreConsumer, { propName: 'store' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withMutationAsProp({
    gqlDocument: TRACK_CART_BROCHURE_DOWNLOAD,
    mutationPropName: 'trackCartBrochureDownload',
  })
)(PdfButton)

export { WrappedComponent as PdfButton }
