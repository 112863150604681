import { useMemo } from 'react'
import { useSelection } from '../contexts'
import '../types'

/**
 * @param {Scheme[]} schemes
 * @param {number} propertyId
 * @param {number} roomId
 * @param {import('../contexts').SelectionState} selection
 * @returns {Scheme}
 */
export function useSelectedSchemeFromSelection(
  schemes,
  propertyId,
  roomId,
  selection
) {
  const { propertyRoomScheme } = selection

  return useMemo(() => {
    const currentSchemeId =
      propertyRoomScheme[propertyId]?.[roomId]?.currentSchemeId

    if (!currentSchemeId || !schemes) return null
    return schemes.find((scheme) => scheme.id === currentSchemeId)
  }, [schemes, propertyRoomScheme, propertyId, roomId])
}

/**
 * @param {Scheme[]} schemes
 * @param {number} propertyId
 * @param {number} roomId
 * @returns {Scheme}
 */
export function useSelectedScheme(schemes, propertyId, roomId) {
  return useSelectedSchemeFromSelection(
    schemes,
    propertyId,
    roomId,
    useSelection()
  )
}

/**
 * @param {Scheme} scheme
 * @param {number} propertyId
 * @param {number} roomId
 * @param {import('../contexts').SelectionState} selection
 * @returns {Scheme}
 */
export function useSchemeWithOverridesFromSelection(
  scheme,
  propertyId,
  roomId,
  selection
) {
  const { propertyRoomScheme } = selection

  const schemeOverrides = useMemo(() => {
    const { schemeId, ...overrides } =
      propertyRoomScheme[propertyId]?.[roomId]?.schemeMaskElement?.[
        scheme?.id
      ] ?? {}

    return new Map(
      Object.values(overrides).map(({ maskId, element }) => [maskId, element])
    )
  }, [propertyRoomScheme, scheme, propertyId, roomId])

  return useMemo(() => {
    if (!scheme || schemeOverrides.size === 0) return scheme

    return {
      ...scheme,
      elements: scheme.elements.map((element) => {
        const overrideValue = schemeOverrides.get(element.mask.id)
        return overrideValue ?? element
      }),
    }
  }, [scheme, schemeOverrides])
}

/**
 * @param {Scheme} scheme
 * @param {number} propertyId
 * @param {number} roomId
 * @returns {Scheme}
 */
export function useSchemeWithOverrides(scheme, propertyId, roomId) {
  return useSchemeWithOverridesFromSelection(
    scheme,
    propertyId,
    roomId,
    useSelection()
  )
}
