import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer } from 'react-virtualized'
import ExteriorImage from '@anewgo/exterior-image'
import { Link } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { chunk } from 'lodash'
import {
  createElevation,
  getCustomColorsForSelectedScheme,
  getCustomSchemeFromSelection,
  groupMaterialsByColorForNonCart,
} from '../../../../utils/helper'
import { BASE_IMAGE_URL } from '../../../constants/Paths'
import MaterialCard from '../../../MaterialCard'
import { materialCustomSorter } from '../../../../utils/materialCustomSorter'

const styles = makeStyles({
  page: {
    marginBottom: 30,
    backgroundColor: 'white',
    border: '3px solid whitesmoke',
    borderRadius: '10px',
    boxSizing: 'border-box',
    marginLeft: '5px',
    marginTop: '5px',
    paddingBottom: '5px',
    height: 'calc(var(--vh, 1vh) * 88)',
    width: '98vw',
    display: 'grid',
    gridGap: '25px',
    gridTemplateRows: '.5fr 6fr 2fr .5fr',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateAreas:
      '"header header header"' +
      '"photo photo sider2"' +
      '"photo photo sider2"' +
      '"photo photo buttonArea"',
    pageBreakAfter: 'always',
    '@media (max-width: 1000px)': {
      gridTemplateAreas: '"header" "photo" "sider2" "buttonArea"',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '.5fr 4fr 2fr .5fr',
      gridGap: '10px',
      width: '96vw',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        gridTemplateColumns: '1fr 2fr 2fr',
        gridTemplateAreas:
          '"header header header"' +
          '"photo photo sider2"' +
          '"photo photo sider2"' +
          '"photo photo buttonArea"',
        minHeight: '78vh',
        maxHeight: '78vh',
        width: '98vw',
      },
    '@media screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait)':
      {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '.5fr 4fr 2fr .5fr',
        gridTemplateAreas: '"header" "photo" "sider2" "buttonArea"',
        maxHeight: 'calc(var(--vh, 1vh) * 90)',
      },
  },
  header: {
    display: 'flex',
    gridArea: 'header',
    gridTemplateColumns: '1fr 1fr',
    fontSize: '25px',
    '@media (max-width: 850px)': {
      fontSize: '25px',
    },
    '@media (max-width: 650px)': {
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '15px',
      },
  },
  headerLeft: {
    marginLeft: '10vw',
    '@media (max-width: 850px)': {
      marginLeft: '10px',
    },
    '@media (max-width: 650px)': {
      marginLeft: '5px',
    },
  },
  schemeName: {
    marginLeft: '3vw',
  },
  selectionsContent: {
    justifyContent: 'center',
    gridArea: 'sider2',
    width: '100%',
    maxWidth: 'initial',
    alignSelf: 'center',
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    '@media (max-width: 1000px)': {
      display: 'flex',
      flexFlow: 'wrap',
      paddingRight: '0px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '1px',
      },
  },
  photoContent: {
    gridArea: 'photo',
  },
  thumbnailImage: {
    gridArea: 'photo',
    justifySelf: 'center',
  },
  headerButtonIcon: {
    marginTop: '0px',
    fontSize: '25px',
    color: 'white',
  },
  customizeArea: {
    gridArea: 'buttonArea',
    boxSizing: 'border-box',
  },
  customizeButton: {
    minHeight: '70px',
    maxHeight: '70px',
    width: '40%',
    minWidth: '40%',
    marginLeft: '55%',
    fontSize: '12px',
    color: '#fff !important',
    textTransform: 'uppercase',
    background: '#0069af !important',
    borderRadius: '15px',
    border: 'none',
    'letter-spacing': '2px',
    ':hover': {
      background: '#369 !important',
      'letter-spacing': '4px',
      '-webkit-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      '-moz-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      'box-shadow': '5px 40px -10px rgba(0,0,0,0.57)',
      transition: 'all 0.4s ease 0s',
      '@media screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          'letter-spacing': '2px',
        },
    },
    ':disabled': {
      background: '#F2F0F0 !important',
      border: '1px #D6D8DB solid',
      color: '#D6D8DB !important',
    },
    '@media (max-width: 1000px)': {
      minHeight: '50px',
      maxHeight: '50px',
      width: '25%',
      minWidth: '25%',
      marginLeft: '75%',
    },
  },
})

const DefaultView = ({ property, selection, location, building, index }) => {
  const classes = styles()
  const { schemes } = property

  const selectedScheme = getCustomSchemeFromSelection(
    building,
    property,
    selection
  )

  const customColorsForSelectedScheme = getCustomColorsForSelectedScheme(
    building,
    property,
    selection,
    selectedScheme
  )

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        {`${building.name}`}
        {'  '}
        <div className={classes.schemeName}>
          {' '}
          {selectedScheme !== undefined
            ? selectedScheme.name
            : 'No Scheme Selected'}
        </div>
      </div>
    </div>
  )

  const hemiRenderedSummaryPage = () => {
    const { account } = property
    const { views } = building
    const elevation = createElevation(schemes, views[0])
    const thumbnailImage = views[0].thumbnail_src

    return (
      <AutoSizer>
        {({ height, width }) => {
          if (width === 0) {
            return null
          }
          if (!selectedScheme) {
            return (
              <img
                style={{ width, height, objectFit: 'contain' }}
                alt=""
                src={`${BASE_IMAGE_URL}/q_auto/app/${account.cloud_folder_name}/images/${thumbnailImage}`}
              />
            )
          }
          return (
            <ExteriorImage
              engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
              client={account.account_name}
              width={width}
              height={height}
              maxWidth={width}
              rootStyle={{ height }}
              community={property.name}
              plan={building.name}
              elevation={elevation}
              selectedScheme={selectedScheme}
              colorMtd="HYBRID"
              directoryName={account.cloud_folder_name}
              colorSelections={
                customColorsForSelectedScheme
                  ? Object.assign(
                      {},
                      ...Object.entries(customColorsForSelectedScheme)
                        .filter((key) => !Number.isNaN(parseInt(key, 10)))
                        .map(([, value]) => ({
                          [value.materialElementId]: {
                            paletteId: value.materialPaletteId,
                            id: value.id,
                          },
                        }))
                    )
                  : {}
              }
            />
          )
        }}
      </AutoSizer>
    )
  }

  const renderSchemes = () => {
    if (selectedScheme && selectedScheme.scheme_elements) {
      const { colorMaterialIdsMap: groupedMaterials } =
        groupMaterialsByColorForNonCart(
          building,
          selectedScheme,
          customColorsForSelectedScheme
        )
      return Object.keys(groupedMaterials).map((colorId) => {
        const colorObject = groupedMaterials[colorId][0]
        const materialList = chunk(groupedMaterials[colorId].slice(1), 5)
        return materialList.map((materials) => (
          <MaterialCard
            key={`${groupedMaterials}-${colorId}-${materials}`}
            colorObject={colorObject}
            materials={materialCustomSorter(materials, 'name').map(
              (material) => material.name
            )}
            style={{ width: '100%', margin: 0 }}
            group
          />
        ))
      })
    }
    return null
  }

  const renderBrochure = () => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const tokenQueryParam = token ? `?token=${token}` : ''
    const propertyUrl = location.pathname.replace('/summary', '')
    return (
      <React.Fragment>
        <div
          className={classes.page}
          key={`${building.name}${[index]}`}
          id={`${building.name}${[index]}`}
        >
          {renderHeader(selectedScheme, building, index, location)}
          <div className={classes.photoContent}>
            {hemiRenderedSummaryPage(
              property,
              selectedScheme,
              selection,
              building
            )}
          </div>
          <div className={classes.selectionsContent}>{renderSchemes()}</div>
          <div className={classes.customizeArea}>
            <Link
              to={`${propertyUrl}/building/${building.id}${tokenQueryParam}`}
            >
              <Button
                className={classes.customizeButton}
                disabled={property.is_finalized}
              >
                <EditIcon className={classes.headerButtonIcon} />
              </Button>
            </Link>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return <React.Fragment>{renderBrochure()}</React.Fragment>
}

DefaultView.propTypes = {
  property: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default DefaultView
