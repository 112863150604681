import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Logo from './Logo'
import MaterialCard from '../../../floorCovering/MaterialCard'
import RoomImage from '../../../floorCovering/RoomImage'
import usePrintPageStyle from '../../../../hooks/usePrintPageStyle'
import {
  useSchemeWithOverridesFromSelection,
  useSelectedSchemeFromSelection,
} from '../../../../hooks/selectionHooks'
import { getRoomCloudinaryPath } from '../../../../utils/cloudinaryPaths'
import { getElementId } from '../../../../utils'
import '../../../../types'
import MaterialList from '../../../floorCovering/MaterialList'
import ListNumber from '../../../floorCovering/ListNumber'
import { materialCustomSorter } from '../../../../utils/materialCustomSorter'

const PAGE_WIDTH = 756 // at 72 dpi 8 1/2"
const PAGE_HEIGHT = 996

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    pageBreakAfter: 'always',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  portraitPage: {
    width: `${PAGE_WIDTH}px`, // at 72dpi
    height: `${PAGE_HEIGHT}px`,
  },
  landscapePage: {
    width: `${PAGE_HEIGHT}px`, // at 72dpi
    height: `${PAGE_WIDTH}px`,
  },
  header: {
    gridRow: '1',
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  headerLeft: {
    gridColumn: '1',
  },
  headerRight: {
    gridColumn: '2',
    fontSize: '12px',
  },
  schemeName: {
    float: 'right',
    paddingLeft: '25px',
  },
  roomName: {
    float: 'right',
    fontSize: '12px',
    borderRight: '1px solid black',
    paddingRight: '15px',
  },
  coverPageContent: {
    flex: '1',
    width: '100%',
    position: 'relative',
    margin: '20px 0',
  },
  exteriorImg: {
    flex: '1',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  insetPhoto: {
    position: 'absolute',
    bottom: '-10px',
    right: 0,
    border: 'solid 3px white',
    borderRadius: '5px',
    maxHeight: '150px',
    minHeight: '90px',
  },
  schemeFooter: {
    display: 'grid',
    width: '99%',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr .2fr',
    minHeight: 180,
  },
  schemeFooterBottomRow: {
    fontWeight: 'bold',
    textAlign: 'center',
    gridRow: '2',
    lineHeight: 0,
  },
  materialCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  materialIndexes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

/**
 * @typedef RoomPageHeaderProps
 * @prop {string=} roomName
 * @prop {string=} schemeName
 */

/**
 * @param {RoomPageHeaderProps} props
 */
function RoomPageHeader({ roomName, schemeName }) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <Logo size="small" />
      </div>
      <div className={classes.headerRight}>
        <div>
          {schemeName ? (
            <div className={classes.schemeName}>{schemeName}</div>
          ) : (
            <div className={classes.schemeName}>No Scheme Selected</div>
          )}
          <div className={classes.roomName}>{roomName}</div>
        </div>
      </div>
    </div>
  )
}

RoomPageHeader.propTypes = {
  roomName: PropTypes.string,
  schemeName: PropTypes.string,
}

RoomPageHeader.defaultProps = {
  roomName: 'Room',
  schemeName: null,
}

/**
 * @typedef RoomSchemeDisplayProps
 * @prop {Scheme} scheme
 */

/**
 * @param {RoomSchemeDisplayProps} props
 */
function RoomSchemeDisplay({ elements, groupedElements }) {
  if (!elements) return null

  const classes = useStyles()

  const sortedGroupedElements = Object.values(groupedElements).sort((a, b) => {
    if (a.indexes.length && b.indexes.length) {
      const aMinIndex = Math.min(a.indexes)
      const bMinIndex = Math.min(b.indexes)

      return aMinIndex < bMinIndex ? -1 : 1
    }

    return 0
  })

  return (
    <>
      <MaterialList
        elements={elements.map((element) => ({
          ...element,
          name: element.mask.material.display_name,
        }))}
      />
      <div className={classes.materialCards}>
        {sortedGroupedElements.map((element) => (
          <div key={getElementId(element)}>
            <MaterialCard element={element} disableScaling hideMaskName>
              <div className={classes.materialIndexes}>
                {element.indexes.map((index) => (
                  <ListNumber key={index} number={index} />
                ))}
              </div>
            </MaterialCard>
          </div>
        ))}
      </div>
    </>
  )
}

RoomSchemeDisplay.propTypes = {
  elements: PropTypes.array.isRequired,
  groupedElements: PropTypes.array,
}

RoomSchemeDisplay.defaultProps = {
  groupedElements: null,
}

/**
 * @typedef RoomDisclaimerProps
 * @prop {Property} property
 */

/**
 * @param {RoomDisclaimerProps} props
 */
function RoomDisclaimer({ property }) {
  return (
    <Typography variant="caption">
      <b>{property?.rep?.full_name ?? ''}</b>
      <br />
      <b>
        {property?.rep ? `${property.rep.phone} • ${property.rep.email}` : ''}
      </b>
      <br />
      {`The digitized image(s) shown approximate actual paint colors as closely as possible.
        Colors may vary due to viewing equipment, lighting conditions and printers. The Cover
        The Earth logo and the Sherwin-Williams logo are trademarks owned or licensed by
        The Sherwin-Williams Company.  © ${new Date().getFullYear()} The Sherwin-Williams Company.`}
      {property?.project_id ? `• ${property.project_id}` : ''}
    </Typography>
  )
}

RoomDisclaimer.propTypes = {
  property: PropTypes.object,
}

RoomDisclaimer.defaultProps = {
  property: null,
}

/**
 * @typedef RoomPageProps
 * @prop {Property} property
 * @prop {RoomWithSchemes} room
 * @prop {import('../../../../contexts').ISelectionContext} selection
 * @prop {boolean} portrait
 */

/**
 * @param {RoomPageProps} props
 */
export default function RoomPage({ property, room, selection, portrait }) {
  usePrintPageStyle(portrait)
  const classes = useStyles()

  const selectedScheme = useSelectedSchemeFromSelection(
    room.schemes,
    property.id,
    room.id,
    selection
  )

  const groupedElements = {}

  /*
    Here we have two similar variables: "actualScheme" and "actualImageScheme".
    Difference:
      - "actualScheme" is being manipulated and sorted in a way that
        materials renders in correct order in brochure, with correct numbering.
      - "actualImageScheme" is used for rendering room image. Brim is sensitive
        to order of materials. Incorrect order leads to incorrectly rendered image.
        Because of that we don't want to manipulate this scheme in any way.
   */
  let actualScheme = useSchemeWithOverridesFromSelection(
    selectedScheme,
    property.id,
    room.id,
    selection
  )

  const actualImageScheme = useSchemeWithOverridesFromSelection(
    selectedScheme,
    property.id,
    room.id,
    selection
  )

  if (actualScheme?.elements) {
    actualScheme = {
      ...actualScheme,
      elements: materialCustomSorter(
        actualScheme.elements,
        'mask.material.display_name'
      ).map((element, index) => {
        const pointNumber = index + 1
        const key =
          // eslint-disable-next-line no-nested-ternary
          element.__typename === 'RoomLayer'
            ? `${element.__typename}-${element.swatch.id}`
            : element.__typename === 'RoomColor'
            ? `${element.__typename}-${element.color.id}`
            : null

        if (groupedElements[key]) {
          groupedElements[key].indexes.push(pointNumber)
        } else {
          groupedElements[key] = {
            ...element,
            indexes: [pointNumber],
          }
        }

        return { ...element, pointNumber }
      }),
    }
  }

  return (
    <div
      className={`${classes.page} ${
        portrait ? classes.portraitPage : classes.landscapePage
      }`}
    >
      <RoomPageHeader roomName={room.name} schemeName={selectedScheme?.name} />
      <div className={classes.coverPageContent}>
        <div className={classes.exteriorImg}>
          <RoomImage
            scheme={actualImageScheme}
            room={room}
            account={property.account}
          />
          <img
            id={`insetRoomImage${room.id}`}
            className={classes.insetPhoto}
            alt={room.name}
            onLoad={({ target }) => {
              target.style.height = `${Math.floor(target.naturalHeight / 5)}px`
            }}
            src={getRoomCloudinaryPath(
              property.account.cloud_folder_name,
              room.image,
              'q_auto'
            )}
          />
        </div>
      </div>
      <div className={classes.schemeFooter}>
        <div>
          <RoomSchemeDisplay
            elements={actualScheme?.elements}
            groupedElements={groupedElements}
          />
        </div>
        <div className={classes.schemeFooterBottomRow}>
          <RoomDisclaimer property={property} />
        </div>
      </div>
    </div>
  )
}

RoomPage.propTypes = {
  property: PropTypes.object,
  room: PropTypes.object,
  selection: PropTypes.object,
  portrait: PropTypes.bool,
}

RoomPage.defaultProps = {
  property: null,
  room: null,
  selection: null,
  portrait: true,
}
