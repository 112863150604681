import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import PropertyCard, { CARD_THUMBNAIL_SIZE } from './PropertyCard'
import { getRoomCloudinaryPath } from '../utils/cloudinaryPaths'

export default function RoomCard({ room, account, property, onSelect }) {
  const imageSrc = getRoomCloudinaryPath(
    account.cloud_folder_name,
    room.image,
    `w_${CARD_THUMBNAIL_SIZE},h_${CARD_THUMBNAIL_SIZE},c_fit`
  )

  return (
    <PropertyCard
      title={room?.name ?? property?.name ?? account.name}
      thumbnailSrc={imageSrc}
      onClick={() => onSelect(room || property || account)}
      content={(classes) => (
        <>
          {room?.name && (
            <Typography variant="h5" className={classes.contentText}>
              {room?.name}
            </Typography>
          )}
          {property?.name && (
            <Typography variant="h5" className={classes.contentText}>
              {property?.name}
            </Typography>
          )}
          {property?.street_address && (
            <Typography variant="body1" className={classes.contentTextBody}>
              {property?.street_address}
            </Typography>
          )}
          {!room && !property && (
            <Typography variant="h5" className={classes.contentText}>
              {account?.account_name}
            </Typography>
          )}
        </>
      )}
    />
  )
}

RoomCard.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  property: PropTypes.shape({
    name: PropTypes.string,
    street_address: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    account_name: PropTypes.string,
    cloud_folder_name: PropTypes.string,
  }).isRequired,
}

RoomCard.defaultProps = {
  room: null,
  property: null,
}
