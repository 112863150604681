import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import ImageSkeleton from '../../../ImageSkeleton'
import { getSwatchCloudinaryPath } from '../../../../utils/cloudinaryPaths'
import {
  getSwatchIdentifier,
  getColorIdentifier,
  getColorName,
  isLayer,
} from '../../../../utils/roomLayer'
import '../../../../types'

const useStyles = makeStyles({
  materialCard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
    border: '1px solid #cccccc',
    borderRadius: 3,
    padding: 16,
  },
  materialName: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  materialTextContainer: {
    flex: '1 1 50%',
  },
  materialImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  materialButtonContainer: {
    flex: '0 0 100%',
  },
  materialImage: {
    width: '90px !important',
    height: '90px !important',
  },
})

/**
 * @typedef BaseOriginalPaletteCardProps
 * @prop {string=} maskName
 * @prop {string=} identifier
 * @prop {string=} name
 * @prop {React.ReactNode=} imageElement
 * @prop {string=} buttonText
 * @prop {() => void} onClick
 */

/**
 * @param {BaseOriginalPaletteCardProps} props
 */
function BaseOriginalPaletteCard({
  maskName,
  identifier,
  name,
  imageElement,
  buttonText,
  onClick,
}) {
  const classes = useStyles()

  return (
    <div className={classes.materialCard}>
      <div className={classes.materialTextContainer}>
        <Typography variant="h6" className={classes.materialName}>
          {maskName}
        </Typography>
        <Typography variant="body2">{identifier}</Typography>
        <Typography variant="body2">{name}</Typography>
      </div>

      <div className={classes.materialImageContainer}>{imageElement}</div>

      <div className={classes.materialButtonContainer}>
        <Button variant="outlined" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

BaseOriginalPaletteCard.propTypes = {
  maskName: PropTypes.string,
  identifier: PropTypes.string,
  name: PropTypes.string,
  imageElement: PropTypes.node,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}

BaseOriginalPaletteCard.defaultProps = {
  maskName: null,
  identifier: null,
  name: null,
  imageElement: null,
  buttonText: null,
  onClick: null,
}

/**
 * @typedef OriginalLayerPaletteCardProps
 * @prop {Mask=} mask
 * @prop {Layer=} layer
 * @prop {() => void} onClick
 */

/**
 * @param {OriginalLayerPaletteCardProps} props
 */
function OriginalLayerPaletteCard({ mask, layer, onClick }) {
  const classes = useStyles()
  const swatchUrl = getSwatchCloudinaryPath(
    layer?.swatch?.image ?? '#',
    'w_90,h_90,c_fill'
  )

  return (
    <BaseOriginalPaletteCard
      maskName={mask?.material?.display_name}
      identifier={getSwatchIdentifier(layer)}
      name={layer?.swatch?.name}
      buttonText="Use Original Floor Covering"
      imageElement={
        <div className={classes.materialImageContainer}>
          <ImageSkeleton
            src={swatchUrl}
            alt={layer?.swatch?.name}
            className={classes.materialImage}
          />
        </div>
      }
      onClick={onClick}
    />
  )
}

OriginalLayerPaletteCard.propTypes = {
  mask: PropTypes.object,
  layer: PropTypes.object,
  onClick: PropTypes.func,
}

OriginalLayerPaletteCard.defaultProps = {
  mask: null,
  layer: null,
  onClick: null,
}

/**
 * @typedef OriginalColorPaletteCardProps
 * @prop {Color=} color
 * @prop {Mask=} mask
 * @prop {() => void} onClick
 */

/**
 * @param {OriginalColorPaletteCardProps} props
 */
function OriginalColorPaletteCard({ mask, color, onClick }) {
  const classes = useStyles()

  return (
    <BaseOriginalPaletteCard
      maskName={mask?.material?.display_name}
      identifier={getColorIdentifier(color)}
      name={getColorName(color)}
      buttonText="Use Original Color"
      imageElement={
        <div className={classes.materialImageContainer}>
          <div
            className={classes.materialImage}
            style={{ backgroundColor: `#${color?.hex ?? color?.color?.hex}` }}
          />
        </div>
      }
      onClick={onClick}
    />
  )
}

OriginalColorPaletteCard.propTypes = {
  mask: PropTypes.object,
  color: PropTypes.object,
  onClick: PropTypes.func,
}

OriginalColorPaletteCard.defaultProps = {
  mask: null,
  color: null,
  onClick: null,
}

/**
 * @typedef OriginalPaletteCardProps
 * @prop {Mask=} mask
 * @prop {SchemeElement=} element
 * @prop {() => void} onClick
 */

/**
 * @param {OriginalPaletteCardProps} props
 */
function OriginalPaletteCard({ element, ...props }) {
  return isLayer(element) ? (
    <OriginalLayerPaletteCard layer={element} {...props} />
  ) : (
    <OriginalColorPaletteCard color={element} {...props} />
  )
}

OriginalPaletteCard.propTypes = {
  element: PropTypes.object,
}

OriginalPaletteCard.defaultProps = {
  element: null,
}

OriginalPaletteCard.Layer = OriginalLayerPaletteCard
OriginalPaletteCard.Color = OriginalColorPaletteCard
export default OriginalPaletteCard
