import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { Link, useLocation } from 'react-router-dom'
import {
  useSchemeWithOverrides,
  useSelectedScheme,
} from '../../../../hooks/selectionHooks'
import MaterialCard from '../../../floorCovering/MaterialCard'
import RoomImage from '../../../floorCovering/RoomImage'
import '../../../../types'
import { materialCustomSorter } from '../../../../utils/materialCustomSorter'

const useStyles = makeStyles({
  page: {
    marginBottom: 30,
    backgroundColor: 'white',
    border: '3px solid whitesmoke',
    borderRadius: '10px',
    boxSizing: 'border-box',
    marginLeft: '5px',
    marginTop: '5px',
    paddingBottom: '5px',
    height: '88vh',
    width: '98vw',
    display: 'grid',
    gridGap: '25px',
    gridTemplateRows: '.5fr 6fr 2fr .5fr',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateAreas:
      '"header header header"' +
      '"photo photo sider2"' +
      '"photo photo sider2"' +
      '"photo photo buttonArea"',
    pageBreakAfter: 'always',
    '@media (max-width: 1000px)': {
      gridTemplateAreas: '"header" "photo" "sider2" "buttonArea"',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '.5fr 4fr 2fr .5fr',
      gridGap: '10px',
      width: '96vw',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        gridTemplateColumns: '1fr 2fr 2fr',
        gridTemplateAreas:
          '"header header header"' +
          '"photo photo sider2"' +
          '"photo photo sider2"' +
          '"photo photo buttonArea"',
        minHeight: '78vh',
        maxHeight: '78vh',
        width: '98vw',
      },
    '@media screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait)':
      {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '.5fr 4fr 2fr .5fr',
        gridTemplateAreas: '"header" "photo" "sider2" "buttonArea"',
        maxHeight: '90vh',
      },
  },
  header: {
    display: 'flex',
    gridArea: 'header',
    gridTemplateColumns: '1fr 1fr',
    fontSize: '25px',
    '@media (max-width: 850px)': {
      fontSize: '25px',
    },
    '@media (max-width: 650px)': {
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '15px',
      },
  },
  headerLeft: {
    marginLeft: '10vw',
    '@media (max-width: 850px)': {
      marginLeft: '10px',
    },
    '@media (max-width: 650px)': {
      marginLeft: '5px',
    },
  },
  schemeName: {
    marginLeft: '3vw',
  },
  selectionsContent: {
    justifyContent: 'center',
    gridArea: 'sider2',
    width: '100%',
    maxWidth: 'initial',
    alignSelf: 'center',
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    '@media (max-width: 1000px)': {
      display: 'flex',
      flexFlow: 'wrap',
      paddingRight: '0px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '1px',
      },
  },
  photoContent: {
    gridArea: 'photo',
  },
  thumbnailImage: {
    gridArea: 'photo',
    justifySelf: 'center',
  },
  headerButtonIcon: {
    marginTop: '0px',
    fontSize: '25px',
    color: 'white',
  },
  customizeArea: {
    gridArea: 'buttonArea',
    boxSizing: 'border-box',
  },
  customizeButton: {
    minHeight: '70px',
    maxHeight: '70px',
    width: '40%',
    minWidth: '40%',
    marginLeft: '55%',
    fontSize: '12px',
    color: '#fff !important',
    textTransform: 'uppercase',
    background: '#0069af !important',
    borderRadius: '15px',
    border: 'none',
    'letter-spacing': '2px',
    ':hover': {
      background: '#369 !important',
      'letter-spacing': '4px',
      '-webkit-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      '-moz-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      'box-shadow': '5px 40px -10px rgba(0,0,0,0.57)',
      transition: 'all 0.4s ease 0s',
      '@media screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          'letter-spacing': '2px',
        },
    },
    ':disabled': {
      background: '#F2F0F0 !important',
      border: '1px #D6D8DB solid',
      color: '#D6D8DB !important',
    },
    '@media (max-width: 1000px)': {
      minHeight: '50px',
      maxHeight: '50px',
      width: '25%',
      minWidth: '25%',
      marginLeft: '75%',
    },
  },
})

/**
 * @typedef RoomViewHeaderProps
 * @prop {Room} room
 * @prop {Scheme} scheme
 */

/**
 *
 * @param {RoomViewHeaderProps} props
 */
function RoomViewHeader({ room, scheme }) {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        {room.name} &nbsp;
        <div className={classes.schemeName}>
          &nbsp;
          {scheme?.name ?? 'No Scheme Selected'}
        </div>
      </div>
    </div>
  )
}

RoomViewHeader.propTypes = {
  room: PropTypes.object.isRequired,
  scheme: PropTypes.object.isRequired,
}

RoomViewHeader.defaultProps = {}

/**
 * @typedef RoomViewProps
 * @prop {Account=} account
 * @prop {Property} property
 * @prop {RoomWithSchemes} room
 * @prop {number} index
 */

/**
 * @param {RoomViewProps} props
 */
export default function RoomView({ account, property, room }) {
  const classes = useStyles()

  const originalScheme = useSelectedScheme(room.schemes, property.id, room.id)
  const actualScheme = useSchemeWithOverrides(
    originalScheme,
    property.id,
    room.id
  )

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const tokenQueryParam = token ? `?token=${token}` : ''
  const propertyUrl = location.pathname.replace('/summary', '')

  return (
    <div className={classes.page}>
      <RoomViewHeader room={room} scheme={originalScheme} />
      <div className={classes.photoContent}>
        {account && (
          <RoomImage account={account} room={room} scheme={actualScheme} />
        )}
      </div>
      <div className={classes.selectionsContent}>
        {actualScheme?.elements &&
          materialCustomSorter(
            actualScheme?.elements,
            'mask.material.display_name'
          ).map((element) => {
            return <MaterialCard key={element.id} element={element} />
          })}
      </div>
      <div className={classes.customizeArea}>
        <Link to={`${propertyUrl}/room/${room.id}${tokenQueryParam}`}>
          <Button
            className={classes.customizeButton}
            disabled={property.is_finalized}
          >
            <EditIcon className={classes.headerButtonIcon} />
          </Button>
        </Link>
      </div>
    </div>
  )
}

RoomView.propTypes = {
  account: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
}

RoomView.defaultProps = {}
