import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import React from 'react'

const SaveLinkNotification = ({
  reminder,
  setReminder,
  setIsLinkCopiedOrDismissed,
}) => (
  <Dialog open={reminder} onClose={() => setReminder(false)}>
    <DialogTitle>Reminder: Save your changes</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please click on Create Save Link and save your changes by saving the
        link.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setReminder(false)}
      >
        Snooze
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setIsLinkCopiedOrDismissed(true)}
      >
        Don&apos;t remind me again
      </Button>
    </DialogActions>
  </Dialog>
)

SaveLinkNotification.propTypes = {
  reminder: PropTypes.bool.isRequired,
  setReminder: PropTypes.func.isRequired,
  setIsLinkCopiedOrDismissed: PropTypes.func.isRequired,
}

export default SaveLinkNotification
