import { compose, lifecycle, withHandlers } from 'recompose'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { SelectionConsumer } from '../../contexts'
import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
  getSessionIdAndToken,
} from '../../utils'
import {
  PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS,
  FINALIZE_PROPERTY_BY_ID,
} from '../../graphql/properties.graphql'
import { ADD_SESSION } from '../../graphql/sessions.graphql'
import { navButtonStyles } from '../../stylesheets/navButton.style'
import Snackbar from '../Snackbar'
import { fetchBrochureURL } from '../../utils/pdfHelper'

const FinalizePropertyButton = ({
  property,
  history,
  location,
  selection,
  addSession,
  finalizePropertyById,
  isPortrait,
}) => {
  const classes = navButtonStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [openFailToFinalize, setOpenFailToFinalize] = useState(false)
  const [isGenerating, setisGenerating] = useState(false)
  const handleFinalize = async (event) => {
    setOpenDialog(false)
    event.preventDefault()
    const sessionState = selection.getState()
    const { sessionId, token: sessionToken } = await getSessionIdAndToken(
      sessionState,
      addSession
    )
    // // For some reason, React Router converts the URL encoding for %20
    // // back into a space. When constructing a URL from the React Router
    // // Change it back to a "%20".
    const finalizedURL = `${
      process.env.FRONT_END_APP_SERVER
    }${location.pathname.replace(/ /g, '%20')}?token=${sessionToken}`

    const pdfUrl = `${process.env.FRONT_END_APP_SERVER}${location.pathname
      .replace(/ /g, '%20')
      .replace('/summary', '/print/brochure')}?token=${sessionToken}`

    // get brochure URL from inglenook
    let brochureS3URL
    try {
      brochureS3URL = await fetchBrochureURL(
        `${pdfUrl}`,
        isPortrait,
        property,
        setisGenerating
      )
    } catch (error) {
      setOpenFailToFinalize(true)
      return
    }

    // finalize property and send an email with cv link and brochure link
    try {
      await finalizePropertyById({
        id: property.id,
        sessionId,
        finalizedURL,
        brochureURL: encodeURI(brochureS3URL),
      })
      history.push('/finalizeproject')
    } catch (error) {
      setOpenFailToFinalize(true)
    }
  }

  return (
    <div className={classes.button}>
      <Button
        onClick={() => setOpenDialog(true)}
        disabled={isGenerating || property.is_finalized}
        style={{ width: 165, whiteSpace: 'nowrap' }}
        color="primary"
        variant="contained"
      >
        <Typography className={classes.buttonContent}>
          <DoneIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonText} variant="button">
            Complete Project
          </Typography>
        </Typography>
        {isGenerating && (
          <CircularProgress size={40} className={classes.circularProgress} />
        )}
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <form onSubmit={handleFinalize}>
          <DialogTitle>
            This will send your selections to your representative. Ready to
            proceed?
          </DialogTitle>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setOpenDialog(false)}
              variant="outlined"
            >
              No
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={openFailToFinalize}
        setOpen={setOpenFailToFinalize}
        message="Failed to finalize project."
        severity="error"
      />
    </div>
  )
}

FinalizePropertyButton.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_finalized: PropTypes.bool.isRequired,
    account: PropTypes.shape({
      account_name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    paint_guide_url: PropTypes.string,
    building: PropTypes.object,
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selection: PropTypes.shape({
    getState: PropTypes.func.isRequired,
  }).isRequired,
  addSession: PropTypes.func.isRequired,
  finalizePropertyById: PropTypes.func.isRequired,
  isPortrait: PropTypes.bool.isRequired,
}

// This outer component requires propertyId to be set.
const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS,
    variables: ({ propertyId }) => ({ propertyId }),
    resultPropName: 'property',
  }),
  withMutationAsProp({
    gqlDocument: ADD_SESSION,
    mutationPropName: 'addSession',
  }),
  withMutationAsProp({
    gqlDocument: FINALIZE_PROPERTY_BY_ID,
    mutationPropName: 'finalizePropertyById',
  }),
  withHandlers({
    handleIfPortrait: (props) => () => {
      const { property } = props
      const { buildings } = property
      let img
      let building

      for (let i = 0; i < buildings.length; i++) {
        if (props.isPortrait === true) break
        building = buildings[i]
        img = new Image()
        img.src = `https://res.cloudinary.com/renderinghouse/image/upload/q_auto/app/${property.account.cloud_folder_name}/images/${building.views[0].base_image_src}`
        // eslint-disable-next-line no-loop-func
        img.onload = () => {
          if (
            building.views[0].brochure_layout &&
            building.views[0].brochure_layout.name === 'digital_homescapes'
          ) {
            props.setIsPortrait(true)
          }
          if (img.naturalHeight >= img.naturalWidth) {
            props.setIsPortrait(true)
          }
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleIfPortrait, property } = this.props
      window.addEventListener('load', handleIfPortrait(property))
    },
    componentWillUnmount() {
      const { handleIfPortrait } = this.props
      window.removeEventListener('load', handleIfPortrait)
    },
  })
)(FinalizePropertyButton)

export { WrappedComponent as FinalizePropertyButton }
