import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ListNumber from './ListNumber'

const useStyles = makeStyles({
  materialList: {
    display: 'flex',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
  },
  materialListItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px',
  },
  listNumberMargin: {
    marginRight: 7,
  },
  materialListItemName: {
    fontSize: '12px',
    margin: 0,
  },
})
export default function MaterialList({ elements }) {
  const classes = useStyles()

  return (
    <div className={classes.materialList}>
      {elements.map((element) => (
        <div className={classes.materialListItem} key={element.pointNumber}>
          <ListNumber
            number={element.pointNumber}
            customClass={classes.listNumberMargin}
          />
          <p className={classes.materialListItemName}>{element.name}</p>
        </div>
      ))}
    </div>
  )
}

MaterialList.propTypes = {
  elements: PropTypes.array.isRequired,
}
MaterialList.defaultProps = {}
