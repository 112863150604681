import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import objectHash from 'object-hash'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import { BannerConsumer } from '../../contexts'
import { BUILDING_DETAILS } from '../../graphql/buildings.graphql'
import { BASE_IMAGE_URL } from '../constants/Paths'
import SchemeCard from '../SchemeCard'
import { StoreConsumer } from '../../contexts/Store'
import {
  addDesignToCart,
  selectScheme,
  selectView,
  selectMaterial,
  editDesignFromCart,
  setSelectedSchemeName,
} from '../../reducers/designCartActionCreators'
import MaterialCard from '../MaterialCard'
import { PalettePickerModal } from '../PalettePickerModal'
import {
  createElevation,
  formatCurrentDesignToCartItemFormat,
  formatCustomMaterialsColors,
  getCustomScheme,
  getSessionTokenFromUrl,
} from '../../utils/helper'
import AddToCartButton from '../AddToCartButton'
import SaveEditsButton from '../SaveEditsButton'
import { useFirstTimeVisit } from '../../utils/hooks'
import AppGuideModal from '../AppGuideModal'
import InteractiveExteriorImage from '../InteractiveExteriorImage'

const styles = makeStyles((theme) => ({
  designPageContainer: {
    '@media screen and (orientation: portrait)': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 15,
    },
    '@media screen and (orientation: landscape)': {
      display: 'grid',
      gridTemplateColumns:
        'calc(260px + (600 - 260)*(100vw - 350px)/(1920 - 350)) 1fr',
      gridTemplateRows: '85fr 15fr',
      height: 'calc(100vh - 153px)',
    },
    backgroundColor: 'white',
  },
  imageContainer: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 2,
    display: 'flex',
    padding: '10px 0',
    minHeight: '40vh',
    '@media screen and (orientation: portrait)': {
      height: '40vh',
    },
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      backgroundColor: 'white',
      top: 70,
      zIndex: 1000,
    },
  },
  imageContainerCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  schemesMaterialsContainer: {
    padding: 20,
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumn: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  schemesContainer: {
    padding: 10,
    overflowY: 'auto',
    height: '65%',
  },
  materialsContainer: {
    padding: 10,
    overflowY: 'auto',
    height: '30%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectScheme: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const DesignPage = ({ building, store, match, history }) => {
  const classes = styles()
  const { property } = building
  const { schemes, account } = property
  const { state } = store
  const { currentDesign, cart } = state
  const view = building.views[0]
  const [palettePickerOpen, setPalettePickerOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [highlightedMaterial, setHighlightedMaterial] = useState(null)

  const alreadyVisited = useFirstTimeVisit()

  const handleSchemeSelect = (scheme) => {
    store.dispatch(selectScheme({ schemeId: scheme.id }))
  }

  const handleSetSelectedSchemeName = (schemeName) => {
    store.dispatch(setSelectedSchemeName({ schemeName }))
  }

  const handleSelectMaterial = async (materialId) => {
    await store.dispatch(selectMaterial({ materialId }))
    setPalettePickerOpen(true)
  }

  const handleAddToCart = (schemeName) => {
    handleSetSelectedSchemeName(schemeName)
    store.dispatch(addDesignToCart())
  }

  const handleSaveEdits = async () => {
    const index = state.editItemFromCart
    const currentStore = store.getState()
    // scenario where user by editing item in cart creates duplicity
    // of items in cart
    const currentDesignHash = objectHash(
      formatCurrentDesignToCartItemFormat(currentStore.currentDesign)
    )
    const editCart = [...currentStore.cart]
    editCart.splice(index, 1)
    const isCurrentDesignInCart = editCart?.length
      ? editCart.some(
          (cartDesign) => objectHash(cartDesign) === currentDesignHash
        )
      : false
    if (isCurrentDesignInCart) {
      setOpenAlert(true)
    } else {
      await store.dispatch(editDesignFromCart({ index }))
      const baseUrl = match.url.split('/project')[0]
      history.push(
        `${baseUrl}/project/${
          match.params.propertyId
        }/cart${getSessionTokenFromUrl()}`
      )
    }
  }

  const imageContainerRef = useRef(null)

  const elevation = createElevation(schemes, view)

  const customScheme = getCustomScheme(currentDesign)

  const customMaterialsColors = formatCustomMaterialsColors(customScheme)

  const selectedScheme =
    currentDesign &&
    currentDesign.schemeId &&
    schemes.find((scheme) => scheme.id === currentDesign.schemeId)

  const viewElemets = building.views[0].view_elements.map(
    (viewElement) => viewElement.material.id
  )

  const hasScheme = currentDesign && currentDesign.schemeId

  return (
    <div className={classes.designPageContainer}>
      <div
        ref={imageContainerRef}
        className={
          hasScheme
            ? classes.imageContainer
            : `${classes.imageContainer} ${classes.imageContainerCenter}`
        }
      >
        {hasScheme ? (
          <InteractiveExteriorImage
            engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
            client={account.account_name}
            maxHeight={
              imageContainerRef.current &&
              Math.round(imageContainerRef.current.offsetHeight)
            }
            maxWidth={
              imageContainerRef.current &&
              Math.round(imageContainerRef.current.offsetWidth)
            }
            community={building.property.name}
            plan={building.name}
            elevation={elevation}
            selectedScheme={selectedScheme}
            directoryName={account.cloud_folder_name}
            colorMtd="HYBRID"
            colorSelections={customMaterialsColors}
            viewElements={building.views[0].view_elements}
            selectedMaterial={highlightedMaterial}
            onHighlight={setHighlightedMaterial}
            onSelect={(layer) => handleSelectMaterial(layer.id)}
          />
        ) : (
          <img
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            alt="Homescape"
            src={`${BASE_IMAGE_URL}/q_auto,h_${
              imageContainerRef.current &&
              Math.round(imageContainerRef.current.offsetHeight)
            }/app/${account.cloud_folder_name}/images/${
              building.views[0].thumbnail_src
            }`}
          />
        )}
      </div>
      <div className={classes.schemesMaterialsContainer}>
        <Paper className={classes.schemesContainer}>
          {schemes.map((scheme) => (
            <SchemeCard
              key={scheme.id}
              scheme={scheme}
              viewElements={viewElemets}
              currentDesign={currentDesign}
              handleSchemeSelect={handleSchemeSelect}
            />
          ))}
        </Paper>
        <Paper className={classes.materialsContainer}>
          {currentDesign && currentDesign.schemeId ? (
            view.view_elements.map((element) => {
              const schemeMaterial = selectedScheme.scheme_elements.find(
                (schemeElement) =>
                  schemeElement.material.id === element.material.id
              )

              if (!schemeMaterial) return null
              return (
                <MaterialCard
                  key={element.id}
                  schemeMaterial={schemeMaterial}
                  customScheme={customScheme}
                  viewElement={element}
                  handleSelectMaterial={handleSelectMaterial}
                  onMouseEnter={() =>
                    setHighlightedMaterial({ id: element.material.id })
                  }
                  onMouseLeave={() => setHighlightedMaterial(null)}
                />
              )
            })
          ) : (
            <Typography className={classes.selectScheme} variant="h6">
              Select Scheme
            </Typography>
          )}
        </Paper>
      </div>
      {state.editItemFromCart !== null ? (
        <SaveEditsButton
          handleSaveEdits={handleSaveEdits}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      ) : (
        <AddToCartButton
          currentDesign={currentDesign}
          cart={cart}
          handleAddToCart={handleAddToCart}
          selectedScheme={selectedScheme}
        />
      )}
      <PalettePickerModal
        schemeMaterial={
          selectedScheme &&
          selectedScheme.scheme_elements.find(
            (schemeElement) =>
              schemeElement.material.id === store.state.selectedMaterialId
          )
        }
        isModalVisible={
          // Display modal, but only if the store's state has finished updating and has the selected
          // material.
          !!(palettePickerOpen && store.state.selectedMaterialId)
        }
        setModalOpen={setPalettePickerOpen}
      />
      {!alreadyVisited && <AppGuideModal />}
    </div>
  )
}

DesignPage.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    property: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      account: PropTypes.shape({
        id: PropTypes.number,
        account_name: PropTypes.string,
        cloud_folder_name: PropTypes.string,
      }).isRequired,
      schemes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          scheme_elements: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              material: PropTypes.shape({
                id: PropTypes.number,
                display_name: PropTypes.string,
              }),
              color: PropTypes.shape({
                id: PropTypes.number,
                identifier: PropTypes.string,
                name: PropTypes.string,
                hex: PropTypes.string,
              }),
            })
          ),
        })
      ),
    }).isRequired,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        thumbnail_src: PropTypes.string,
        view_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            material: PropTypes.shape({
              id: PropTypes.number,
            }),
          })
        ),
      })
    ),
  }).isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
      }),
      selectedMaterialId: PropTypes.number,
      editItemFromCart: PropTypes.number,
    }),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      propertyId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withConsumer(StoreConsumer, { propName: 'store' }),
  withRouter,
  withQueryResultAsProp({
    gqlDocument: BUILDING_DETAILS,
    variables: ({ match }) => ({
      buildingId: parseInt(match.params.buildingId, 10),
    }),
    resultPropName: 'building',
  }),
  lifecycle({
    componentDidMount() {
      this.props.banner.setSubText(this.props.building?.name)
      if (
        !this.props.store.state.sessionId &&
        !(
          this.props.store.state.currentDesign &&
          this.props.store.state.currentDesign.viewId
        )
      ) {
        // Reset the current design whenever this page is loaded without a session.
        this.props.store.dispatch(
          selectView({
            viewId: parseInt(this.props.match.params.buildingId, 10),
          })
        )
      }
      // If we have a session, its state has been loaded already, so make no changes to it.
    },
    componentWillUnmount() {
      this.props.banner.setSubText('')
    },
  })
)(DesignPage)

export { WrappedComponent as DesignPage }
