import cloneDeep from 'lodash/cloneDeep'
import objectHash from 'object-hash'
import { formatCurrentDesignToCartItemFormat } from '../utils/helper'

import {
  SELECT_VIEW,
  SELECT_SCHEME,
  SET_SELECTED_SCHEME_NAME,
  UNSELECT_SCHEME,
  SELECT_MATERIAL,
  CUSTOMIZE_MATERIAL_COLOR,
  CUSTOMIZE_MATERIAL_NAME,
  RESET_MATERIAL,
  ADD_DESIGN_TO_CART,
  REMOVE_DESIGN_FROM_CART,
  LOAD_DESIGN_FROM_CART,
  EDIT_DESIGN_FROM_CART,
  SET_SESSION_ID,
  SET_TOKEN,
  RENAME_DESIGN_FROM_CART,
} from './designCartActionTypes'

function designCartReducer(state, action) {
  let customSchemes
  let selectedScheme
  let selectedMaterial
  let currentDesignHash
  let isCurrentDesignInCart
  let editCart
  let currentDesignAddToCart

  switch (action.type) {
    case SELECT_VIEW:
      // Reset the current design whenever a view is selected, and when the page is loaded without a
      // session.
      return {
        currentDesign: {
          viewId: action.payload.viewId,
          schemeId: null,
        },
        cart: state.cart && state.cart.length ? state.cart : [],
        sessionId: state.sessionId || null,
        editItemFromCart: null,
      }
    case SELECT_SCHEME:
      // Track the selected scheme. The selected scheme is what we will render, and is also what
      // we will customize.
      return {
        ...state,
        currentDesign: {
          ...state.currentDesign,
          schemeId: action.payload.schemeId,
        },
      }
    case SET_SELECTED_SCHEME_NAME:
      // Track the selected scheme. The selected scheme is what we will render, and is also what
      // we will customize.
      return {
        ...state,
        currentDesign: {
          ...state.currentDesign,
          schemeName: action.payload.schemeName,
        },
      }
    case UNSELECT_SCHEME:
      return {
        ...state,
        currentDesign: {
          ...state.currentDesign,
          schemeId: null,
        },
      }
    case SELECT_MATERIAL:
      return {
        ...state,
        selectedMaterialId: action.payload.materialId,
      }
    case CUSTOMIZE_MATERIAL_COLOR:
      if (state.currentDesign && state.currentDesign.schemeId) {
        // For the selected scheme and selected material, set a custom material color.
        // Clone first, to avoid mutating anything in state.
        // Do an "upsert", meaning if the data is there, update it, otherwise, add it.
        customSchemes = cloneDeep(
          state && state.currentDesign && state.currentDesign.customSchemes
            ? state.currentDesign.customSchemes
            : []
        )
        selectedScheme = customSchemes.find(
          (scheme) => scheme.schemeId === state.currentDesign.schemeId
        )

        if (!selectedScheme) {
          selectedScheme = {
            schemeId: state.currentDesign.schemeId,
            materials: [],
          }
          customSchemes.push(selectedScheme)
        }

        selectedMaterial =
          selectedScheme.materials &&
          selectedScheme.materials.find(
            (material) => material.materialId === state.selectedMaterialId
          )

        if (!selectedMaterial) {
          selectedMaterial = {
            materialId: state.selectedMaterialId,
          }
          selectedScheme.materials.push(selectedMaterial)
        }

        selectedMaterial.colorId = action.payload.colorId
        selectedMaterial.colorPaletteId = action.payload.paletteId
        selectedMaterial.elementId = action.payload.elementId
        selectedMaterial.colorName = action.payload.colorName
        selectedMaterial.color = action.payload.color
        selectedMaterial.colorIdentifier = action.payload.colorIdentifier
        selectedMaterial.vendorPrefix = action.payload.vendorPrefix

        return {
          ...state,
          currentDesign: {
            ...state.currentDesign,
            customSchemes,
          },
        }
      }
      return { ...state }
    case CUSTOMIZE_MATERIAL_NAME:
      if (state.currentDesign && state.currentDesign.schemeId) {
        // For the selected scheme and selected material, set a custom material color.
        // Clone first, to avoid mutating anything in state.
        // Do an "upsert", meaning if the data is there, update it, otherwise, add it.
        customSchemes = cloneDeep(
          state && state.currentDesign && state.currentDesign.customSchemes
            ? state.currentDesign.customSchemes
            : []
        )
        selectedScheme = customSchemes.find(
          (scheme) => scheme.schemeId === state.currentDesign.schemeId
        )

        if (!selectedScheme) {
          selectedScheme = {
            schemeId: state.currentDesign.schemeId,
            materials: [],
          }
          customSchemes.push(selectedScheme)
        }

        selectedMaterial =
          selectedScheme.materials &&
          selectedScheme.materials.find(
            (material) => material.materialId === state.selectedMaterialId
          )

        if (!selectedMaterial) {
          selectedMaterial = {
            materialId: state.selectedMaterialId,
          }
          selectedScheme.materials.push(selectedMaterial)
        }

        selectedMaterial.materialName = action.payload.materialName

        return {
          ...state,
          currentDesign: {
            ...state.currentDesign,
            customSchemes,
          },
        }
      }
      return { ...state }
    case RESET_MATERIAL:
      // Remove the material having the selected material ID.
      // Clone first, to avoid mutating anything in state.
      if (state.currentDesign && state.currentDesign.schemeId) {
        // For the selected scheme and selected material, set a custom material color.
        // Clone first, to avoid mutating anything in state.
        // Do an "upsert", meaning if the data is there, update it, otherwise, add it.
        customSchemes = cloneDeep(
          state && state.currentDesign && state.currentDesign.customSchemes
            ? state.currentDesign.customSchemes
            : []
        )
        selectedScheme = customSchemes.find(
          (scheme) => scheme.schemeId === state.currentDesign.schemeId
        )

        if (!selectedScheme) {
          selectedScheme = {
            schemeId: state.currentDesign.schemeId,
            materials: [],
          }
          customSchemes.push(selectedScheme)
        }

        // Remove customizations for the currently selected material.
        selectedScheme.materials = selectedScheme.materials.filter(
          (material) => material.materialId !== state.selectedMaterialId
        )

        if (selectedScheme.materials.length < 1) {
          const index = customSchemes.indexOf(selectedScheme)
          customSchemes.splice(index, 1)
        }

        return {
          ...state,
          currentDesign: {
            ...state.currentDesign,
            customSchemes,
          },
        }
      }
      return { ...state }
    case ADD_DESIGN_TO_CART:
      if (state.currentDesign) {
        // Filter customSchemes to only include currently selected scheme
        currentDesignAddToCart = formatCurrentDesignToCartItemFormat(
          state.currentDesign
        )
        // Check if the design is in the cart before adding it.
        currentDesignHash = objectHash(currentDesignAddToCart)
        isCurrentDesignInCart =
          state.cart && state.cart.length
            ? state.cart.some(
                (cartDesign) => objectHash(cartDesign) === currentDesignHash
              )
            : false

        if (!isCurrentDesignInCart) {
          return {
            ...state,
            cart: [
              ...(state.cart && state.cart.length ? state.cart : []),
              currentDesignAddToCart,
            ],
          }
        }
      }
      return { ...state }
    case REMOVE_DESIGN_FROM_CART:
      if (state.cart && state.cart.length) {
        return {
          ...state,
          cart: [
            ...state.cart.slice(0, action.payload.index),
            ...state.cart.slice(action.payload.index + 1),
          ],
        }
      }
      return { ...state }
    case LOAD_DESIGN_FROM_CART:
      if (state.cart && state.cart.length > 0) {
        return {
          ...state,
          currentDesign: state.cart[action.payload.index],
          editItemFromCart: action.payload.index,
        }
      }
      return { ...state }
    case EDIT_DESIGN_FROM_CART:
      // Filter customSchemes to only include currently selected scheme
      currentDesignAddToCart = formatCurrentDesignToCartItemFormat(
        state.currentDesign
      )

      // Check if edited item is not duplicate of other item in cart
      if (state.currentDesign) {
        currentDesignHash = objectHash(currentDesignAddToCart)
        editCart = [...state.cart]
        editCart.splice(action.payload.index, 1)
        isCurrentDesignInCart =
          editCart && editCart.length
            ? editCart.some(
                (cartDesign) => objectHash(cartDesign) === currentDesignHash
              )
            : false

        if (!isCurrentDesignInCart) {
          state.cart[action.payload.index] = currentDesignAddToCart

          return {
            ...state,
            editItemFromCart: null,
          }
        }
      }
      return { ...state }
    case RENAME_DESIGN_FROM_CART:
      if (state.cart[action.payload.index]) {
        state.cart[action.payload.index].schemeName = action.payload.newName
      }

      return { ...state }
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload.sessionId,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      }
    default:
      throw new Error('Unknown action type.')
  }
}

export { designCartReducer }
