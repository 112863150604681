import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import objectHash from 'object-hash'
import { BannerConsumer, StoreConsumer } from '../../contexts'
import { withConsumer, withQueryResultAsProp } from '../../utils'
import ShoppingCard from '../ShoppingCard'
import { PROPERTY_DETAILS_WITH_BUILDINGS } from '../../graphql/properties.graphql'
import {
  loadDesignFromCart,
  removeDesignFromCart,
  renameDesignFromCart,
} from '../../reducers/designCartActionCreators'
import {
  createElevation,
  getSessionTokenFromUrl,
  islistOrderGreater,
} from '../../utils/helper'
import { useFirstTimeVisit } from '../../utils/hooks'
import AppGuideModal from '../AppGuideModal'
import NameSchemeDialog from '../NameSchemeDialog'

const styles = makeStyles((theme) => ({
  cartPageContainer: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '10px 0',
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 40,
    },
  },
}))

const LIMIT = 9

const CartPage = ({ property, store, match, history, banner }) => {
  const classes = styles()

  const { account, buildings, schemes } = property
  const { cart } = store.state
  const [numItems, setNumItems] = useState(LIMIT)
  const [renamedItem, setRenamedItem] = useState(null)
  const observer = useRef()

  const alreadyVisited = useFirstTimeVisit()

  const paginatedCart = useMemo(
    () => cart?.slice(0, numItems),
    [cart, numItems]
  )

  useEffect(() => {
    const propertyData = {
      name: property.name,
      account: {
        account_name: account.account_name,
        cloud_folder_name: account.cloud_folder_name,
      },
    }

    banner.setProperty(propertyData)
  }, [])

  const lastElement = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && paginatedCart.length !== cart.length) {
          setNumItems((items) => items + LIMIT)
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [paginatedCart, cart]
  )

  const handleRemoveItem = async (index) => {
    await store.dispatch(removeDesignFromCart({ index }))
  }

  const handleEditItem = async (index, buildingId) => {
    await store.dispatch(loadDesignFromCart({ index }))
    const baseUrl = match.url.split('/cart')[0]
    history.push(
      `${baseUrl}/building/${buildingId}/design${getSessionTokenFromUrl()}`
    )
  }

  const handleRenameItemClose = async () => {
    setRenamedItem(null)
  }

  const handleRenameItem = async (index) => {
    setRenamedItem({
      ...paginatedCart[index],
      index,
    })
  }

  const handleRenameItemSubmit = async (name) => {
    await store.dispatch(
      renameDesignFromCart({
        index: renamedItem.index,
        newName: name,
      })
    )

    setRenamedItem(null)
  }

  return (
    <div className={classes.cartPageContainer}>
      {paginatedCart &&
        paginatedCart.map((cartItem, index) => {
          const building = buildings.find((item) => item.id === cartItem.viewId)
          const scheme = schemes.find((item) => item.id === cartItem.schemeId)
          building.views[0].view_elements.slice().sort(islistOrderGreater)
          const view = building.views[0]

          const elevation = createElevation(schemes, view)

          return (
            <ShoppingCard
              refElement={
                paginatedCart.length === index + 1 ? lastElement : undefined
              }
              key={objectHash(cartItem)}
              index={index}
              item={cartItem}
              building={building}
              scheme={scheme}
              account={account}
              elevation={elevation}
              community={property.name}
              handleRemoveItem={handleRemoveItem}
              handleEditItem={handleEditItem}
              handleRenameItem={handleRenameItem}
            />
          )
        })}
      {!alreadyVisited && <AppGuideModal />}
      <NameSchemeDialog
        key={renamedItem?.schemeId}
        open={!!renamedItem}
        cart={cart}
        initialName={renamedItem?.schemeName}
        onClose={handleRenameItemClose}
        onSubmit={handleRenameItemSubmit}
      />
    </div>
  )
}

CartPage.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.shape({
      cart: PropTypes.arrayOf(
        PropTypes.shape({
          schemeId: PropTypes.number,
          viewId: PropTypes.number,
          customSchemes: PropTypes.arrayOf(
            PropTypes.shape({
              schemeId: PropTypes.number,
              materials: PropTypes.arrayOf(
                PropTypes.shape({
                  colorId: PropTypes.number,
                  colorPaletteId: PropTypes.number,
                  elementId: PropTypes.number,
                  materialId: PropTypes.number,
                })
              ),
            })
          ),
        })
      ),
      currentDesign: PropTypes.shape({
        customSchemes: PropTypes.arrayOf(
          PropTypes.shape({
            schemeId: PropTypes.number,
            materials: PropTypes.arrayOf(
              PropTypes.shape({
                colorId: PropTypes.number,
                colorPaletteId: PropTypes.number,
                elementId: PropTypes.number,
                materialId: PropTypes.number,
              })
            ),
          })
        ),
        schemeId: PropTypes.number,
        viewId: PropTypes.number,
      }),
      selectedMaterialId: PropTypes.number,
    }),
  }).isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    account: PropTypes.shape({
      id: PropTypes.number,
      account_name: PropTypes.string,
      cloud_folder_name: PropTypes.string,
    }).isRequired,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
            view_elements: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                material: PropTypes.shape({
                  id: PropTypes.number,
                  element_id: PropTypes.number,
                  display_name: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ),
    schemes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        scheme_elements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            material: PropTypes.shape({
              id: PropTypes.number,
              display_name: PropTypes.string,
            }),
            color: PropTypes.shape({
              id: PropTypes.number,
              identifier: PropTypes.string,
              name: PropTypes.string,
              hex: PropTypes.string,
            }),
          })
        ),
      })
    ),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  banner: PropTypes.shape({
    setProperty: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(StoreConsumer, { propName: 'store' }),
  withConsumer(BannerConsumer, { propName: 'banner' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    resultPropName: 'property',
  }),
  withRouter
)(CartPage)

export { WrappedComponent as CartPage }
