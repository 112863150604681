import { Button, Typography } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'

import { navButtonStyles } from '../../stylesheets/navButton.style'

const SelectSchemeButton = ({ match, location, history, disabled = false }) => {
  const classes = navButtonStyles()
  const { subAccountName } = match.params

  const handleSelectScheme = () => {
    // Parse out token
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const tokenQueryParam = token ? `?token=${token}` : ''

    let link = `/account/${match.params.accountName}/project/${match.params.propertyId}/summary${tokenQueryParam}`
    if (subAccountName) {
      link = `/account/${match.params.accountName}/subAccount/${subAccountName}/project/${match.params.propertyId}/summary${tokenQueryParam}`
    }
    const baseUrl = match.url.split('/account')[0]
    history.push(`${baseUrl}${link}`)
  }

  return (
    <div className={classes.button}>
      <Button
        style={{ width: 165, whiteSpace: 'nowrap' }}
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={handleSelectScheme}
      >
        <Typography className={classes.buttonContent}>
          <FormatPaintIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonText} variant="button">
            Select Scheme
          </Typography>
        </Typography>
      </Button>
    </div>
  )
}

SelectSchemeButton.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

SelectSchemeButton.defaultProps = {
  disabled: false,
}

const WrappedComponent = compose(withRouter)(SelectSchemeButton)

export { WrappedComponent as SelectSchemeButton }
