import { makeStyles } from '@material-ui/core'
import React from 'react'
import { BASE_IMAGE_URL } from '../constants/Paths'

const styles = makeStyles({
  bg: {
    height: '600px',
    backgroundImage: `url(${BASE_IMAGE_URL}/v1612887431/app/cv/shw/app_images/clientlogo-alt_5b4e3a29f0a01.png)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  message: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '5%',
  },
})

// Display the first building's first view's thumbnail as the representative
// view of the property.
const FinalizePage = () => {
  const classes = styles()

  return (
    <React.Fragment>
      <div className={classes.bg}>
        <div className={classes.message}>
          Your selections have been sent to your Sherwin-Williams
          representative.
        </div>
      </div>
    </React.Fragment>
  )
}

export { FinalizePage }
