/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React, { Component, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef BannerContext
 * @prop {boolean} isLinkCopiedOrDismissed
 * @prop {boolean} isReminderOn
 * @prop {object} property
 * @prop {() => boolean} setIsLinkCopiedOrDismissed
 * @prop {(isReminderOn: boolean) => void} setIsReminderOn
 * @prop {(property: object) => void} setProperty
 * @prop {(subText: string) => void} setSubText
 * @prop {string} subText
 */

const BannerContext = React.createContext()

const BannerConsumer = BannerContext.Consumer

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.
class BannerProvider extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  state = {
    subText: '',
    isReminderOn: false,
    isLinkCopiedOrDismissed: false,
    property: {
      name: '',
      account: {
        account_name: '',
        cloud_folder_name: '',
      },
    },
  }

  setSubText = (subText) =>
    this.setState((state) => ({
      ...state,
      subText,
    }))

  setProperty = (property) =>
    this.setState((state) => ({
      ...state,
      property,
    }))

  setIsReminderOn = (isReminderOn) =>
    this.setState((state) => ({
      ...state,
      isReminderOn,
    }))

  setIsLinkCopiedOrDismissed = (isLinkCopiedOrDismissed) =>
    this.setState((state) => ({
      ...state,
      isLinkCopiedOrDismissed,
    }))

  render() {
    return (
      <BannerContext.Provider
        value={{
          subText: this.state.subText,
          setSubText: this.setSubText,
          property: this.state.property,
          setProperty: this.setProperty,
          isReminderOn: this.state.isReminderOn,
          setIsReminderOn: this.setIsReminderOn,
          isLinkCopiedOrDismissed: this.state.isLinkCopiedOrDismissed,
          setIsLinkCopiedOrDismissed: this.setIsLinkCopiedOrDismissed,
        }}
      >
        {this.props.children}
      </BannerContext.Provider>
    )
  }
}

/**
 * @returns {BannerContext}
 */
function useBanner() {
  return useContext(BannerContext)
}

/**
 * @param {string} text
 */
function useSetBannerText(text) {
  const { setSubText } = useBanner()

  useEffect(() => {
    setSubText(text)
    return () => setSubText('')
  }, [text])
}

export { BannerProvider, BannerConsumer, useBanner, useSetBannerText }
