import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import {
  AccountPage,
  PropertyPage,
  BuildingPage,
  RoomPage,
  HomePage,
  DesignPage,
  CartPage,
} from '../pages'
import SummaryPage from '../pages/summaryPage/SummaryPage'
import {
  ACCOUNT_PAGE,
  BUILDING_PAGE,
  ROOM_PAGE,
  CART_PAGE,
  DESIGN_PAGE,
  HOME_PAGE,
  PROPERTY_PAGE,
  SUMMARY_PAGE,
  SUBACC_ACCOUNT_PAGE,
  SUBACC_BUILDING_PAGE,
  SUBACC_ROOM_PAGE,
  SUBACC_CART_PAGE,
  SUBACC_DESIGN_PAGE,
  SUBACC_HOME_PAGE,
  SUBACC_PROPERTY_PAGE,
  SUBACC_SUMMARY_PAGE,
} from '../constants/Paths'
import AppFooter from './AppFooter'
import { AccountBanner } from './AccountBanner'
import { withConsumer } from '../../utils'
import { BannerConsumer } from '../../contexts'
import SaveLinkNotification from '../SaveLinkNotification'

// AppLayout receives location React router.
const AppLayout = ({ banner }) => {
  useEffect(() => {
    const onSaveablePages = () =>
      window.location.href.includes('/building/') ||
      window.location.href.endsWith('/design') ||
      window.location.href.endsWith('/summary') ||
      window.location.href.endsWith('/cart')
    if (
      !banner.isLinkCopiedOrDismissed &&
      !banner.isReminderOn &&
      !window.location.href.includes('?token=') &&
      onSaveablePages()
    ) {
      setTimeout(() => {
        banner.setIsReminderOn(onSaveablePages())
      }, 1000 * 60 * 5) // 1000 milliseconds * 60 * 5 = 5 minutes
    }
  })
  return (
    <React.Fragment>
      <Switch>
        <Route path={SUBACC_ACCOUNT_PAGE} component={AccountBanner} />
        <Route path={ACCOUNT_PAGE} component={AccountBanner} />
      </Switch>
      <Switch>
        <Route path={SUBACC_DESIGN_PAGE} component={DesignPage} />
        <Route path={SUBACC_BUILDING_PAGE} component={BuildingPage} />
        <Route path={SUBACC_ROOM_PAGE} component={RoomPage} />
        <Route path={SUBACC_SUMMARY_PAGE} component={SummaryPage} />
        <Route path={SUBACC_HOME_PAGE} component={HomePage} />
        <Route path={SUBACC_CART_PAGE} component={CartPage} />
        <Route path={SUBACC_PROPERTY_PAGE} component={PropertyPage} />
        <Route path={SUBACC_ACCOUNT_PAGE} component={AccountPage} />

        <Route path={DESIGN_PAGE} component={DesignPage} />
        <Route path={BUILDING_PAGE} component={BuildingPage} />
        <Route path={ROOM_PAGE} component={RoomPage} />
        <Route path={SUMMARY_PAGE} component={SummaryPage} />
        <Route path={HOME_PAGE} component={HomePage} />
        <Route path={CART_PAGE} component={CartPage} />
        <Route path={PROPERTY_PAGE} component={PropertyPage} />
        <Route path={ACCOUNT_PAGE} component={AccountPage} />
      </Switch>
      <div>
        {!banner.isLinkCopiedOrDismissed && (
          <SaveLinkNotification
            reminder={banner.isReminderOn}
            setReminder={banner.setIsReminderOn}
            setIsLinkCopiedOrDismissed={banner.setIsLinkCopiedOrDismissed}
          />
        )}
      </div>
      <AppFooter />
    </React.Fragment>
  )
}

AppLayout.propTypes = {
  banner: PropTypes.shape({
    isReminderOn: PropTypes.bool,
    setIsReminderOn: PropTypes.func,
    isLinkCopiedOrDismissed: PropTypes.bool,
    setIsLinkCopiedOrDismissed: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(BannerConsumer, { propName: 'banner' })
)(AppLayout)

export { WrappedComponent as AppLayout }
