import { makeStyles } from '@material-ui/core'
import React from 'react'
import CookieBanner from 'react-cookie-banner'
import { Route, Switch, withRouter } from 'react-router-dom'
import { DESIGN_PAGE, HOME_PAGE, CART_PAGE } from '../constants/Paths'

const styles = makeStyles((theme) => ({
  footerDisclaimer: {
    marginRight: '5%',
    textAlign: 'left',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
      width: '100%',
      marginTop: '10px',
      marginRight: 0,
      order: 2,
    },
  },
  footerLink: {
    color: theme.palette.primary.main,
    borderRight: '1px solid #1890ff',
    padding: '2px 9px 2px 0',
    marginRight: '9px',
    '&:link': {
      textDecoration: 'none',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
    '&:last-child': {
      borderRight: 'none',
      paddingRight: '0',
    },
  },
  appFooter: {
    background: 'white',
    boxShadow: '0 0 24px rgba(0,0,0,.15)',
    fontSize: '14px',
    padding: '6px 19px',
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 'auto',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
  },
  belowDisclaimer: {
    padding: '6px 19px',
    background: 'white',
    position: 'relative',
    borderTop: '1px solid gainsboro',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
  },
  footerWrap: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
}))

const HelpLink = () => {
  const classes = styles()
  return (
    <a
      className={classes.footerLink}
      target="_blank"
      rel="noopener noreferrer"
      href="https://cvhelp.anewgo.com/shopping_cart_mode/index.html"
    >
      Help
    </a>
  )
}

const AppFooter = () => {
  const classes = styles()

  return (
    <div className={classes.footerWrap}>
      <div className={classes.appFooter}>
        <CookieBanner
          styles={{
            banner: {
              width: 'calc(100% + 38px)',
              height: 'auto',
              minHeight: '40px',
              display: 'grid',
              gridTemplateColumns: '8fr 1fr',
              gridGap: '3px',
              gridTemplateAreas: '"message button"',
              margin: '-9px -19px 11px',
              alignItems: 'center',
            },
            message: {
              lineHeight: 2,
              gridArea: 'message',
            },
            button: {
              marginTop: 'auto',
              gridArea: 'button',
              width: '75px',
              height: '25px',
              right: '5px',
              bottom: '5px',
            },
          }}
          dismissOnScroll={false}
          message="This site uses cookies. By continuing to use this site, you are agreeing to our use of cookies. For more details, see the Privacy Policy."
          onAccept={() => {}}
          cookie="react-cookie-banner-accepted"
        />
        <span className={classes.footerDisclaimer}>
          <strong>
            Don’t forget about our Repaint Specifications – we do the work for
            paint bids, so you don’t have to!
          </strong>
        </span>
        <a
          className={classes.footerLink}
          href="https://privacy.sherwin-williams.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className={classes.footerLink}
          href=" https://accessibility.sherwin-williams.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility Statement
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url */}
        <a id="footerDoNotSellInfoMyLink" href="javascript:void(0);">
          Do Not Sell or Share My Personal Information
        </a>
        <Switch>
          <Route path={DESIGN_PAGE} component={HelpLink} />
          <Route path={HOME_PAGE} component={HelpLink} />
          <Route path={CART_PAGE} component={HelpLink} />
        </Switch>
      </div>
      <div className={classes.belowDisclaimer}>
        Actual color may vary from on-screen representation. To confirm your
        color choices prior to purchase, please view a physical color chip,
        color card, or painted sample. Sherwin-Williams is not responsible for
        the content and photos shared by users of their color selection tools.
        The Cover The Earth logo and the Sherwin-Williams logo are trademarks
        owned or licensed by The Sherwin-Williams Company. ©
        {`${new Date().getFullYear()} `}
        The Sherwin-Williams Company.
      </div>
    </div>
  )
}

export default withRouter(AppFooter)
