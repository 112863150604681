import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import Typography from '@material-ui/core/Typography'
import BrokenImage from '@material-ui/icons/BrokenImage'
import { makeStyles } from '@material-ui/core/styles'
import MaterialCard from '../../../floorCovering/MaterialCard'
import { useIntersect } from '../hooks'
import { getElementId } from '../../../../utils/roomLayer'
import '../../../../types'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
    gridAutoRows: 'max-content',
    justifyContent: 'center',
    marginTop: 8,
    gap: 4,
  },

  noCovering: {
    gridColumn: '1 / -1',
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

/**
 * @typedef ElementGridIndicatorProps
 * @prop {string=} text
 */

/**
 * @param {ElementGridIndicatorProps} props
 */
function ElementGridIndicator({ text }) {
  const classes = useStyles()

  return (
    <div className={classes.noCovering}>
      <BrokenImage color="action" fontSize="large" />
      <Typography color="action">{text}</Typography>
    </div>
  )
}

ElementGridIndicator.propTypes = {
  text: PropTypes.string,
}

ElementGridIndicator.defaultProps = {
  text: '',
}

export const PAGE_SIZE = 70

/**
 * @typedef ElementGridProps
 * @prop {boolean} loading
 * @prop {boolean} error
 * @prop {SchemeElement=} elements
 * @prop {totalElements=} totalElements
 * @prop {() => void} fetchMore
 * @prop {(element: SchemeElement) => boolean} isElementSelected
 * @prop {(element: SchemeElement) => void} onSelect
 */

/**
 * @param {ElementGridProps} props
 */
export default function ElementGrid({
  loading,
  error,
  elements,
  totalElements,
  errorText,
  emptyText,
  fetchMore,
  isElementSelected,
  onSelect,
}) {
  const classes = useStyles()
  const numRestItems = (totalElements ?? 0) - (elements?.length ?? 0)
  const numRestSkeletons = Math.min(numRestItems, PAGE_SIZE)

  const intersectRef = useIntersect(
    (intersecting) => {
      if (intersecting) {
        fetchMore?.()
      }
    },
    { threshold: 0.01 }
  )

  return (
    <div className={classes.root}>
      {/* Data */}
      {!loading &&
        !error &&
        elements?.map((element) => (
          <MaterialCard
            key={getElementId(element)}
            element={element}
            selected={isElementSelected(element)}
            hideMaskName
            onClick={() => onSelect?.(element)}
          />
        ))}

      {!loading && !elements?.length && !error && (
        <ElementGridIndicator text={emptyText} />
      )}
      {!loading && error && <ElementGridIndicator text={errorText} />}

      {/* Loading indicator (Used once on initialization) */}
      {loading && range(PAGE_SIZE).map((i) => <MaterialCard.Loading key={i} />)}

      {/* Infinite list */}
      {!loading && numRestSkeletons > 0 && (
        <>
          <MaterialCard.Loading
            ref={intersectRef}
            key={`more-${numRestItems}`}
          />
          {range(numRestSkeletons - 1).map((i) => (
            <MaterialCard.Loading key={i} />
          ))}
        </>
      )}
    </div>
  )
}

ElementGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.object),
  totalElements: PropTypes.number,
  errorText: PropTypes.string,
  emptyText: PropTypes.string,
  fetchMore: PropTypes.func,
  isElementSelected: PropTypes.func,
  onSelect: PropTypes.func,
}

ElementGrid.defaultProps = {
  error: false,
  elements: null,
  totalElements: null,
  errorText: 'An error occured fetching data, please try again later!',
  emptyText: 'No items matched your selection!',
  fetchMore: null,
  isElementSelected: () => false,
  onSelect: null,
}

function ElementGridLoading() {
  return <ElementGrid loading />
}

ElementGrid.Loading = ElementGridLoading
