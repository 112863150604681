import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import { StoreConsumer } from '../contexts'
import { HOA_MATERIALS } from '../graphql/materials.graphql'
import { withConsumer, withQueryResultAsProp } from '../utils'
import { getSchemesMaterials } from '../utils/helper'

const useClasses = makeStyles({
  schemesList: {
    overflowY: 'auto',
  },
  schemeListItem: {
    marginLeft: 25,
  },
})

const HOAValidationErrorDialog = ({
  store,
  property,
  openError,
  setOpenError,
}) => {
  const classes = useClasses()
  const { buildings } = property
  const sessionState = store.getState()
  const buildingIds = sessionState.cart?.map((cartItem) => cartItem.viewId)
  const selectedBuildings = buildings.filter((building) =>
    buildingIds.includes(building.id)
  )

  const unknownSchemes = getSchemesMaterials(sessionState, selectedBuildings)

  if (unknownSchemes?.length > 0) setOpenError(true)
  else setOpenError(false)

  return (
    <Dialog open={openError} onClose={() => setOpenError(false)}>
      <DialogTitle>Cannot Send Substrates to the HOA Archive</DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          <div>
            <h3>The following substrate names are not supported:</h3>
          </div>
          {unknownSchemes && unknownSchemes.length > 0 && (
            <div className={classes.schemesList}>
              {unknownSchemes?.map((scheme) => (
                <div key={scheme.schemeName}>
                  <h4>{scheme.schemeName}</h4>
                  {scheme?.duplicateMaterials?.map((materialName) => (
                    <li className={classes.schemeListItem} key={materialName}>
                      <i>{materialName}</i>
                    </li>
                  ))}
                </div>
              ))}
            </div>
          )}
          <div>
            <b>
              <br />
              Please rename these and try again, or contact the project
              administrator.
            </b>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenError(false)}
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

HOAValidationErrorDialog.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.shape({
      sessionId: PropTypes.number,
    }),
    getState: PropTypes.func,
  }).isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street_address: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            thumbnail_src: PropTypes.string,
            base_image_src: PropTypes.string,
            view_elements: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                material: PropTypes.shape({
                  id: PropTypes.number,
                  element_id: PropTypes.number,
                  display_name: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ),
  }).isRequired,
  openError: PropTypes.bool.isRequired,
  setOpenError: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: HOA_MATERIALS,
    resultPropName: 'hoaMaterials',
  }),
  withConsumer(StoreConsumer, { propName: 'store' })
)(HOAValidationErrorDialog)

export { WrappedComponent as HOAValidationErrorDialog }
