import React, { useMemo } from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { withConsumer, withQueryResultAsProp } from '../../../utils'
import { PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS } from '../../../graphql/properties.graphql'
import { SelectionConsumer } from '../../../contexts/Selection'
import DefaultView from './components/DefaultView'
import DigitalHomescapesView from './components/DigitalHomescapesView'
import RoomView from './components/RoomView'
import useAccountByName from '../../../hooks/useAccountByName'

function useSummaryPageParams() {
  const { accountName, subAccountName, propertyId: propertyIdRaw } = useParams()
  const propertyId = parseInt(propertyIdRaw, 10)

  if (!propertyId)
    throw new TypeError(
      `The propertyId and roomId arguments only support integer values!`
    )

  return {
    accountName: subAccountName ?? accountName,
    propertyId,
  }
}

function SummaryPage({ selection, location, property }) {
  const { accountName } = useSummaryPageParams()
  const { data } = useAccountByName(accountName)

  const sortedBuildings = useMemo(
    () =>
      property?.buildings.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.buildings]
  )

  const sortedRooms = useMemo(
    () => property?.rooms.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [property?.rooms]
  )

  return (
    <div>
      {sortedBuildings.map((building, index) =>
        building?.views[0]?.brochure_layout?.name === 'digital_homescapes' ? (
          <DigitalHomescapesView
            key={building.name}
            building={building}
            property={property}
            selection={selection}
            location={location}
            index={index}
          />
        ) : (
          <DefaultView
            key={building.name}
            building={building}
            property={property}
            selection={selection}
            location={location}
            index={index}
          />
        )
      )}

      {sortedRooms.map((room) => (
        <RoomView
          key={room.id}
          account={data?.accountByName}
          property={property}
          room={room}
        />
      ))}
    </div>
  )
}

SummaryPage.propTypes = {
  selection: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_DETAILS_WITH_BUILDINGS_AND_ROOMS,
    variables: ({ match }) => ({
      propertyId: parseInt(match.params.propertyId, 10),
    }),
    transformFunc: (property, result) => ({
      ...property,
      rooms: result.roomsByProjectId,
    }),
    resultPropName: 'property',
  }),
  withHandlers({
    handleResize: () => () => {
      const vw = window.innerWidth * 0.01
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      document.documentElement.style.setProperty('--vw', `${vw}px`)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleResize } = this.props
      window.addEventListener('resize', handleResize)
      window.addEventListener('orientationChange', handleResize)
    },
    componentWillUnmount() {
      const { handleResize } = this.props
      window.removeEventListener('resize', handleResize)
      window.addEventListener('orientationChange', handleResize)
    },
  })
)(SummaryPage)

export default WrappedComponent
