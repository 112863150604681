import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles, Snackbar as Snack } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = makeStyles((theme) => ({
  snackBar: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
}))

const Snackbar = ({ open, setOpen, message, severity }) => {
  const classes = styles()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Snack
      className={classes.snackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={() => handleClose()}
    >
      <MuiAlert
        style={{ width: 'inherit' }}
        onClose={() => handleClose()}
        elevation={6}
        variant="standard"
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snack>
  )
}

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
}
export default Snackbar
